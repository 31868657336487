<template>
  <div class="menu-canvas">
    <!-- 节点面板 -->
    <div class="menu-node" v-show="isNodeToBaoWei">
      <div class="menu-linecolor">
        <div class="menu-linecolor-box" >
          <div class="menu-left-accout">
            {{ getString(strings.Mind_Edit_Right_Menu_text) }}
          </div>
        </div>
        <div class="menu-linecolor-box">
          <input
                id="hiddenInputFontFamilys"
                type="file"
                accept=".TTF, .OTF, .WOFF, .EOT, .SVG"
                @change="handleFontFamilyFile"
                class="hiddenInputFontFamilys"
              />
          <div class="selected-box">
            <WzcStrSelect
                :width="250"
                :height="24"
                :maxHeight="350"
                :placeholder="fontFamily"
                :itemList="fontFamilys"
                :inputIdName="'dataFontFamily'"
                @changeInputVal="changeFontFamily"
              >              
              <template v-slot:wzc_option>
                  <WzcStrOption
                    v-for="item in fontFamilys"
                    :class="item.id == -1 ? 'fontFamilyAddLocal' : (item.isUser ? '' : '')"
                    :key="item.id"
                    :label="item.name"
                    :optionid="item.id"
                    :sizeSliderCurrent="fontFamily"
                    @changeVal="changeFontFamily"
                  ></WzcStrOption>
              </template>
            </WzcStrSelect>
          </div>
        </div>
        <div class="menu-linecolor-box">
          <div class="menu-left-accout">
            <div class="selected-box">
              <WzcSelect
                :width="110"
                :height="24"
                :maxHeight="200"
                :placeholder="fontSizeSlider"
                :itemList="fontItems"
                :inputIdName="'fontSizeSlider'"
                :sliderMum ="fontExtremum"
                @changeInputVal="changeSliderVal"
              >
                <template v-slot:wzc_option>
                  <WzcOption
                    v-for="item in fontItems"
                    :key="item.id"
                    :label="item.name"
                    :optionid="item.id"
                    :sizeSliderCurrent="fontSizeSlider"
                    @changeVal="changeSliderVal"
                  ></WzcOption>
                </template>
              </WzcSelect>
            </div>
          </div>
          <div class="menu-right-accout">
            <div
              v-for="(item, index) in fontAlign"
              :key="index"
              class="menu-font-stlye"
              :class="
                item.fontStyleAccout ? 'font-stlye-before' : 'menu-font-stlye'
              "
              @click="setFontAlign(item, index)"
            >
              <img
                v-if="mapEditDarkMode"
                :src="item.fontAlignImgWhite"
                alt=""
              />
              <img v-else :src="item.fontStlye" alt="" />
            </div>
          </div>
        </div>
        <div class="menu-linecolor-box">
          <div class="menu-left-accout">
            <div @click="setTextColorAccout"
              class="menu-right-linecolor"
              style="width:50px;"
              :style="{ background: textColor }"
            ></div>
          </div>

          <div class="menu-div-font-shadow-stlye" style="position: absolute;left:-2px">
            <div @click="setTextShadowColorAccout"
              class="menu-font-shadow-stlye"
              :class="
                fontShadowStlye.fontStyleAccout ? 'font-shadow-stlye-before' : 'menu-font-shadow-stlye'
                ">
              <img class="menu-img-font-shadow-stlye" v-if="mapEditDarkMode"
                  :src="fontShadowStlye.whiteUrl"
                  alt=""/>
              <img v-else :src="fontShadowStlye.url" class="menu-img-font-shadow-stlye" alt="" />
            </div>
          </div>

          <div style="display:flex; margin-left:80px">
            <div
              v-for="(item, index) in fontStlyeList"
              :key="index"
              class="menu-font-stlye"
              :class=" item.fontStyleAccout ? 'font-stlye-before' : 'menu-font-stlye' "
              @click="setFontStlyeAccout(index)" >
              <img
                v-if="mapEditDarkMode"
                :src="item.fontStlyeImgWhite"
                alt="" />
              <img v-else :src="item.fontStlye" alt="" />
            </div>
          </div>
        </div>
        <div class="menu-right-bg">
          <transition name="slide-fade">
            <CommonBgColor v-if="textColorAccout" />
          </transition>
        </div>
        <div class="menu-right-bg">
          <transition name="slide-fade">
            <CommonBgColor v-if="textShadowColorAccout" />
          </transition>
        </div>
      </div>

      <div class="menu-linecolor">
        
        <!-- 形状 -->
        <div class="menu-linecolor-box margin-top-12">
          <div class="menu-left-accout">
            {{ getString(strings.Mind_Edit_Right_Menu_Border_Shape) }}
          </div>
          <div class="menu-right-accout"
            style="border:1px solid var(--exportMenuDashedColor); background: #fffff;padding: 0 5px;width: 76px;height: 32px;"
            @click.stop="setFromBorderAccout()">
            <div class="menu-right-border-box"
              style="border-radius: 10px; min-width: 38px" >
              <img class="menu-right-border-icon"
                :src="borderStyleLayout" alt="" />
            </div>
            <a-icon
              class="imgthree"
              :class="fromBorderAccout?'imgthree-sel':'imgthree-no'"
              type="caret-up" />
          </div>
          <div class="menu-right-bg" style="marign-top: 30px">
            <transition name="slide-fade">
              <ComplieChildMenuForm
                :fullShapeType="shapeBorderType"
                v-if="fromBorderAccout" />
            </transition>
          </div>
        </div>

        <div class="line-box"></div>
        <div class="menu-linecolor-box" @click="setFillColorAccout">
          <div class="menu-left-accout">
            {{ getString(strings.Mind_Edit_Right_Menu_Fill_Color) }}
          </div>
          <div class="menu-right-accout">
            <div
              class="menu-right-linecolor"
              style="background: #ffc5c5"
              :style="{ background: fillColor }"
            ></div>
          </div>
        </div>

        <div class="menu-right-bg">
          <transition name="slide-fade">
            <CommonBgColor v-if="fillColorAccout" />
          </transition>
        </div>
        <!-- 背景填充样式 -->
        <div class="menu-linecolor-box">
          <div class="menu-left-accout">
            {{ getString(strings.Mind_Edit_Right_Menu_Node_Full_Shape) }}
          </div>
          <div class="menu-right-accout"
            style="border:1px solid var(--exportMenuDashedColor); background: #fffff;padding: 0 5px;width: 76px;height: 32px;"
            @click.stop="setFullShapeType()">
            <div class="menu-right-border-box"
              style="border-radius: 10px; min-width: 38px" >
              <img class="menu-right-border-icon" style="width:45px"
                :src="nodeFullShapeImg" alt="" />
            </div>
            <a-icon
              class="imgthree"
              :class="fullShapeTypeAccout?'imgthree-sel':'imgthree-no'"
              type="caret-up" />
          </div>
          <div class="menu-right-bg" style="marign-top: 30px">
            <transition name="slide-fade">
              <ComplieChildMenuNodeFullShape
                :shapeBorderType="shapeBorderType"
                v-if="fullShapeTypeAccout" />
            </transition>
          </div>
        </div>
        <div class="menu-linecolor-box" @click="setBorderAccout">
          <div class="menu-left-accout">
            {{ getString(strings.Mind_Edit_Right_Menu_Border_Color) }}
          </div>
          <div class="menu-right-accout">
            <div
              class="menu-right-linecolor"
              :style="{ background: borderColor }"
            ></div>
          </div>
        </div>
        <div class="menu-right-bg">
          <transition name="slide-fade">
            <CommonBgColor v-if="borderAccout" />
          </transition>
        </div>
        <!-- 节点阴影 -->
        <section class="menu-linecolor-box border-dotted-line">
          <div class="menu-left-accout">
            <a-checkbox :checked="nodeIsShadow" @change="nodeChangeShadow">
                <span :class="mapEditDarkMode?'checkbox-deep':'checkbox-shallow'">
                  {{ getString(strings.Node_Shadow_Label) }}
                </span>
              </a-checkbox>
          </div>
          <div class="menu-right-accout">
            <!-- <a-switch
              :defaultChecked="false"
              :checked="borderIsDottedLine"
              @change="borderChangeDottedLine"
            /> -->
          </div>
        </section>

        <!-- 边框宽度  -->
        <div class="menu-change-number-box">
          <div>
            <div class="menu-left-title">
              <a-checkbox :checked="showBorderUsed" @change="changeBorderValTo()">
                <span :class="mapEditDarkMode?'checkbox-deep':'checkbox-shallow'">{{ getString(strings.Mind_Edit_Right_Menu_Border_Width) }}</span>
              </a-checkbox>
            </div>
          </div>
          <!-- <div>
            <a-slider
              v-model="borderSizeSlider"
              :tooltip-visible="false"
              :min="borderSizeSliderExtremum.min"
              :max="borderSizeSliderExtremum.max"
              :step="zeroPointFive"
              @change="changeBorderVal"
            />
          </div> -->
          <div class="selected-box">
            <WzcSelect
              :width="76"
              :height="24"
              :maxHeight="200"
              :placeholder="borderSizeSlider"
              :itemList="borderSizeSliderItem"
              :inputIdName="'borderSizeSlider'"
              :sliderMum ="borderSizeSliderExtremum"
              @changeInputVal="changeBorderVal"
            >
              <template v-slot:wzc_option>
                <WzcOption
                  v-for="item in borderSizeSliderItem"
                  :key="item.id"
                  :label="item.name"
                  :precision="true"
                  :optionid="item.id"
                  :sizeSliderCurrent="borderSizeSlider"
                  @changeVal="changeBorderVal"
                ></WzcOption>
              </template>
            </WzcSelect>
          </div>
        </div>
        <!-- 自定义宽度  -->
        <div class="menu-change-number-box">
          <div>
            <div class="menu-left-title">
              <a-checkbox :checked="showNodeWidthUsed" @change="changeNodeWidthValTo()">
                <span :class="mapEditDarkMode?'checkbox-deep':'checkbox-shallow'">{{ getString(strings.Mind_Edit_Menu_Custom_Width) }}</span>
              </a-checkbox>
            </div>
          </div>
          <div class="selected-box">
            <WzcSelect
              :width="76"
              :height="24"
              :maxHeight="200"
              :placeholder="nodeWidthSizeSlider"
              :itemList="nodeWidthSizeSliderItem"
              :inputIdName="'nodeWidthSizeSlider'"
              :sliderMum ="nodeWidthSizeSliderExtremum"
              @changeInputVal="changeNodeWidthVal"
            >
              <template v-slot:wzc_option>
                <WzcOption
                  v-for="item in nodeWidthSizeSliderItem"
                  :key="item.id"
                  :label="item.name"
                  :optionid="item.id"
                  :sizeSliderCurrent="nodeWidthSizeSlider"
                  @changeVal="changeNodeWidthVal"
                ></WzcOption>
              </template>
            </WzcSelect>
          </div>
        </div>
        <!-- 边框虚线 -->
        <section class="menu-linecolor-box border-dotted-line">
          <div class="menu-left-accout">
            <a-checkbox :checked="borderIsDottedLine" @change="borderChangeDottedLine">
                <span :class="mapEditDarkMode?'checkbox-deep':'checkbox-shallow'">
                  {{ getString(strings.Border_Dashed_Line) }}
                </span>
              </a-checkbox>
          </div>
          <div class="menu-right-accout">
          </div>
        </section>

        <section class="menu-linecolor-box border-dotted-line" v-show="isToPeerNode || isToAllSonNode">
          <div :class="isToAllSonNode?(mapEditDarkMode?'menu-left-accout style-to-node-peer-and-all-son-darkmode':'menu-left-accout style-to-node-peer-and-all-son'):(mapEditDarkMode?'menu-left-accout style-to-node-only-peer-darkmode':'menu-left-accout style-to-node-only-peer')">
            <div 
            :class="isToAllSonNode?(mapEditDarkMode?'style-to-node-cell-divide-darkmode':'style-to-node-cell-divide'):(mapEditDarkMode?'style-to-node-cell-darkmode':'style-to-node-cell')"
              v-show="isToPeerNode" @click="setStyleToPeer">
            <span :class="mapEditDarkMode?'checkbox-deep':'checkbox-shallow'">
                  {{ getString(strings.Mind_Style_To_Peer) }}
                </span>
            </div>
            <div :class="mapEditDarkMode?'style-to-node-cell-divide-darkmode':'style-to-node-cell-divide'" 
            v-show="isToAllSonNode"
            @click="setStyleToAllSon">
              <span :class="mapEditDarkMode?'checkbox-deep':'checkbox-shallow'">
                    {{ getString(strings.Mind_Style_To_All_Son_Subject) }}
                  </span>
            </div>
          </div>
          
        </section>
        <!-- <div class="menu-linecolor-box">
          <div class="menu-left-accout">
            <div>
              {{ getString(strings.Mind_Edit_Right_Menu_Show_Shadow_Effect) }}
            </div>
          </div>
          <div class="menu-right-accout">
            <a-switch default-checked @onChanges="onChange" />
          </div>
        </div> -->
      </div>

      <div class="menu-linecolor">
        <div
          class="menu-linecolor-box"
          style="margin-top: 12px"
          :class="{ 'no-drop-style': !changeNodeLayout }"
        >
          <div class="menu-left-accout">
            {{ getString(strings.Mind_Edit_Right_Menu_Layout_branch) }}
          </div>
          <div
            @click.stop="setNodeStructure()"
            class="menu-right-accout"
            style="border: 1px solid var(--exportMenuDashedColor); padding: 0 5px; background: #fffff;width: 76px;height: 32px;"
          >
            <div
              class="menu-right-border-box"
              style="border-radius: 10px"
              :class="{ 'no-drop-style': !changeNodeLayout }"
            >
              <img
                class="menu-right-border-icon"
                style="width:28px;margin-left: 6px;"
                :src="structureDefaultImg"
                alt=""
              />
            </div>
            <a-icon
              class="imgthree"
              :class="nodeStructMenuShow?'imgthree-sel':'imgthree-no'"
              style="margin-left:20px;"
              type="caret-up"
            />
          </div>
          <div class="menu-right-bg" style="marign-top: -230px">
            <transition name="slide-fade">
              <ComplieChildMenuNodeLayout
                :layoutList="layoutList"
                :selectedLayoutType="selectedLayoutType"
                v-if="nodeStructMenuShow"
              />
            </transition>
          </div>
        </div>
        <div class="line-box"></div>

        <div
          class="menu-linecolor-box"
          style="margin-top: 12px"
          :class="{ 'no-drop-style': !changeNodeLayout }"
        >
          <div class="menu-left-accout">
            {{ getString(strings.Mind_Edit_Right_Menu_Line_branch) }}
          </div>
          <div
            @click.stop="setIsLineStlyeAccout()"
            class="menu-right-accout"
            style="border: 1px solid var(--exportMenuDashedColor); padding: 0 5px; background: #fffff;width: 76px;height: 32px;"
          >
            <div
              class="menu-right-border-box"
              style="border-radius: 10px"
              :class="{ 'no-drop-style': !changeNodeLayout }"
            >
              <img
                class="menu-right-border-icon"
                :src="lineStyleLayoutImg"
                alt=""
              />
            </div>
            <a-icon
              class="imgthree"
              :class="nodeStructMenuShow?'imgthree-sel':'imgthree-no'"
              style="margin-left:13px;"
              type="caret-up"
            />
          </div>
          <div class="menu-right-bg" style="marign-top: 20px">
            <transition name="slide-fade">
              <ComplieChildMenuBorder v-if="isLineStlyeAccout" />
            </transition>
          </div>
        </div>

        <div class="menu-linecolor-box" @click="setLineColorAccout">
          <div class="menu-left-accout">
            {{ getString(strings.Mind_Edit_Right_Menu_Line_Color)}}
          </div>
          <div class="menu-right-accout">
            <div
              class="menu-right-linecolor"
              style="background: #ffc5c5"
              :style="{ background: borderMinorStyle }"
            ></div>
          </div>
        </div>
        <div class="menu-right-bg">
          <transition name="slide-fade">
            <CommonBgColor v-if="lineColorAccout" style="margin-top:-280px"/>
          </transition>
        </div>
        <!-- 线条宽度 -->
        <div class="menu-change-number-box">
          <div>
            <div class="menu-left-title">
              {{ getString(strings.Mind_Edit_Right_Menu_Line_Width) }}
            </div>
          </div>
          <!-- <div>
            <a-slider
              v-model="lineSizeSlider"
              :tooltip-visible="false"
              :min="lineSizeSliderExtremum.min"
              :max="lineSizeSliderExtremum.max"
              :step="zeroPointFive"
              @change="changeLineWidthVal"
            />
          </div> -->
          <div class="selected-box">
            <WzcSelect
              :width="76"
              :height="24"
              :maxHeight="200"
              :placeholder="lineSizeSlider"
              :itemList="lineSizeSliderItem"
              :inputIdName="'lineSizeSlider'"
              :sliderMum ="lineSizeSliderExtremum"
              @changeInputVal="changeLineWidthVal"
            >
              <template v-slot:wzc_option>
                <WzcOption
                  v-for="item in lineSizeSliderItem"
                  :key="item.id"
                  :label="item.name"
                  :precision="true"
                  :optionid="item.id"
                  :sizeSliderCurrent="lineSizeSlider"
                  @changeVal="changeLineWidthVal"
                ></WzcOption>
              </template>
            </WzcSelect>
          </div>
        </div>
        <!-- 线条阴影 -->
        <section class="menu-linecolor-box border-dotted-line">
          <div class="menu-left-accout">
            <a-checkbox :checked="lineIsShadow" @change="lineChangeShadow">
                <span :class="mapEditDarkMode?'checkbox-deep':'checkbox-shallow'">
                  {{ getString(strings.Node_Shadow_Label) }}
                </span>
              </a-checkbox>
          </div>
          <div class="menu-right-accout">
            <!-- <a-switch
              :defaultChecked="false"
              :checked="borderIsDottedLine"
              @change="borderChangeDottedLine"
            /> -->
          </div>
        </section>
        <!-- 线条虚线 -->
        <section class="menu-linecolor-box line-dotted-line">
          <div class="menu-left-accout">
            <a-checkbox :checked="lineIsDottedLine" @change="lineChangeDottedLine">
                <span :class="mapEditDarkMode?'checkbox-deep':'checkbox-shallow'">
                  {{ getString(strings.Line_Dashed_Line) }}
                </span>
            </a-checkbox>
            
          </div>
          <div class="menu-right-accout">
            <!-- <a-switch
              :defaultChecked="false"
              :checked="lineIsDottedLine"
              @change="lineChangeDottedLine"
            /> -->
          </div>
        </section>
        <div class="menu-change-number-box" v-if="isBubblue">
          <div>
            <div class="menu-left-title">连接线条长度</div>
            <!-- {{ getString(strings.Mind_Edit_Right_Menu_Child_Font) }} -->
          </div>
          <div class="selected-box">
            <a-input-number
              id="inputNumber"
              style="width: 62px"
              v-model="lineLength"
              :step="10"
              :min="0"
              :max="300"
              @change="onChangeLineLength"
            />
          </div>
        </div>
      </div>

      <div v-show="showMindImgMenu" class="menu-linecolor">
        <div class="menu-change-number-box">
          <div>
            <div class="menu-left-title">
              {{ getString(strings.Mind_Edit_Right_Menu_Picture_Size) }}
            </div>
          </div>
          <!-- <div>
            <a-slider
              v-model="imgFontSize"
              :tooltip-visible="false"
              :min="imgFontSizeSliderExtremum.min"
              :max="imgFontSizeSliderExtremum.max"
              @change="changeImgSizeVal"
            />
          </div> -->
          <div class="selected-box">
            <WzcSelect
              :width="76"
              :height="24"
              :maxHeight="200"
              :placeholder="imgFontSize"
              :itemList="imgFontSizeSliderItem"
              :inputIdName="'imgFontSize'"
              :sliderMum ="imgFontSizeSliderExtremum"
              @changeInputVal="changeImgSizeVal"
            >
              <template v-slot:wzc_option>
                <WzcOption
                  v-for="item in imgFontSizeSliderItem"
                  :key="item.id"
                  :label="item.name"
                  :optionid="item.id"
                  :sizeSliderCurrent="imgFontSize"
                  @changeVal="changeImgSizeVal"
                ></WzcOption>
              </template>
            </WzcSelect>
          </div>
        </div>

        <div class="menu-linecolor-box" style="margin-top: 12px">
          <div class="menu-left-accout">
            <div>
              {{ getString(strings.Mind_Edit_Right_Menu_Picture_Location) }}
            </div>
          </div>
          <div class="menu-right-accout">
            <div
              style="margin: 0 0 0 6px"
              v-for="(item, index) in iconTargetList"
              :key="index"
              @click="setImageTarget(index)"
            >
              <img
                :src="
                  iconTargetAccout == index
                    ? item.iconTargetAf
                    : item.iconTargetBf
                "
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="menu-linecolor-box" style="margin-top: 12px">
          <div class="menu-left-accout">
            <div>
              {{ getString(strings.Mind_Edit_Right_Menu_Picture_Shape) }}
            </div>
          </div>
          <div class="menu-right-accout">
            <div
              style="margin: 0 0 0 10px"
              v-for="(item, index) in iconShapeList"
              :key="index"
              @click="setIconLayoutAccout(index)"
            >
              <img
                :src="
                  iconShapeAccout == index ? item.iconShapeAf : item.iconShapeBf
                "
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div v-show="showMindIconMenu" class="menu-linecolor">
        <div class="menu-change-number-box">
          <div>
            <div class="menu-left-title">
              {{ getString(strings.Mind_Edit_Right_Menu_Icon_Size) }}
            </div>
          </div>
          <!-- <div>
            <a-slider
              v-model="iconFontSize"
              :tooltip-visible="false"
              :min="iconFontSizeSliderExtremum.min"
              :max="iconFontSizeSliderExtremum.max"
              @change="changeIconSizeVal"
            />
          </div> -->
          <div class="selected-box">
            <WzcSelect
              :width="76"
              :height="24"
              :maxHeight="200"
              :placeholder="iconFontSize"
              :itemList="iconFontSizeSliderItem"
              :inputIdName="'iconFontSize'"
              :sliderMum ="iconFontSizeSliderExtremum"
              @changeInputVal="changeIconSizeVal"
            >
              <template v-slot:wzc_option>
                <WzcOption
                  v-for="item in iconFontSizeSliderItem"
                  :key="item.id"
                  :label="item.name"
                  :optionid="item.id"
                  :sizeSliderCurrent="iconFontSize"
                  @changeVal="changeIconSizeVal"
                ></WzcOption>
              </template>
            </WzcSelect>
          </div>
        </div>
        <div class="menu-linecolor-box" style="margin-top: 12px">
          <div class="menu-left-accout">
            <div>
              {{ getString(strings.Mind_Edit_Right_Menu_Icon_Position) }}
            </div>
          </div>
          <div class="menu-right-accout">
            <div
              style="margin: 0px 4px"
              v-for="(item, index) in iconPlaceList"
              :key="index"
              @click="setIconPlaceAccout(index)"
            >
              <img
                :src="
                  iconPlaceAccout == index ? item.iconPlaceAf : item.iconPlaceBf
                "
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 包围线面板 -->
    <div class="menu-baowei" v-show="!isBaoWeiToNode">
      <div class="menu-linecolor">
        <div class="menu-linecolor-box" >
          <div class="menu-left-accout">
            {{ getString(strings.Mind_Edit_Right_Menu_text) }}
          </div>
        </div>
        <div class="menu-linecolor-box">
          <div class="menu-left-accout">
            <div class="selected-box">
              <WzcSelect
                :width="76"
                :height="24"
                :maxHeight="200"
                :placeholder="baoWeiTitleFontsize"
                :itemList="baoWeiTitleFontsizeItems"
                :inputIdName="'baoWeiTitleFontsize'"
                :sliderMum ="baoWeiTitleFontsizeSliderExtremum"
                @changeInputVal="changeBaoWeiTitleFontsizeVal"
              >
                <template v-slot:wzc_option>
                  <WzcOption
                    v-for="item in baoWeiTitleFontsizeItems"
                    :key="item.id"
                    :label="item.name"
                    :optionid="item.id"
                    :sizeSliderCurrent="baoWeiTitleFontsize"
                    @changeVal="changeBaoWeiTitleFontsizeVal"
                  ></WzcOption>
                </template>
              </WzcSelect>
            </div>
          </div>
          <div>
            <div
              @click.stop="setBaoWeiTitleColorAccout"
              class="menu-right-linecolor"
              style="background: #ffc5c5;margin-left: -48px;width:50px;"
              :style="{ background: textColor }"
            ></div>
          </div>
          <div class="menu-right-accout">
            <div
              v-for="(item, index) in fontStlyeList"
              :key="index"
              class="menu-font-stlye"
              :class="
                item.fontStyleAccout ? 'font-stlye-before' : 'menu-font-stlye'
              "
              @click="setFontStlyeAccout(index)"
            >
              <img
                v-if="mapEditDarkMode"
                :src="item.fontStlyeImgWhite"
                alt=""
              />
              <img v-else :src="item.fontStlye" alt="" />
            </div>
          </div>
        </div>
        <div class="menu-right-bg">
          <transition name="slide-fade">
            <CommonBgColor v-if="textColorAccoutEncircle" />
          </transition>
        </div>
      </div>
      <div class="menu-linecolor">
        <!-- 标题位置 -->
        <div
          class="menu-linecolor-box margin-top-12"
        >
          <div class="menu-left-accout">{{ getString(strings.Title_Location) }}</div>
          <div
            @click.stop="setEncircleTitleType()"
            class="menu-right-accout"
            style="border: 1px solid var(--exportMenuDashedColor); 
              padding:0 5px;
              background: #fffff;
              width: 76px;
              height: 32px;"
          >
            <div
              class="menu-right-border-box"
              style="border-radius: 10px; min-width: 38px"
            >
              <img
                class="menu-right-border-icon"
                :src="encircleLayoutImg"
                alt=""
              />
            </div>
            <a-icon
              class="imgthree"
              :class="fromBorderAccout?'imgthree-sel':'imgthree-no'"
              type="caret-up"
            />
          </div>
          <div class="menu-right-bg" style="margin-top: 190px">
            <transition name="slide-fade">
              <ComplieChildMenuEncircle v-if="encircleTitleAccout" />
            </transition>
          </div>
        </div>
        <!-- <div
          class="menu-linecolor-box"
          @click="setWrappingLineTitleType"
          style="margin-top: 12px"
        >
          <div class="menu-left-accout">主题位置</div>
          <div
            class="menu-right-accout"
            style="
              border: solid 1px #cccccc;
              padding: 10px 7px;
              background: #fffff;
            "
          >
            <div
              class="menu-right-border-box"
              style="width: 41px; height: 26px"
            >
              <img
                class="menu-right-border-icon"
                :src="borderStyleLayout"
                alt=""
              />
            </div>
          </div>
          <div class="menu-right-bg" style="marign-top: 30px">
            <transition name="slide-fade" v-if="showWrappingLineTitle">
              <ComplieChildMenuWrappingLineTilte />
            </transition>
          </div>
        </div> -->
      </div>

      <div class="menu-linecolor">
        <div
          class="menu-linecolor-box"
          style="margin-top: 12px"
        >
          <div class="menu-left-accout">{{ getString(strings.Mind_Edit_Right_Menu_Border_Shape) }}</div>
          <div
            @click.stop="setFromBorderAccout"
            class="menu-right-accout"
            style="
              border: solid 1px var(--exportMenuDashedColor);
              padding: 0px 5px;
              background: #fffff;
              width: 76px;
              height: 32px;
            "
          >
            <div
              class="menu-right-border-box"
              style="width: 41px; height: 30px"
            >
              <img
                class="menu-right-border-icon"
                :src="borderStyleLayout"
                alt=""
              />
            </div>
            <a-icon
              class="imgthree"
              :class="fromBorderAccout?'imgthree-sel':'imgthree-no'"
              type="caret-up"
            />
          </div>
          <div class="menu-right-bg" style="margin-top: 190px">
            <transition name="slide-fade" v-if="fromBorderAccout">
              <ComplieChildMenuEncircleLineForm />
            </transition>
          </div>
        </div>
        <div class="line-box"></div>
        <div class="menu-linecolor-box" @click="setBaoWeiLineColorAccout">
          <div class="menu-left-accout">
            {{ getString(strings.Mind_Edit_Right_Menu_Line_Color) }}
          </div>
          <div class="menu-right-accout">
            <div
              class="menu-right-linecolor"
              style="background: #ffc5c5"
              :style="{ background: baoWeiLineColor }"
            ></div>
          </div>
        </div>
        <div class="menu-right-bg">
          <transition name="slide-fade">
            <CommonBgColor v-if="baoWeiLineColorAccout" />
          </transition>
        </div>

        <div class="menu-linecolor-box" @click="setBaoWeiFillColorAccout">
          <div class="menu-left-accout">
            {{ getString(strings.Mind_Edit_Right_Menu_Fill_Color) }}
          </div>
          <div class="menu-right-accout">
            <div
              class="menu-right-linecolor"
              style="background: #ffc5c5"
              :style="{ background: baoWeiFillColor }"
            ></div>
          </div>
        </div>
        <div class="menu-right-bg">
          <transition name="slide-fade">
            <CommonBgColor v-if="baoWeiFillColorAccout" />
          </transition>
        </div>
        <!-- 摘要线条宽度 -->
        <div class="menu-change-number-box">
          <div>
            <div class="menu-left-title">
              {{ getString(strings.Mind_Edit_Right_Menu_Line_Width) }}
            </div>
          </div>
          <div class="selected-box">
            <WzcSelect
              :width="76"
              :height="24"
              :maxHeight="200"
              :placeholder="lineSizeSlider"
              :itemList="lineSizeSliderItem"
              :inputIdName="'lineSizeSliderBaoWei'"
              :sliderMum ="lineSizeSliderExtremum"
              @changeInputVal="changeLineWidthVal"
            >
              <template v-slot:wzc_option>
                <WzcOption
                  v-for="item in lineSizeSliderItem"
                  :key="item.id"
                  :label="item.name"
                  :precision="true"
                  :optionid="item.id"
                  :sizeSliderCurrent="lineSizeSlider"
                  @changeVal="changeLineWidthVal"
                ></WzcOption>
              </template>
            </WzcSelect>
          </div>
        </div>

        <div class="menu-change-number-box">
          <div>
            <div class="menu-left-title">{{ getString(strings.Wrapping_Line_Padding) }}</div>
          </div>
          <div>
            <!-- <a-slider
              v-model="paddingSizeSlider"
              :tooltip-visible="false"
              :min="paddingSizeSliderExtremum.min"
              :max="paddingSizeSliderExtremum.max"
              @change="changePaddingSizeVal"
            /> -->
          </div>
          <div class="selected-box">
            <WzcSelect
              :width="76"
              :height="24"
              :maxHeight="200"
              :placeholder="paddingSizeSlider"
              :itemList="paddingSizeSliderItem"
              :inputIdName="'paddingSizeSlider'"
              :sliderMum ="paddingSizeSliderExtremum"
              @changeInputVal="changePaddingSizeVal"
            >
              <template v-slot:wzc_option>
                <WzcOption
                  v-for="item in paddingSizeSliderItem"
                  :key="item.id"
                  :label="item.name"
                  :optionid="item.id"
                  :sizeSliderCurrent="paddingSizeSlider"
                  @changeVal="changePaddingSizeVal"
                ></WzcOption>
              </template>
            </WzcSelect>
          </div>
        </div>
        
        <!-- 虚线 -->
        <div class="menu-linecolor-box">
          <div class="menu-left-accout">
            <a-checkbox :checked="isDottedLine" @change="onChangeLine">
                <span :class="mapEditDarkMode?'checkbox-deep':'checkbox-shallow'">
                  {{ getString(strings.Mind_Edit_Right_Menu_Dotted_Line) }}
                </span>
            </a-checkbox>
          </div>
          <div class="menu-right-accout">
            <!-- <a-switch
              :defaultChecked="false"
              :checked="isDottedLine"
              @change="onChangeLine"
            /> -->
          </div>
        </div>
        <div class="menu-linecolor-box">

        <div class="menu-left-accout">
          <a-checkbox :checked="lineIsShadow" @change="lineChangeShadow">
              <span :class="mapEditDarkMode?'checkbox-deep':'checkbox-shallow'">
                {{ getString(strings.Node_Shadow_Label) }}
              </span>
          </a-checkbox>
        </div>
        <div class="menu-right-accout">
          <!-- <a-switch
            :defaultChecked="false"
            :checked="isDottedLine"
            @change="onChangeLine"
          /> -->
        </div>
      </div>
      </div>
      <div class="menu-linecolor">
        <div class="menu-linecolor-box">
          <div @click="showCheckImage()" class="menu-left-accout">
            <div>{{getString(strings.Mind_Edit_Node_Menu_Add_Encircle_Background)}}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 连接线面板 -->
    <div class="menu-baowei" v-show="!isNodeToLine">
      <div class="menu-linecolor">
        <div class="menu-linecolor-box" >
          <div class="menu-left-accout">
            {{ getString(strings.Mind_Edit_Right_Menu_text) }}
          </div>
        </div>
        <div class="menu-linecolor-box">
          <div class="menu-left-accout">
            <div class="selected-box">
            <WzcSelect
              :width="76"
              :height="24"
              :maxHeight="200"
              :placeholder="fontSizeSlider"
              :itemList="fontItems"
              :inputIdName="'fontSizeSliderConnectionLine'"
              :sliderMum ="fontExtremum"
              @changeInputVal="changeSliderVal"
            >
              <template v-slot:wzc_option>
                <WzcOption
                  v-for="item in fontItems"
                  :key="item.id"
                  :label="item.name"
                  :optionid="item.id"
                  :sizeSliderCurrent="fontSizeSlider"
                  @changeVal="changeSliderVal"
                ></WzcOption>
              </template>
            </WzcSelect>
          </div>
          </div>
          <div>
            <div
              @click.stop="setTextColorAccout"
              class="menu-right-linecolor"
              style="background: #ffc5c5;margin-left: -48px;width:50px;"
              :style="{ background: textColor }"
            ></div>
          </div>
          <div class="menu-right-accout">
            <div
              v-for="(item, index) in fontStlyeList"
              :key="index"
              class="menu-font-stlye"
              :class="
                item.fontStyleAccout ? 'font-stlye-before' : 'menu-font-stlye'
              "
              @click="setFontStlyeAccout(index)"
            >
              <img
                v-if="mapEditDarkMode"
                :src="item.fontStlyeImgWhite"
                alt=""
              />
              <img v-else :src="item.fontStlye" alt="" />
            </div>
          </div>
        </div>
        <div class="menu-right-bg">
          <transition name="slide-fade">
            <CommonBgColor v-if="textColorAccout" />
          </transition>
        </div>
      </div>

      <div class="menu-linecolor">

        <div
          class="menu-linecolor-box"
          style="margin-top: 12px"
        >
          <div class="menu-left-accout">
            {{ getString(strings.Mind_Edit_Right_Menu_Correlation_Line) }}
          </div>
          <div
            class="menu-right-accout"
            @click.stop="setCorrelationLineType()"
            style="border: 1px solid var(--exportMenuDashedColor); 
              padding: 5px; 
              background: #fffff;
              width: 76px;
              height: 32px;"
          >
            <div class="menu-right-border-box" style="border-radius: 10px;margin-left: -10px;">
              <img
                class="menu-right-border-icon"
                :src="correlationLineStyleLayoutImg"
                alt=""
              />
            </div>
            <a-icon
              class="imgthree"
              :class="showCorrelationLineType?'imgthree-sel':'imgthree-no'"
              type="caret-up"
              style="margin-left: 0px;line-height: 23px;"
            />
          </div>
          <div
            class="menu-right-bg"
            style="top: 50px; background-color: #ffffff"
          >
            <transition name="slide-fade">
              <ComplieChildMenuCorrelationLine v-if="showCorrelationLineType" />
            </transition>
          </div>
        </div>

        <div class="line-box"></div>

        <div
          class="menu-linecolor-box"
          style="margin-top: 12px"
        >
          <div class="menu-left-accout">
            {{ getString(strings.Mind_Edit_Right_Menu_Correlation_Line_Start) }}
          </div>
          <div
            class="menu-right-accout"
            @click.stop="setCorrelationLineStartType()"
            style="border: 1px solid var(--exportMenuDashedColor); 
              padding: 5px; 
              background: #fffff;
              width: 76px;
              height: 32px;"
          >
            <div class="menu-right-border-box" style="border-radius: 10px;margin-left: -10px;">
              <img
                class="menu-right-border-icon"
                :src="correlationLineStartImg"
                alt=""
              />
            </div>
            <a-icon
              class="imgthree"
              :class="showCorrelationLineStartType?'imgthree-sel':'imgthree-no'"
              style="margin-left: 0px;line-height: 23px;"
              type="caret-up"
            />
          </div>
          <div
            class="menu-right-bg"
            style="top: 50px; background-color: #ffffff"
          >
            <transition name="slide-fade">
              <ComplieChildMenuCorrelationLineStartOrEnd
                :startOrEnd="'start'"
                v-if="showCorrelationLineStartType"
              />
            </transition>
          </div>
        </div>

        <div
          class="menu-linecolor-box"
          style="margin-top: 12px"
        >
          <div class="menu-left-accout">
            {{ getString(strings.Mind_Edit_Right_Menu_Correlation_Line_End) }}
          </div>
          <div
            class="menu-right-accout"
            @click.stop="setCorrelationLineEndType()"
            style="border: 1px solid var(--exportMenuDashedColor); 
              padding: 5px; 
              background: #fffff;
              width: 76px;
              height: 32px;"
          >
            <div class="menu-right-border-box" style="border-radius: 10px;margin-left: -10px;">
              <img
                class="menu-right-border-icon"
                :src="correlationLineEndImg"
                alt=""
              />
            </div>
            <a-icon
              class="imgthree"
              :class="showCorrelationLineEndType?'imgthree-sel':'imgthree-no'"
              style="margin-left: 0px;line-height: 23px;"
              type="caret-up"
            />
          </div>
          <div
            class="menu-right-bg"
            style="top: 50px; background-color: #ffffff"
          >
            <transition name="slide-fade">
              <ComplieChildMenuCorrelationLineStartOrEnd
                :startOrEnd="'end'"
                v-if="showCorrelationLineEndType"
              />
            </transition>
          </div>
        </div>

        <div class="menu-linecolor-box" @click="setLineColorAccout">
          <div class="menu-left-accout">
            {{ getString(strings.Mind_Edit_Right_Menu_Line_Color) }}
          </div>
          <div class="menu-right-accout">
            <div
              class="menu-right-linecolor"
              style="background: #ffc5c5"
              :style="{ background: borderMinorStyle }"
            ></div>
          </div>
        </div>
        <div class="menu-right-bg">
          <transition name="slide-fade" v-if="lineColorAccout">
            <CommonBgColor />
          </transition>
        </div>
        <!-- 关联线线条宽度 -->
        <div class="menu-change-number-box">
          <div>
            <div class="menu-left-title">
              {{ getString(strings.Mind_Edit_Right_Menu_Line_Width) }}
            </div>
          </div>
          <div>
            <!-- <a-slider
              v-model="lineSizeSlider"
              :tooltip-visible="false"
              :min="lineSizeSliderExtremum.min"
              :max="lineSizeSliderExtremum.max"
              :step="zeroPointFive"
              @change="changeLineWidthVal"
            /> -->
          </div>
          <div class="selected-box">
            <WzcSelect
              :width="76"
              :height="24"
              :maxHeight="200"
              :placeholder="lineSizeSlider"
              :itemList="lineSizeSliderItem"
              :inputIdName="'lineSizeSliderWrappingLine'"
              :sliderMum ="lineSizeSliderExtremum"
              @changeInputVal="changeLineWidthVal"
            >
              <template v-slot:wzc_option>
                <WzcOption
                  v-for="item in lineSizeSliderItem"
                  :key="item.id"
                  :label="item.name"
                  :precision="true"
                  :optionid="item.id"
                  :sizeSliderCurrent="lineSizeSlider"
                  @changeVal="changeLineWidthVal"
                ></WzcOption>
              </template>
            </WzcSelect>
          </div>
        </div>
        <!-- 关联线虚线 -->
        <div class="menu-linecolor-box">
          <div class="menu-left-accout">
            <a-checkbox :checked="isDottedLine" @change="onChangeLine">
                <span :class="mapEditDarkMode?'checkbox-deep':'checkbox-shallow'">
                  {{ getString(strings.Mind_Edit_Right_Menu_Dotted_Line) }}
                </span>
            </a-checkbox>
          </div>
          <div class="menu-right-accout">
            <!-- <a-switch
              :defaultChecked="false"
              :checked="isDottedLine"
              @change="onChangeLine"
            /> -->
          </div>
        </div>
        <div class="menu-linecolor-box">
          <div class="menu-left-accout">
            <a-checkbox :checked="lineIsShadow" @change="lineChangeShadow">
                <span :class="mapEditDarkMode?'checkbox-deep':'checkbox-shallow'">
                  {{ getString(strings.Node_Shadow_Label) }}
                </span>
            </a-checkbox>
          </div>
          <div class="menu-right-accout">
            <!-- <a-switch
              :defaultChecked="false"
              :checked="isDottedLine"
              @change="onChangeLine"
            /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import CommonBgColor from "../../common/CommonBgColor/CommonBgColor";
import ComplieChildMenuForm from "../ComplieRightMenuChild/ComplieChildMenuForm";
import ComplieChildMenuEncircle from "../ComplieRightMenuChild/ComplieChildMenuEncircle";
import ComplieChildMenuNodeLayout from "../ComplieRightMenuChild/ComplieChildMenuNodeLayout";
import ComplieChildMenuNodeFullShape from "../ComplieRightMenuChild/ComplieChildMenuNodeFullShape";
import ComplieChildMenuEncircleLineForm from "../ComplieRightMenuChild/ComplieChildMenuEncircleLineForm";
import ComplieChildMenuWrappingLineTilte from "../ComplieRightMenuChild/ComplieChildMenuWrappingLineTilte";
import Colors from "../../../utils/Colors";
import ImageLayoutType from "../../../viewmodel/datatype/ImageLayoutType";
import ImageShapeType from "../../../viewmodel/datatype/ImageShapeType";
import MindElementType from "../../../viewmodel/datatype/MindElementType";
import ColorToSystem from "../../../utils/ColorToSystem";
import colorChangeClass from "../../../utils/ColorToSystem";

import complieRightMenu from "@/assets/css/complieRightMenu/complieRightMenu.less";
import strings from "../../../common/lang/strings";
import getString from "../../../common/lang/language";
import MindElementAlignmentType from "../../../viewmodel/datatype/MindElementAlignmentType";
import LayoutData from "../tools/LayoutData";
import LayoutImg from "../tools/LayoutImg";
import BorderLineImg from "../tools/BorderLineImg";
import ShapeTypeImg from "../tools/ShapeTypeImg";
import NodeBackgroundFullShapeImg from "../tools/NodeBackgroundFullShapeImg";;

import EditMindmapVM from "../../../viewmodel/mindelementdata/vm/EditMindmapVM";

import WzcSelect from "../../common/Select/WzcSelect";
import WzcOption from "../../common/Select/WzcOption";

import WzcStrSelect from "../../common/Select/WzcStrSelect";
import WzcStrOption from "../../common/Select/WzcStrOption";

import ComplieChildMenuBorder from "../ComplieRightMenuChild/ComplieChildMenuBorder";
import ComplieChildMenuCorrelationLine from "../ComplieRightMenuChild/ComplieChildMenuCorrelationLine";
import ComplieChildMenuCorrelationLineStartOrEnd from "../ComplieRightMenuChild/ComplieChildMenuCorrelationLineStartOrEnd";
import ConnectLineType from "../../../viewmodel/datatype/ConnectLineType";
import LinePointShape from "../../../viewmodel/datatype/LinePointShape";
import MindType from "../../../viewmodel/datatype/MindType";
import EncircleTitleLayoutType from "../../../viewmodel/datatype/EncircleTitleLayoutType";
import Config from '../../../core/core/calcule/Config';
import FontLoad from '../../../core/core/calcule/FontLoad';
import Util from '../../../utils/Util';

export default {
  data() {
    return {
      fontFamily:"Noto Sans SC", //字体
      fontSizeSlider: 12, //字体大小
      borderSizeSlider: 3, //边框大小
      showBorderUsed:false,//边框是否使用
      nodeWidthSizeSlider: 40, //节点宽度大小
      nodeWidthSizeSliderTo:100,
      showNodeWidthUsed:false,//设置节点宽度是否使用
      lineSizeSlider: 3, //线条大小
      lineLength: 70, //连接线长度
      isBubblue: false, //是不是气泡图
      paddingSizeSlider: 5, // 包裹线与内容边距
      fontStyleAccout: 0, //字体样式
      showWrappingLineTitle: false, //包裹线标题
      imgFontSize: 40, //图片大小
      iconFontSize: 20, //图标大小
      iconTargetAccout: 0, //图片位置
      iconShapeAccout: 0, //图片形状
      iconPlaceAccout: 0, //图标位置
      textColorAccout: false, //文字颜色组件是否显示
      textShadowColorAccout: false, //文字阴影颜色组件是否显示
      lineColorAccout: false, //线条颜色组件是否显示
      fromBorderAccout: false, //线条颜色组件是否显示
      fullShapeTypeAccout: false, //填充样式组件是否显示
      borderAccout: false, //边框形状组件是否显示
      fillColorAccout: false, //节点填充颜色组件是否显示
      baoWeiLineColorAccout: false, //包围线颜色组件是否显示
      baoWeiFillColorAccout: false, //包围填充颜色组件是否显示
      textColorAccoutEncircle: false, //包围线文本颜色组件是否显示
      nodeStructMenuShow: false, //节点结构菜单是否展示。
      isLineStlyeAccout: false, //线条类型菜单是否展示
      showCorrelationLineType: false, //关联线类型组件
      showCorrelationLineStartType: false, //关联线起点形状组件
      showCorrelationLineEndType: false, //关联线终点形状组件
      encircleTitleAccout: false, //包裹线标题位置
      textColor: null, //文本颜色
      borderColor: null, //边框颜色
      fillColor: null, //节点填充颜色
      baoWeiLineColor: null, //包围线填充颜色
      baoWeiFillColor: null, //包围填充颜色
      borderMinorStyle: null, //边框样式
      nodeAccout: this.$store.state.nodeContentMessage, //节点JSON数据
      isNodeToBaoWei: true, //显示包围设置面板
      isBaoWeiToNode: true, //显示节点设置面板
      isNodeToLine: true, //显示连接线设置面板
      baoWeiTitleFontsize: 12, //包裹线字体大小
      iconTargetList: [
        {
          iconTargetBf: require("../../../assets/img/mapIcon/res/mipmap-right-menu/img_target_left_bf.svg"),
          iconTargetAf: require("../../../assets/img/mapIcon/res/mipmap-right-menu/img_target_left_af.svg"),
        },
        {
          iconTargetBf: require("../../../assets/img/mapIcon/res/mipmap-right-menu/img_target_top_bf.svg"),
          iconTargetAf: require("../../../assets/img/mapIcon/res/mipmap-right-menu/img_target_top_af.svg"),
        },
      ], //图片位置Icon
      iconShapeList: [
        {
          iconShapeBf: require("../../../assets/img/mapIcon/res/mipmap-right-menu/square_rotation_bf.svg"),
          iconShapeAf: require("../../../assets/img/mapIcon/res/mipmap-right-menu/square_rotation_af.svg"),
        },
        {
          iconShapeBf: require("../../../assets/img/mapIcon/res/mipmap-right-menu/round_bf.svg"),
          iconShapeAf: require("../../../assets/img/mapIcon/res/mipmap-right-menu/round_af.svg"),
        },
        {
          iconShapeBf: require("../../../assets/img/mapIcon/res/mipmap-right-menu/square_bf.svg"),
          iconShapeAf: require("../../../assets/img/mapIcon/res/mipmap-right-menu/square_af.svg"),
        },
      ], //图片形状Icon
      iconPlaceList: [
        {
          iconPlaceBf: require("../../../assets/img/mapIcon/res/mipmap-right-menu/img_target_right_bf.svg"),
          iconPlaceAf: require("../../../assets/img/mapIcon/res/mipmap-right-menu/img_target_right_af.svg"),
        },
        {
          iconPlaceBf: require("../../../assets/img/mapIcon/res/mipmap-right-menu/img_target_left_bf.svg"),
          iconPlaceAf: require("../../../assets/img/mapIcon/res/mipmap-right-menu/img_target_left_af.svg"),
        },
        {
          iconPlaceBf: require("../../../assets/img/mapIcon/res/mipmap-right-menu/img_target_top_bf.svg"),
          iconPlaceAf: require("../../../assets/img/mapIcon/res/mipmap-right-menu/img_target_top_af.svg"),
        },
      ], 
      fontShadowStlye: 
        {
          url: require("../../../assets/img/mapIcon/res/mipmap-right-menu/font-shadow-color.svg"),
          whiteUrl: require("../../../assets/img/mapIcon/res/mipmap-right-menu/darkModeIcon/font-shadow-white-color.svg"),
          fontStyleAccout: false,
        },
      //图标位置Icon
      fontStlyeList: [
        {
          fontStlye: require("../../../assets/img/mapIcon/res/mipmap-right-menu/font-weight.svg"),
          fontStlyeImgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/darkModeIcon/fontWeightWhite.svg"),
          fontStyleAccout: false,
        },
        {
          fontStlye: require("../../../assets/img/mapIcon/res/mipmap-right-menu/font-italic.svg"),
          fontStlyeImgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/darkModeIcon/fontItalicWhite.svg"),
          fontStyleAccout: false,
        },
        {
          fontStlye: require("../../../assets/img/mapIcon/res/mipmap-right-menu/font-underline.svg"),
          fontStlyeImgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/darkModeIcon/fontUnderlineWhite.svg"),
          fontStyleAccout: false,
        },
      ], //字体样式Icon
      fontAlign: [
        {
          fontStlye: require("../../../assets/img/mapIcon/res/mipmap-right-menu/text_align/left.svg"),
          fontAlignImgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/text_align/darkModeIcon/leftWhite.svg"),
          type: MindElementAlignmentType.LEFT,
          fontStyleAccout: false,
        },
        {
          fontStlye: require("../../../assets/img/mapIcon/res/mipmap-right-menu/text_align/center.svg"),
          fontAlignImgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/text_align/darkModeIcon/centerWhite.svg"),
          type: MindElementAlignmentType.MIDDLE,
          fontStyleAccout: false,
        },
        {
          fontStlye: require("../../../assets/img/mapIcon/res/mipmap-right-menu/text_align/right.svg"),
          fontAlignImgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/text_align/darkModeIcon/rightWhite.svg"),
          type: MindElementAlignmentType.RIGHT,
          fontStyleAccout: false,
        },
      ], //文字对齐方式
      imgDataList: [
        //连接线图片

        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/joinCurve1.png"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/joinCurve1.svg"),
          lineType: ConnectLineType.CURVE_LINE,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/joinStraight2.png"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/joinStraight2.svg"),
          lineType: ConnectLineType.STRAIGHT_ARROW_LINE,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/joinZigZag3.png"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/joinZigZag3.svg"),
          lineType: ConnectLineType.RIGHT_ANGLE_LINE,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/joinDotStraight4.png"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/joinDotStraight4.svg"),
          lineType: ConnectLineType.STRAIGHT_CIRCULAR_LINE,
        },
      ],

      strings: strings, //语言包
      borderStyleLayout: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_radio.svg"), //边框样式
      wrapLineBorderStyleLayout: require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleLine/lightModeIcon/border_trapezoid.svg"), //包裹线边框默认图标
      nodeFullShapeImg:require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/nodeBackgroundFull.svg"), //填充样式
      shapeBorderType: {},
      fullShapeType:{},
      structureDefaultImg: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_right_icon.svg"), //结构默认图片
      lineStyleLayoutImg: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/lightModeIcon/straight_line_icon.svg"), //线条布局默认图片
      correlationLineStyleLayoutImg: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/joinCurve.png"), //关联线默认图片
      correlationLineStartImg: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineStartIcon5.svg"),
      correlationLineEndImg: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineEndIcon5.svg"),
      encircleLayoutImg: require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleTitle/top_left.svg"), //默认包裹线标题的位置
      encircleTitleImgList: [
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleTitle/top_left.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleTitle/darkModeIcon/top_left.svg"),
          nodeType: EncircleTitleLayoutType.TOP_LEFT,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleTitle/top_center.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleTitle/darkModeIcon/top_center.svg"),
          nodeType: EncircleTitleLayoutType.TOP_MIDDLE,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleTitle/top_reight.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleTitle/darkModeIcon/top_reight.svg"),
          nodeType: EncircleTitleLayoutType.TOP_RIGHT,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleTitle/center_left.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleTitle/darkModeIcon/center_left.svg"),
          nodeType: EncircleTitleLayoutType.MIDDLE_LEFT,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleTitle/center_center.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleTitle/darkModeIcon/center_center.svg"),
          nodeType: EncircleTitleLayoutType.MIDDLE_MIDDLE,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleTitle/center_reight.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleTitle/darkModeIcon/center_reight.svg"),
          nodeType: EncircleTitleLayoutType.MIDDLE_RIGHT,
        },
      ],
      imgDataListStart: [
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineStartIcon1.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/associationLineStartEnd/associationLineWhite.svg"),
          lineType: LinePointShape.NORMAL,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineStartIcon2.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/associationLineStartEnd/darkModeIcon/AssociationLineStartIcon2.svg"),
          lineType: LinePointShape.CIRCULAR,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineStartIcon4.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/associationLineStartEnd/darkModeIcon/AssociationLineStartIcon4.svg"),
          lineType: LinePointShape.CIRCULAR_RADIATION,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineStartIcon3.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/associationLineStartEnd/darkModeIcon/AssociationLineStartIcon3.svg"),
          lineType: LinePointShape.CIRCULAR_HOLLOW,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineStartIcon5.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/associationLineStartEnd/darkModeIcon/AssociationLineStartIcon5.svg"),
          lineType: LinePointShape.ARROW,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineStartIcon6.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/associationLineStartEnd/darkModeIcon/AssociationLineStartIcon6.svg"),
          lineType: LinePointShape.BAMBOO_ARROW,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineStartIcon7.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/associationLineStartEnd/darkModeIcon/AssociationLineStartIcon7.svg"),
          lineType: LinePointShape.SIMPLE_ARROW,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineStartIcon8.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/associationLineStartEnd/darkModeIcon/AssociationLineStartIcon8.svg"),
          lineType: LinePointShape.T_SHAPED,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineStartIcon9.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/associationLineStartEnd/darkModeIcon/AssociationLineStartIcon9.svg"),
          lineType: LinePointShape.DIAMOND,
        },
      ],
      imgDataListEnd: [
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineEndIcon1.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/associationLineStartEnd/associationLineWhite.svg"),
          lineType: LinePointShape.NORMAL,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineEndIcon2.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/associationLineStartEnd/darkModeIcon/AssociationLineEndIcon2.svg"),
          lineType: LinePointShape.CIRCULAR,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineEndIcon3.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/associationLineStartEnd/darkModeIcon/AssociationLineEndIcon3.svg"),
          lineType: LinePointShape.CIRCULAR_RADIATION,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineEndIcon4.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/associationLineStartEnd/darkModeIcon/AssociationLineEndIcon4.svg"),
          lineType: LinePointShape.CIRCULAR_HOLLOW,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineEndIcon5.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/associationLineStartEnd/darkModeIcon/AssociationLineEndIcon5.svg"),
          lineType: LinePointShape.ARROW,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineEndIcon6.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/associationLineStartEnd/darkModeIcon/AssociationLineEndIcon6.svg"),
          lineType: LinePointShape.BAMBOO_ARROW,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineEndIcon7.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/associationLineStartEnd/darkModeIcon/AssociationLineEndIcon7.svg"),
          lineType: LinePointShape.SIMPLE_ARROW,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineEndIcon8.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/associationLineStartEnd/darkModeIcon/AssociationLineEndIcon8.svg"),
          lineType: LinePointShape.T_SHAPED,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineEndIcon9.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/associationLineStartEnd/darkModeIcon/AssociationLineEndIcon9.svg"),
          lineType: LinePointShape.DIAMOND,
        },
      ],
      imgProportion: 0, //图片宽高比
      maxBorder: null, //当前的图片大小是按照宽高哪边显示数字
      isDottedLine: false, // 当前线条是否是虚线
      showMindImgMenu: false, //是否展示节点的图片菜单。
      showMindIconMenu: false, //是否展示节点的图标菜单。
      layoutList: [], //可以切换的菜单类型。
      selectedLayoutType: {},
      layoutListImg: new LayoutImg(), //布局的列表数据
      changeNodeLayout: false, //是否可以改变节点布局
      isToPeerNode:false,
      isToAllSonNode:false,
      fontExtremum: {
        //文字大小
        max: 100, //最大
        min: 12, //最小。
      },
      fontItems: [], //线条宽度选择数组。
      fontFamilys: [], //字体列表
      borderSizeSliderExtremum: {
        //边框宽度
        max: 6, //最大
        min: 1, //最小。
      },
      borderSizeSliderItem: [],

      nodeWidthSizeSliderExtremum: {
        //自定义节点宽度
        max: 1000, //最大
        min: 0, //最小。
      },
      nodeWidthSizeSliderItem: [],

      lineSizeSliderExtremum: {
        //线条宽度
        max: 6, //最大
        min: 1, //最小。
      },
      lineSizeSliderItem: [], //线条宽度数组

      imgFontSizeSliderExtremum: {
        //边框宽度
        max: 400, //最大
        min: 40, //最小。
      },
      imgFontSizeSliderItem: [], //图片大小数组。

      iconFontSizeSliderExtremum: {
        //图标宽度
        max: 400, //最大
        min: 20, //最小。
      },
      iconFontSizeSliderItem: [], //图标宽度数组。

      baoWeiTitleFontsizeSliderExtremum: {
        //包裹线标题极值
        max: 100, //最大
        min: 12, //最小。
      },
      baoWeiTitleFontsizeItems: [],

      paddingSizeSliderExtremum: {
        //内边距极值
        max: 400, //最大
        min: 1, //最小。
      },
      paddingSizeSliderItem: [], //
      lineIsDottedLine: false, // 当前分支线条是否是虚线
      borderIsDottedLine: false, // 当前边框是否是虚线
      nodeIsShadow: false, 
      lineIsShadow: false, 
      zeroPointFive: 0.5,
      mousedownViewClassName: "",
      mousedownViewTagName: "",
      isClickCommonMoreBgColor: false,
    };
  },
  components: {
    CommonBgColor,
    ComplieChildMenuForm,
    ComplieChildMenuEncircleLineForm,
    ComplieChildMenuNodeLayout,
    WzcSelect,
    WzcOption,
    WzcStrSelect,
    WzcStrOption,
    ComplieChildMenuBorder,
    ComplieChildMenuCorrelationLine,
    ComplieChildMenuNodeFullShape,
    ComplieChildMenuWrappingLineTilte,
    ComplieChildMenuCorrelationLineStartOrEnd,
    ComplieChildMenuEncircle,
  },
  computed: {
    ...mapState({
      mapEditDarkMode: (state) => state.mapEditIsDardarkMode.darkMode, //深色模式
    }),
  },
  created() {
    //获取vuex存储的节点数据
    if (this.nodeAccout == null) {
      this.nodeAccout = this.$store.state.nodeContentMessage;
    }
    this.fontFamilys = Config.FontFamilys;
    setTimeout(() => {
      let tath = this
      FontLoad.loadUserContent().then(function(succ) {
        if (succ) {
          if (FontLoad.userFont != null && FontLoad.userFont.list.length > 0) {
              for (let index = FontLoad.userFont.list.length - 1; index > -1; index--) {
                let cell = FontLoad.userFont.list[index]
                tath.fontFamilys.splice(2, 0, cell);
              }
          }
        }
      })
    }, 3000);
  },
  mounted() {
    this.setLineSliderItem();
    this.setBorderSizeSliderExtremum();
    this.setNodeWidthSizeSliderExtremum();
    document.addEventListener("mousedown", (e) => {
      this.mousedownViewClassName = e.target.className
      this.mousedownViewTagName = e.target.tagName.toUpperCase();
      let commonMoreBgColor = document.getElementsByClassName("CommonMoreBgColor")
      if (commonMoreBgColor != null && commonMoreBgColor.length > 0) {
        this.isClickCommonMoreBgColor = commonMoreBgColor[0].contains(e.target);
      } else {
        this.isClickCommonMoreBgColor = false
      }
    });
    document.addEventListener("click", (e) => {
      if (this.mousedownViewClassName == null || this.mousedownViewClassName.length == 0 ||
        this.mousedownViewTagName == null || this.mousedownViewTagName.length == 0) {
        this.mousedownViewClassName = e.target.className
        this.mousedownViewTagName = e.target.tagName.toUpperCase();
      }

      var hideTag = this.mousedownViewTagName;
      if (
        (this.mousedownViewClassName != "menu-right-border-box" &&
          this.mousedownViewClassName != "menu-right-border-icon" &&
          this.mousedownViewClassName != "menu-right-linecolor" &&
          this.mousedownViewClassName != "menu-right-accout" &&
          this.mousedownViewClassName != "menu-use-box" &&
          this.mousedownViewClassName != "font-shadow-stlye-before" &&
          this.mousedownViewClassName != "menu-font-shadow-stlye" &&
          this.mousedownViewClassName != "menu-div-font-shadow-stlye" &&
          this.mousedownViewClassName != "menu-img-font-shadow-stlye" &&
          this.mousedownViewClassName != "menu-font-shadow-stlye menu-font-shadow-stlye" &&
          this.mousedownViewClassName != "menu-baowei" &&
          this.mousedownViewClassName != "menu-right-accout-ly" &&
          this.mousedownViewClassName != "menu-right-bgcolor" &&
          this.mousedownViewClassName != "menu-linecolor-box" &&
          this.mousedownViewClassName != "menu-left-accout" &&
          this.mousedownViewClassName != "color-content-max-titel" &&
          this.mousedownViewClassName != "vc-sketch-presets" &&
          this.mousedownViewClassName != "vc-saturation" &&
          this.mousedownViewClassName != "vc-hue-container" &&
          this.mousedownViewClassName != "vc-alpha-picker" &&
          this.mousedownViewClassName != "vc-input__input" &&
          this.mousedownViewClassName != "vc-alpha-container" &&
          this.mousedownViewClassName != "vc-saturation--black" &&
          this.mousedownViewClassName != "vc-sketch" &&
          this.mousedownViewClassName != "vc-sketch-sliders" &&
          this.mousedownViewClassName != "vc-hue-picker" && 
          this.mousedownViewClassName != "color-mode-title" &&
          this.mousedownViewClassName != "vc-chrome-body" &&
          !this.isClickCommonMoreBgColor) ||
        (this.mousedownViewClassName == "menu-content" &&
          hideTag == "SVG" &&
          hideTag != "PATH" &&
          hideTag != "IMG")
      ) {
        this.textColorAccout = false;
        this.textShadowColorAccout = false;
        this.showCorrelationLineType = false;
        this.showCorrelationLineStartType = false;
        this.showCorrelationLineEndType = false;
        this.lineColorAccout = false;
        this.fromBorderAccout = false;
        this.fullShapeTypeAccout = false;
        this.encircleTitleAccout = false;
        this.borderAccout = false;
        this.fillColorAccout = false;
        this.baoWeiFillColorAccout = false;
        this.baoWeiLineColorAccout = false;
        this.textColorAccoutEncircle = false;
        this.nodeStructMenuShow = false;
        this.isLineStlyeAccout = false;
        this.showWrappingLineTitle = false;
      }
    });
  },
  methods: {
    ...mapMutations([
      "showChunkColor",
      "markColorAccout",
      "getTextSize",
      "getTextStlye",
      "setBorderlLine",
      "setIconSize",
      "iconPlaceAccouts",
      "setLineMinorWitdh",
      "showTipsMessageAccouts",
      "setImgFontSize",
      "imagePlaceAccouts",
      "iconLayoutAccout",
      "nodeStylePageAccout",
      "onChangeDottedLineAccout",
      "setPaddingSize",
      "setmaterialLibraryModulesModel",
      "setbaoWeiTextSize",
      "setNodeFontAlign",
      "lineStyleLayout",
      "correlationLineStyleLayout",
      "correlationNodeFullShape",
      "setNodeWidth",
      "isShowShoppingModel",
    ]),
    getString(i) {
      return getString(i);
    },
    onChange(e) {},
    onChanges(checked) {},
    onChangeLine() {
      this.isDottedLine = !this.isDottedLine;
      this.onChangeDottedLineAccout(this.isDottedLine);
    },
    lineChangeDottedLine() {
      // 修改线条虚线
      this.lineIsDottedLine = !this.lineIsDottedLine;
      EditMindmapVM.setlineDot(this.lineIsDottedLine);
    },
    borderChangeDottedLine() {
      // 修改边框虚线
      this.borderIsDottedLine = !this.borderIsDottedLine;
      EditMindmapVM.setShapeDot(this.borderIsDottedLine);
    },
    nodeChangeShadow() {
      // 修改节点阴影
      this.nodeIsShadow = !this.nodeIsShadow;
      EditMindmapVM.setShadow(this.nodeIsShadow);
    },
    lineChangeShadow() {
      // 修改线条阴影
      this.lineIsShadow = !this.lineIsShadow;
      EditMindmapVM.setlineShadow(this.lineIsShadow);
    },
    onChangeLineLength(value) {
      EditMindmapVM.changeNodeLineLength(value);
    },
    //字体样式
    setFontStlyeAccout(index) {
      if (this.nodeAccout != null) {
        this.showCorrelationLineType = false;
        this.showCorrelationLineStartType = false;
        this.showCorrelationLineEndType = false;
        this.fontStyleAccout = index;
        this.fontStlyeList[index].fontStyleAccout =
          !this.fontStlyeList[index].fontStyleAccout;
        this.getTextStlye(index);
      } else {
        this.showTipsMessageAccouts(true);
        setTimeout(() => {
          this.showTipsMessageAccouts(false);
        }, 1000);
      }
    },
    setFontAlign(item, index) {
      if (this.nodeAccout != null) {
        this.showCorrelationLineType = false;
        this.showCorrelationLineStartType = false;
        this.showCorrelationLineEndType = false;
        this.fontAlign.forEach((item, index) => {
          item.fontStyleAccout = false;
        });
        this.fontAlign[index].fontStyleAccout = true;
        this.setNodeFontAlign(item.type);
      } else {
        this.showTipsMessageAccouts(true);
        setTimeout(() => {
          this.showTipsMessageAccouts(false);
        }, 1000);
      }
    },
    //图片位置
    setImageTarget(index) {
      if (this.nodeAccout != null) {
        this.iconTargetAccout = index;
        this.iconLayoutAccout(
          index == 0 ? ImageLayoutType.LAYOUT_LEFT : ImageLayoutType.LAYOUT_TOP
        );
        setTimeout(() => {
          this.iconLayoutAccout(null);
        }, 100);
      } else {
        this.showTipsMessageAccouts(true);
        setTimeout(() => {
          this.showTipsMessageAccouts(false);
        }, 1000);
      }
    },
    //图片形状
    setIconLayoutAccout(index) {
      if (this.nodeAccout != null) {
        this.iconShapeAccout = index;
        if (index == 0) {
          this.imagePlaceAccouts(ImageShapeType.Diamond);
        } else if (index == 1) {
          this.imagePlaceAccouts(ImageShapeType.Circular);
        } else if (index == 2) {
          this.imagePlaceAccouts(ImageShapeType.Square);
        }
      } else {
        this.showTipsMessageAccouts(true);
        setTimeout(() => {
          this.showTipsMessageAccouts(false);
        }, 1000);
      }
    },
    //图标位置
    setIconPlaceAccout(index) {
      if (this.nodeAccout != null) {
        this.iconPlaceAccout = index;
        this.iconPlaceAccouts(index + 1);
        setTimeout(() => {
          this.iconPlaceAccouts(null);
        }, 100);
      } else {
        this.showTipsMessageAccouts(true);
        setTimeout(() => {
          this.showTipsMessageAccouts(false);
        }, 1000);
      }
    },
    //文字颜色
    setTextColorAccout() {
      if (this.nodeAccout != null) {
        this.textColorAccout = !this.textColorAccout;
        this.textShadowColorAccout = false;
        this.lineColorAccout = false;
        this.fromBorderAccout = false;
        this.fullShapeTypeAccout = false;
        this.encircleTitleAccout = false;
        this.fillColorAccout = false;
        this.borderAccout = false;
        this.nodeStructMenuShow = false;
        this.isLineStlyeAccout = false;
        this.showCorrelationLineType = false;
        this.showCorrelationLineStartType = false;
        this.showCorrelationLineEndType = false;
        this.showWrappingLineTitle = false;
        this.showChunkColor(true);
        this.markColorAccout(2);
      } else {
        this.showTipsMessageAccouts(true);
        setTimeout(() => {
          this.showTipsMessageAccouts(false);
        }, 1000);
      }
    },
    //文字阴影颜色
    setTextShadowColorAccout() {
      if (this.nodeAccout != null) {
        this.textShadowColorAccout = !this.textShadowColorAccout;
        this.textColorAccout = false;
        this.lineColorAccout = false;
        this.fromBorderAccout = false;
        this.fullShapeTypeAccout = false;
        this.encircleTitleAccout = false;
        this.fillColorAccout = false;
        this.borderAccout = false;
        this.nodeStructMenuShow = false;
        this.isLineStlyeAccout = false;
        this.showCorrelationLineType = false;
        this.showCorrelationLineStartType = false;
        this.showCorrelationLineEndType = false;
        this.showWrappingLineTitle = false;
        this.showChunkColor(true);
        this.markColorAccout(9);
      } else {
        this.showTipsMessageAccouts(true);
        setTimeout(() => {
          this.showTipsMessageAccouts(false);
        }, 1000);
      }
    },
    //边框颜色
    setBorderAccout() {
      if (this.nodeAccout != null) {
        this.borderAccout = !this.borderAccout;
        this.lineColorAccout = false;
        this.textColorAccout = false;
        this.textShadowColorAccout = false;
        this.fromBorderAccout = false;
        this.fullShapeTypeAccout = false;
        this.encircleTitleAccout = false;
        this.fillColorAccout = false;
        this.nodeStructMenuShow = false;
        this.isLineStlyeAccout = false;
        this.showCorrelationLineType = false;
        this.showCorrelationLineStartType = false;
        this.showCorrelationLineEndType = false;
        this.showWrappingLineTitle = false;
        this.showChunkColor(true);
        this.markColorAccout(3);
      } else {
        this.showTipsMessageAccouts(true);
        setTimeout(() => {
          this.showTipsMessageAccouts(false);
        }, 1000);
      }
    },
    //节点填充颜色
    setFillColorAccout() {
      if (this.nodeAccout != null) {
        this.fillColorAccout = !this.fillColorAccout;
        this.lineColorAccout = false;
        this.fromBorderAccout = false;
        this.fullShapeTypeAccout = false;
        this.encircleTitleAccout = false;
        this.borderAccout = false;
        this.textColorAccout = false;
        this.textShadowColorAccout = false;
        this.nodeStructMenuShow = false;
        this.isLineStlyeAccout = false;
        this.showCorrelationLineType = false;
        this.showCorrelationLineStartType = false;
        this.showCorrelationLineEndType = false;
        this.showChunkColor(true);
        this.markColorAccout(4);
      } else {
        this.showTipsMessageAccouts(true);
        setTimeout(() => {
          this.showTipsMessageAccouts(false);
        }, 1000);
      }
    },
    //线条颜色
    setLineColorAccout() {
      if (this.nodeAccout != null) {
        this.lineColorAccout = !this.lineColorAccout;
        this.textColorAccout = false;
        this.textShadowColorAccout = false;
        this.fromBorderAccout = false;
        this.fullShapeTypeAccout = false;
        this.encircleTitleAccout = false;
        this.borderAccout = false;
        this.nodeStructMenuShow = false;
        this.isLineStlyeAccout = false;
        this.showCorrelationLineType = false;
        this.showCorrelationLineStartType = false;
        this.showCorrelationLineEndType = false;
        this.showChunkColor(true);
        this.markColorAccout(5);
      } else {
        this.showTipsMessageAccouts(true);
        setTimeout(() => {
          this.showTipsMessageAccouts(false);
        }, 1000);
      }
    },
    //关联线组件
    setCorrelationLineType() {
      this.textColorAccout = false;
      this.textShadowColorAccout = false;
      this.fromBorderAccout = false;
      this.fullShapeTypeAccout = false;
      this.encircleTitleAccout = false;
      this.borderAccout = false;
      this.nodeStructMenuShow = false;
      this.isLineStlyeAccout = false;
      this.lineColorAccout = false;
      this.showCorrelationLineStartType = false;
      this.showCorrelationLineEndType = false;
      this.showCorrelationLineType = !this.showCorrelationLineType;
    },
    //关联线起点组件
    setCorrelationLineStartType() {
      this.textColorAccout = false;
      this.textShadowColorAccout = false;
      this.fromBorderAccout = false;
      this.encircleTitleAccout = false;
      this.fullShapeTypeAccout = false;
      this.borderAccout = false;
      this.nodeStructMenuShow = false;
      this.isLineStlyeAccout = false;
      this.lineColorAccout = false;
      this.showCorrelationLineType = false;
      this.showCorrelationLineEndType = false;
      this.showCorrelationLineStartType = !this.showCorrelationLineStartType;
    },
    //关联线终点组件
    setCorrelationLineEndType() {
      this.textColorAccout = false;
      this.textShadowColorAccout = false;
      this.fromBorderAccout = false;
      this.fullShapeTypeAccout = false;
      this.encircleTitleAccout = false;
      this.borderAccout = false;
      this.nodeStructMenuShow = false;
      this.isLineStlyeAccout = false;
      this.lineColorAccout = false;
      this.showCorrelationLineType = false;
      this.showCorrelationLineStartType = false;
      this.showCorrelationLineEndType = !this.showCorrelationLineEndType;
    },
    //包围线颜色
    setBaoWeiLineColorAccout() {
      if (this.nodeAccout != null) {
        this.baoWeiLineColorAccout = !this.baoWeiLineColorAccout;
        this.showCorrelationLineType = false;
        this.showCorrelationLineStartType = false;
        this.showCorrelationLineEndType = false;
        this.baoWeiFillColorAccout = false;
        this.showWrappingLineTitle = false;
        this.showChunkColor(true);
        this.markColorAccout(6);
      } else {
        this.showTipsMessageAccouts(true);
        setTimeout(() => {
          this.showTipsMessageAccouts(false);
        }, 1000);
      }
    },
    //包围填充色
    setBaoWeiFillColorAccout() {
      if (this.nodeAccout != null) {
        this.baoWeiFillColorAccout = !this.baoWeiFillColorAccout;
        this.baoWeiLineColorAccout = false;
        this.showWrappingLineTitle = false;
        this.showChunkColor(true);
        this.markColorAccout(7);
      } else {
        this.showTipsMessageAccouts(true);
        setTimeout(() => {
          this.showTipsMessageAccouts(false);
        }, 1000);
      }
    },
    //包围文字颜色
    setBaoWeiTitleColorAccout() {
      if (this.nodeAccout != null) {
        // this.lineColorAccout = !this.lineColorAccout;
        // this.textColorAccout = false;
        this.textColorAccoutEncircle = !this.textColorAccoutEncircle;

        this.baoWeiFillColorAccout = false;
        this.baoWeiLineColorAccout = false;
        this.showWrappingLineTitle = false;
        this.showChunkColor(true);
        this.markColorAccout(8);
      } else {
        this.showTipsMessageAccouts(true);
        setTimeout(() => {
          this.showTipsMessageAccouts(false);
        }, 1000);
      }
    },
    //边框样式
    setFromBorderAccout() {
      if (this.nodeAccout != null) {
        this.fromBorderAccout = !this.fromBorderAccout;
        this.fullShapeTypeAccout = false;
        this.encircleTitleAccout = false;
        this.textColorAccout = false;
        this.textShadowColorAccout = false;
        this.lineColorAccout = false;
        this.showCorrelationLineType = false;
        this.showCorrelationLineStartType = false;
        this.showCorrelationLineEndType = false;
        this.fillColorAccout = false;
        this.borderAccout = false;
        this.nodeStructMenuShow = false;
        this.isLineStlyeAccout = false;
        this.showWrappingLineTitle = false;
      } else {
        this.showTipsMessageAccouts(true);
        setTimeout(() => {
          this.showTipsMessageAccouts(false);
        }, 1000);
      }
    },
     //填充样式
    setFullShapeType() {
      if (this.nodeAccout != null) {
        this.fullShapeTypeAccout = !this.fullShapeTypeAccout;
        this.fromBorderAccout = false;
        this.encircleTitleAccout = false;
        this.textColorAccout = false;
        this.textShadowColorAccout = false;
        this.lineColorAccout = false;
        this.showCorrelationLineType = false;
        this.showCorrelationLineStartType = false;
        this.showCorrelationLineEndType = false;
        this.fillColorAccout = false;
        this.borderAccout = false;
        this.nodeStructMenuShow = false;
        this.isLineStlyeAccout = false;
        this.showWrappingLineTitle = false;
      } else {
        this.showTipsMessageAccouts(true);
        setTimeout(() => {
          this.showTipsMessageAccouts(false);
        }, 1000);
      }
    },

    setStyleToPeer() {
      EditMindmapVM.styleToPeer()
    },
    setStyleToAllSon() {
      EditMindmapVM.styleToAllSon()
    },
    //包裹线类型
    setWrappingLineTitleType() {
      if (this.nodeAccout != null) {
        this.fromBorderAccout = false;
        this.fullShapeTypeAccout = false;
        this.encircleTitleAccout = false;
        this.textColorAccout = false;
        this.textShadowColorAccout = false;
        this.lineColorAccout = false;
        this.showCorrelationLineType = false;
        this.showCorrelationLineStartType = false;
        this.showCorrelationLineEndType = false;
        this.fillColorAccout = false;
        this.borderAccout = false;
        this.nodeStructMenuShow = false;
        this.isLineStlyeAccout = false;
        this.showWrappingLineTitle = !this.showWrappingLineTitle;
      } else {
        this.showTipsMessageAccouts(true);
        setTimeout(() => {
          this.showTipsMessageAccouts(false);
        }, 1000);
      }
    },
    //包裹线标题位置
    setEncircleTitleType() {
      if (this.nodeAccout != null) {
        this.encircleTitleAccout = !this.encircleTitleAccout;
        this.fromBorderAccout = false;
        this.fullShapeTypeAccout = false;
        this.textColorAccout = false;
        this.textShadowColorAccout = false;
        this.lineColorAccout = false;
        this.showCorrelationLineType = false;
        this.showCorrelationLineStartType = false;
        this.showCorrelationLineEndType = false;
        this.fillColorAccout = false;
        this.borderAccout = false;
        this.nodeStructMenuShow = false;
        this.isLineStlyeAccout = false;
        this.showWrappingLineTitle = false;
      } else {
        this.showTipsMessageAccouts(true);
        setTimeout(() => {
          this.showTipsMessageAccouts(false);
        }, 1000);
      }
    },
    //节点的布局样式
    setNodeStructure() {
      if (!this.changeNodeLayout) {
        return;
      }
      if (this.nodeAccout != null) {
        this.fromBorderAccout = false;
        this.fullShapeTypeAccout = false;
        this.textColorAccout = false;
        this.textShadowColorAccout = false;
        this.lineColorAccout = false;
        this.showCorrelationLineType = false;
        this.showCorrelationLineStartType = false;
        this.showCorrelationLineEndType = false;
        this.fillColorAccout = false;
        this.borderAccout = false;
        this.isLineStlyeAccout = false;
        this.showWrappingLineTitle = false;
        this.nodeStructMenuShow = !this.nodeStructMenuShow;
      } else {
        this.showTipsMessageAccouts(true);
        setTimeout(() => {
          this.showTipsMessageAccouts(false);
        }, 1000);
      }
    },
    //节点线条的布局
    setIsLineStlyeAccout() {
      if (this.nodeAccout != null) {
        this.fromBorderAccout = false;
        this.fullShapeTypeAccout = false;
        this.showWrappingLineTitle = false;
        this.textColorAccout = false;
        this.textShadowColorAccout = false;
        this.lineColorAccout = false;
        this.showCorrelationLineType = false;
        this.showCorrelationLineStartType = false;
        this.showCorrelationLineEndType = false;
        this.fillColorAccout = false;
        this.borderAccout = false;
        this.nodeStructMenuShow = false;
        if (this.isLineStlyeAccout) {
          this.isLineStlyeAccout = false;
        } else {
          this.isLineStlyeAccout = EditMindmapVM.isChangeNodeLineLayout();
        }
      } else {
        this.showTipsMessageAccouts(true);
        setTimeout(() => {
          this.showTipsMessageAccouts(false);
        }, 1000);
      }
    },

    //文字大小
    setTextSize(size) {
      if (this.nodeAccout != null) {
        this.getTextSize(size);
      } else {
        this.showTipsMessageAccouts(true);
        setTimeout(() => {
          this.showTipsMessageAccouts(false);
        }, 1000);
      }
    },
    //边框大小
    setBorderLineSize(borderSize) {
      if (this.nodeAccout != null) {
        this.setBorderlLine(borderSize);
      } else {
        this.showTipsMessageAccouts(true);
        setTimeout(() => {
          this.showTipsMessageAccouts(false);
        }, 1000);
      }
    },
    //自定义宽度大小
    setNodeWidthSize(Size) {
      if (this.nodeAccout != null) {
        this.setNodeWidth(Size);
      } else {
        this.showTipsMessageAccouts(true);
        setTimeout(() => {
          this.showTipsMessageAccouts(false);
        }, 1000);
      }
    },
    //图片大小
    setImgFontSizes(imgSize) {
      if (this.nodeAccout != null) {
        let width;
        let height;
        if (this.maxBorder == "width") {
          width = imgSize;
          height = imgSize / this.imgProportion;
        } else if (this.maxBorder == "height") {
          height = imgSize;
          width = height * this.imgProportion;
        }
        this.setImgFontSize({ width, height });
      } else {
        this.showTipsMessageAccouts(true);
        setTimeout(() => {
          this.showTipsMessageAccouts(false);
        }, 1000);
      }
    },
    //线条大小
    setLineMinorWitdhs(newLineMinorWitdh) {
      if (this.nodeAccout != null) {
        this.setLineMinorWitdh(newLineMinorWitdh);
      } else {
        this.showTipsMessageAccouts(true);
        setTimeout(() => {
          this.showTipsMessageAccouts(false);
        }, 1000);
      }
    },
    //添加背景
    showCheckImage() {
      this.setmaterialLibraryModulesModel({ accout: true, type: "bbg" });
    },

    changeSliderVal(newVal) {
      //改变字体触发的函数。
      this.fontSizeSlider = newVal;
      this.setTextSize(newVal);
    },
    changeFontFamily(newVal) {
      //改变字体触发的函数。
      let fontFamilyClass = ""
      let id = -2
      for (let index = 0; index < this.fontFamilys.length; index++) {
        const cell = this.fontFamilys[index];
        if (newVal == cell.name) {
          fontFamilyClass = cell.class
          id = cell.id
          break
        }
      }
      
      if (id == -1) {
        let family = this.fontFamily
        this.fontFamily = ""
        this.addLocalFontFamily()
        setTimeout(() => {
          this.fontFamily = family
        }, 10);
        return
      } else {
        this.fontFamily = newVal;
        FontLoad.load([this.fontFamily]).then((success) => {
          if (success) {
            EditMindmapVM.changeTextFontFamily(fontFamilyClass)
          }
        })
      }
    },

    addLocalFontFamily() {
      if (this.$tools.isElectron()) {
        if (FontLoad.userFont != null && FontLoad.userFont.list != null &&
            FontLoad.userFont.list.length >= 1 && !Util.isMember()) {
            let that = this;
            this.$confirm({
              title: getString(strings.Mind_Tips),
              content: getString(strings.Mind_Import_Font_Upgrade_Label),
              cancelText: getString(strings.Global_Cancel),
              okText: getString(strings.Upgrade_To_Professional),
              centered: true,
              style: {
                borderRadius: "20px",
              },
              onOk() {
                that.isShowShoppingModel(true); 
              },
              onCancel() {
                          
              },
            });
        } else {
          let inputFontFamilys = document.getElementById("hiddenInputFontFamilys");
          if (inputFontFamilys) {
            inputFontFamilys.click();
          }
        }
      } else {
        let that = this;
        this.$confirm({
          title: getString(strings.Mind_Tips),
          content: getString(strings.Mind_Import_Font_Web_Tips),
          cancelText: getString(strings.Global_Cancel),
          okText: getString(strings.Global_Ok),
          centered: true,
          style: {
            borderRadius: "20px",
          },
          onOk() {
            let newWindow = window.open();
            newWindow.location = 'https://mindyushu.com';
          },
          onCancel() {
                       
          },
        });
      }
    },

    handleFontFamilyFile(e) {
      let $target = e.$target || e.srcElement;
      let file = $target.files[0];
      if (file.size > 1024 * 1024 * 200) {
        this.$message.error("文件太大，请添加少于200M字体文件");
        this.$el.querySelector(".hiddenInputFontFamilys").value = null
        return false;
      }
      
      var reader = new FileReader();
      reader.onload = (data) => {
        let res = data.target || data.srcElement;
        if (file.size > 1024 * 1024 * 200) {
          this.$message.error("文件太大，请添加少于200M字体文件");
          this.$el.querySelector(".hiddenInputFontFamilys").value = null
          return false;
        }
        if (res == null || res.result == null) {
          this.$message.error("导入失败");
          this.$el.querySelector(".hiddenInputFontFamilys").value = null
        }
        var buf = res.result
        function blobData() {
          var arr = buf.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }
          return new Blob([u8arr], { type: mime });
        }
        var blob = blobData();        
        if (blob != null) {
            try {
              let fileName = file.name
              for (let index = 0; index < this.fontFamilys.length; index++) {
                const element = this.fontFamilys[index];
                if (element.file == fileName) {
                    this.fontFamilys.splice(index, 1)
                    break
                }
              }
              FontLoad.saveUserLocalFont(fileName, blob).then((fontCell) => {
                if (fontCell != null) {
                  this.fontFamilys.splice(1, 0, fontCell);
                }
                this.$el.querySelector(".hiddenInputFontFamilys").value = null
              })
            } catch (error) {
                this.$el.querySelector(".hiddenInputFontFamilys").value = null
            }
        } else {
          this.$el.querySelector(".hiddenInputFontFamilys").value = null
        }
      };
      reader.readAsDataURL(file);
    },

    changeBorderVal(newVal) {
      //改变边框宽度
      this.borderSizeSlider = newVal;
      if(this.borderSizeSlider>0){
        this.showBorderUsed = true;
      }else{
        this.showBorderUsed = false;
      }
      this.setBorderLineSize(newVal);
    },
    changeBorderValTo(){
      //设置边框宽度为0
      this.showBorderUsed = !this.showBorderUsed;
      if(this.showBorderUsed){
        this.borderSizeSlider = 1;
      }else{
        this.borderSizeSlider = 0;
      }
      this.setBorderLineSize(this.borderSizeSlider);
    },
    changeNodeWidthVal(newVal) {
      //改变边框宽度
      this.nodeWidthSizeSlider = newVal;
      if(this.nodeWidthSizeSlider>0){
        this.showNodeWidthUsed = true;
      }else{
        this.showNodeWidthUsed = false;
      }
      this.setNodeWidthSize(newVal);
    },
    changeNodeWidthValTo(){
      //设置边框宽度为0
      this.showNodeWidthUsed = !this.showNodeWidthUsed;
      if(this.showNodeWidthUsed){
        this.nodeWidthSizeSlider = parseInt(this.nodeWidthSizeSliderTo);
      }else{
        this.nodeWidthSizeSlider = 0;
      }
      this.setNodeWidthSize(this.nodeWidthSizeSlider);
    },
    changeLineWidthVal(newVal) {
      //改变连接线宽度
      this.setLineMinorWitdhs(newVal);
      this.lineSizeSlider = newVal;
    },
    changeImgSizeVal(newVal) {
      //改变图片大小
      this.setImgFontSizes(newVal);
    },
    changeIconSizeVal(newVal) {
      //改变图标大小
      this.setIconSize(newVal);
    },
    changeBaoWeiTitleFontsizeVal(newVal) {
      //改变包裹线的标题字体大小。
      this.setbaoWeiTextSize(newVal);
    },
    changePaddingSizeVal(newVal) {
      this.setPaddingSize(newVal);
    },
    setLineSliderItem() {
      //设置分支线条宽度，时间线导图最小为0.5最大为6，其它最小为0最大为6
      let extremum = this.lineSizeSliderExtremum;
      if (this.$store.state.editMindmap.mindType == MindType.TIME_MAP) {
        extremum.min = 0.5;
      } else {
        extremum.min = 0;
      }
      //     extremum.min = 0;
      this.lineSizeSliderItem = [];
      for (let index = extremum.min;
        index <= extremum.max;
        index += this.zeroPointFive) {
        let obj = {};
        obj.name = index;
        obj.id = index;
        this.lineSizeSliderItem.push(obj);
      }
    },
    setBorderSizeSliderExtremum() {
      //设置分支边框宽度，最小为0最大为6
      let extremum = this.borderSizeSliderExtremum;
      extremum.min = 0;
      this.borderSizeSliderItem = [];
      for (
        let index = extremum.min;
        index <= extremum.max;
        index += this.zeroPointFive
      ) {
        let obj = {};
        obj.name = index;
        obj.id = index;
        this.borderSizeSliderItem.push(obj);
      }
    },
    setNodeWidthSizeSliderExtremum() {
      //设置分支边框宽度，最小为0最大为6
      let extremum = this.nodeWidthSizeSliderExtremum;
      extremum.min = 0;
      this.nodeWidthSizeSliderItem = [];
      for (
        let index = extremum.min;
        index <= extremum.max;
        index += 10
      ) {
        let obj = {};
        obj.name = index;
        obj.id = index;
        this.nodeWidthSizeSliderItem.push(obj);
      }
    },
    setDardarkMode() {
      // 设置深色模式
      this.$nextTick(() => {
        let dardarkMode = this.$store.state.mapEditIsDardarkMode;
        if (dardarkMode.darkMode) {
          document.documentElement.style.setProperty(
            "--rightMenuTextStyleBg",
            "rgba(255, 255, 255, 0.38)"
          );
        } else {
          document.documentElement.style.setProperty(
            "--rightMenuTextStyleBg",
            " #cccccc"
          );
        }
        this.setDarkModeIcon();
      });
    },
    setDarkModeIcon() {
      // 设置深色模式图标
      if (this.mapEditDarkMode) {
        this.borderStyleLayout = require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderRadioWhite.svg"); //边框样式
        this.nodeFullShapeImg = require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/nodeBackgroundFullWhite.svg");
        this.wrapLineBorderStyleLayout = require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleLine/darkModeIcon/border_trapezoid.svg");
        this.lineStyleLayoutImg = require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/darkModeIcon/straightLineWhite.svg"); //线条布局
        this.structureDefaultImg = require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/layoutRightWhite.svg"); //结构默认图片
      } else {
        this.borderStyleLayout = require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_radio.svg"); //边框样式
        this.nodeFullShapeImg = require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/nodeBackgroundFull.svg");
        this.wrapLineBorderStyleLayout = require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleLine/lightModeIcon/border_trapezoid.svg");
        this.lineStyleLayoutImg = require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/lightModeIcon/straight_line_icon.svg"); //线条布局
        this.structureDefaultImg = require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_right_icon.svg"); //结构默认图片
      }
    },
  },
  watch: {
    $route: {
      handler() {
        this.setLineSliderItem();
      },
      deep: true,
    },
    "$store.state.editMindmap.mindType"(newval) {
      //判断当前导图的类型
      this.setLineSliderItem();
    },
    fontExtremum: {
      handler(newval, olval) {
        this.fontItems = [];
        //console.log(newval);
        for (let index = newval.min; index <= newval.max; index++) {
          let obj = {};
          obj.name = index;
          obj.id = index;
          this.fontItems.push(obj);
        }
      },
      immediate: true,
      deep: true,
    },

    borderSizeSliderExtremum: {
      handler(newval, olval) {
        // this.borderSizeSliderItem = [];
        // for (let index = newval.min; index <= newval.max; index++) {
        //   let obj = {};
        //   obj.name = index;
        //   obj.id = index;
        //   this.borderSizeSliderItem.push(obj);
        // }
      },
      immediate: true,
      deep: true,
    },

    lineSizeSliderExtremum: {
      handler(newval, olval) {
        // this.lineSizeSliderItem = [];
        // for (let index = newval.min; index <= newval.max; index++) {
        //   let obj = {};
        //   obj.name = index;
        //   obj.id = index;
        //   this.lineSizeSliderItem.push(obj);
        // }
      },
      immediate: true,
      deep: true,
    },

    imgFontSizeSliderExtremum: {
      handler(newval, olval) {
        this.imgFontSizeSliderItem = [];
        for (let index = newval.min; index <= newval.max; index++) {
          let obj = {};
          obj.name = index;
          obj.id = index;
          this.imgFontSizeSliderItem.push(obj);
        }
      },
      immediate: true,
      deep: true,
    },

    iconFontSizeSliderExtremum: {
      handler(newval, olval) {
        this.iconFontSizeSliderItem = [];
        for (let index = newval.min; index <= newval.max; index++) {
          let obj = {};
          obj.name = index;
          obj.id = index;
          this.iconFontSizeSliderItem.push(obj);
        }
      },
      immediate: true,
      deep: true,
    },

    baoWeiTitleFontsizeSliderExtremum: {
      handler(newval, olval) {
        this.baoWeiTitleFontsizeItems = [];
        for (let index = newval.min; index <= newval.max; index++) {
          let obj = {};
          obj.name = index;
          obj.id = index;
          this.baoWeiTitleFontsizeItems.push(obj);
        }
      },
      immediate: true,
      deep: true,
    },

    paddingSizeSliderExtremum: {
      handler(newval, olval) {
        this.paddingSizeSliderItem = [];
        for (let index = newval.min; index <= newval.max; index++) {
          let obj = {};
          obj.name = index;
          obj.id = index;
          this.paddingSizeSliderItem.push(obj);
        }
      },
      immediate: true,
      deep: true,
    },

    fontSizeSlider: {
      handler(newval, olval) {
        // this.setTextSize(newval);
      },
    },
    borderSizeSlider: {
      handler(newBorderSize, olBorderSize) {
        // this.setBorderLineSize(newBorderSize);
      },
    },
    imgFontSize: {
      handler(newImgFontSize, olImgFontSize) {
        // this.setImgFontSizes(newImgFontSize);
      },
    },
    iconFontSize: {
      handler(newIconSize, olIconSize) {
        // this.setIconSize(newIconSize);
      },
    },
    lineSizeSlider: {
      handler(newLineMinorWitdh, olLineMinorWitdh) {
        this.setLineMinorWitdhs(newLineMinorWitdh);
      },
    },

    paddingSizeSlider: {
      handler(newLineMinorWitdh, olLineMinorWitdh) {
        // this.setPaddingSize(newLineMinorWitdh);
      },
    },
    baoWeiTitleFontsize: {
      handler(newval, olval) {
        // this.setbaoWeiTextSize(newval);
      },
    },
    "$store.state.nodeContentMessage": {
      handler(newNodeMessage, olval) {
        if (newNodeMessage != null) {
          if (newNodeMessage.generalizationContent != null) {
            this.nodeAccout = newNodeMessage;
          }

          if (newNodeMessage.layout == MindType.BUBBLE_MAP) {
            this.isBubblue = true;
          } else {
            this.isBubblue = false;
          }
          //连接线
          if (newNodeMessage.type == MindElementType.NODE_CONNECT_LINE) {
            let imgCount = this.imgDataList.length;
            for (let index = 0; index < imgCount; index++) {
              if (this.imgDataList[index].lineType == newNodeMessage.lineContent.connectLineType) {
                this.correlationLineStyleLayoutImg = this.mapEditDarkMode
                  ? this.imgDataList[index].imgWhite
                  : this.imgDataList[index].imgData;
              }
            }

            let startImgCount = this.imgDataListStart.length;
            let endImgCount = this.imgDataListEnd.length;
            for (let index = 0; index < startImgCount; index++) {
              if (
                this.imgDataListStart[index].lineType ==
                newNodeMessage.lineContent.startShape
              ) {
                this.correlationLineStartImg = this.mapEditDarkMode
                  ? this.imgDataListStart[index].imgWhite
                  : this.imgDataListStart[index].imgData;
              }
            }
            for (let index = 0; index < endImgCount; index++) {
              if (
                this.imgDataListEnd[index].lineType ==
                newNodeMessage.lineContent.endShape
              ) {
                this.correlationLineEndImg = this.mapEditDarkMode
                  ? this.imgDataListStart[index].imgWhite
                  : this.imgDataListStart[index].imgData;
              }
            }

            this.nodeAccout = newNodeMessage;
            this.isNodeToBaoWei = false;
            this.isBaoWeiToNode = true;
            this.isNodeToLine = false;
            if (newNodeMessage.lineContent.textContent != null) {
              this.fontSizeSlider = newNodeMessage.lineContent.textContent.textFontSize;
              this.textColor = colorChangeClass.SystemToColor(
                newNodeMessage.lineContent.textContent.textColor
              );
            }
            
            this.fillColor = colorChangeClass.SystemToColor(
              newNodeMessage.lineContent.backgroundColor
            );
            if (newNodeMessage.lineContent != null) {
              this.lineSizeSlider = newNodeMessage.lineContent.lineWidth;
            }
            if (newNodeMessage.lineContent.textContent != null) {
              this.fontStlyeList[0].fontStyleAccout =
                newNodeMessage.lineContent.textContent.textBold;
              this.fontStlyeList[1].fontStyleAccout =
                newNodeMessage.lineContent.textContent.textItalics;
              this.fontStlyeList[2].fontStyleAccout =
                newNodeMessage.lineContent.textContent.textStrikethrough;
            }
            this.isDottedLine =
              newNodeMessage.lineContent.nodeConnectLineDottedLine;
          }
          this.isToPeerNode = newNodeMessage.parentNodeId > -1 && (newNodeMessage.type == MindElementType.SUBJECT || newNodeMessage.type == MindElementType.SON_SUBJECT);
          this.isToAllSonNode = this.isToPeerNode && newNodeMessage.type == MindElementType.SON_SUBJECT;
          if (newNodeMessage.type == MindElementType.SUBJECT) {
            this.nodeAccout = newNodeMessage;
            this.isNodeToBaoWei = true;
            this.isBaoWeiToNode = true;
            this.isNodeToLine = true;
          }
          //包围线
          if (newNodeMessage.type == MindElementType.BAOWEI_VIEW) {
            this.nodeAccout = newNodeMessage;
            this.isBaoWeiToNode = false;
            this.isNodeToBaoWei = false;
            this.isNodeToLine = true;
            for (var i = 0; i < this.encircleTitleImgList.length; i++) {
              let encircleTitleimg = this.encircleTitleImgList[i];
              if (
                newNodeMessage.lineContent.encircleTitleType ==
                encircleTitleimg.nodeType
              ) {
                this.encircleLayoutImg = this.mapEditDarkMode
                  ? encircleTitleimg.imgWhite
                  : encircleTitleimg.layoutIcon;
                // this.encircleLayoutImg = encircleTitleimg.layoutIcon;
              }
            }
            this.baoWeiFillColor = colorChangeClass.SystemToColor(
              newNodeMessage.lineContent.color
            );
            this.baoWeiLineColor = colorChangeClass.SystemToColor(
              newNodeMessage.lineContent.strokeColor
            );
            this.paddingSizeSlider = newNodeMessage.lineContent.padding;
            if (newNodeMessage.lineContent.textContent != undefined) {
              this.baoWeiTitleFontsize =
                newNodeMessage.lineContent.textContent.textFontSize;
            }
            this.isDottedLine = newNodeMessage.lineContent.dottedLine;
          }
          //节点文本内容
          let textContent = null
          if (newNodeMessage.textContent != null) {
            textContent = newNodeMessage.textContent
          } else if (newNodeMessage.generalizationContent != null) {
            textContent = newNodeMessage.generalizationContent
          }
          if (textContent != null) {
            this.nodeAccout = newNodeMessage;
            this.fontSizeSlider = textContent.textFontSize;
            for (let index = 0; index < this.fontFamilys.length; index++) {
              const cell = this.fontFamilys[index];
              if (cell.class == textContent.fontFamily) {
                this.fontFamily = cell.name;
                break
              }
            }

            this.textColor = Colors.getUiColor(textContent.textColor);
            // colorChangeClass.SystemToColor(
            //   newNodeMessage.textContent.textColor
            // );
            this.borderColor = Colors.getUiColor(
              newNodeMessage.borderColor
            );
            // if(this.borderColor == "#20202"){
            //   this.borderColor = "#020202"
            // }
            this.fillColor = Colors.getUiColor(
              newNodeMessage.backgroundColor
            );
            if (typeof newNodeMessage.borderWidth == "number") {
              this.borderSizeSlider = newNodeMessage.borderWidth;
            }
            // console.log(newNodeMessage);
            if(this.borderSizeSlider>0){
              this.showBorderUsed = true;
            }else{
              this.showBorderUsed = false;
            }

            if (typeof newNodeMessage.customWidth == "number") {
              this.nodeWidthSizeSlider = parseInt(newNodeMessage.customWidth.toFixed(0));
            }
            this.nodeWidthSizeSliderTo = newNodeMessage.width;
            if(this.nodeWidthSizeSlider>0){
              this.showNodeWidthUsed = true;
            }else{
              this.showNodeWidthUsed = false;
            }

            if (newNodeMessage.lineContent != null) {
              this.lineSizeSlider = newNodeMessage.lineContent.lineWidth;
            }
            if (newNodeMessage.bubbleRadius != null) {
              this.lineLength = newNodeMessage.bubbleRadius;
            }
            if (
              newNodeMessage.iconElementContents != null &&
              newNodeMessage.iconElementContents.length > 0
            ) {
              this.iconFontSize = newNodeMessage.iconElementContents[0].width;
              this.iconPlaceAccout =
                newNodeMessage.iconElementContents[0].layoutType - 1;
            }
            if (textContent !== null) {
              this.fontStlyeList[0].fontStyleAccout =
                textContent.textBold;
              this.fontStlyeList[1].fontStyleAccout =
                textContent.textItalics;
              this.fontStlyeList[2].fontStyleAccout =
                textContent.textStrikethrough;
            }

            //设置文本的水平对齐方式。
            if (newNodeMessage.alignmentType != null) {
              this.fontAlign.forEach((item, index) => {
                item.fontStyleAccout = false;
              });
              if (
                newNodeMessage.alignmentType == MindElementAlignmentType.LEFT
              ) {
                this.fontAlign[0].fontStyleAccout = true;
              } else if (
                newNodeMessage.alignmentType == MindElementAlignmentType.MIDDLE
              ) {
                this.fontAlign[1].fontStyleAccout = true;
              } else if (
                newNodeMessage.alignmentType == MindElementAlignmentType.RIGHT
              ) {
                this.fontAlign[2].fontStyleAccout = true;
              }
            }
            if (newNodeMessage.layout != null) {
              let parentNode = EditMindmapVM.getNodeById(
                newNodeMessage.parentNodeId
              );
              this.layoutList = LayoutData.getChangeLayoutDatas(
                newNodeMessage,
                parentNode.value
              );
              let imgObj = LayoutData.getImgData(newNodeMessage.layout);
              if (imgObj == "" || imgObj == null) {
              } else {
                this.structureDefaultImg = this.mapEditDarkMode
                  ? imgObj.imgWhite
                  : imgObj.layoutIcon;
                this.selectedLayoutType = imgObj;
              }

              this.changeNodeLayout = EditMindmapVM.isChangeNodeLayout();
            }

            let selectedLineType = EditMindmapVM.getChangeNodeLineLayout();
            if (
              selectedLineType != null &&
              selectedLineType != "" &&
              selectedLineType != undefined
            ) {
              let imgList = new BorderLineImg().borderStyleList;
              let imgListCount = imgList.length;
              for (var i = 0; i < imgListCount; i++) {
                let lineType = imgList[i];
                if (selectedLineType == lineType.LineType) {
                  this.lineStyleLayoutImg = this.mapEditDarkMode
                    ? lineType.imgWhite
                    : lineType.borderIcon;
                  // this.shapeBorderType = shapeType;
                }
              }
            }

            let imageContent = newNodeMessage.imageContent;
            if (
              imageContent == null ||
              imageContent.key == "" ||
              imageContent.key == null
            ) {
              this.showMindImgMenu = false;
            } else {
              this.showMindImgMenu = true;
            }

            let iconElementContents = newNodeMessage.iconElementContents;
            if (
              iconElementContents == null ||
              iconElementContents.length == 0
            ) {
              this.showMindIconMenu = false;
            } else {
              this.showMindIconMenu = true;
            }
            // //设置图片菜单展示。
            //   this.showMindImgMenu =  false; //是否展示节点的图片菜单。
            //   this.showMindIconMenu = false; //是否展示节点的图标菜单。
          }
          //图片显示状态
          if (newNodeMessage.imageContent != null) {
            //图片大小
            let maxNumber = parseInt(
              Math.max(
                newNodeMessage.imageContent.width,
                newNodeMessage.imageContent.height
              )
            );
            this.imgProportion =
              newNodeMessage.imageContent.width /
              newNodeMessage.imageContent.height;
            if (maxNumber == parseInt(newNodeMessage.imageContent.width)) {
              this.maxBorder = "width";
            } else if (
              maxNumber == parseInt(newNodeMessage.imageContent.height)
            ) {
              this.maxBorder = "height";
            }
            this.imgFontSize = maxNumber;

            //位置
            if (
              newNodeMessage.imageContent.layoutType ==
              ImageLayoutType.LAYOUT_TOP
            ) {
              this.iconTargetAccout = 1;
            } else if (
              newNodeMessage.imageContent.layoutType ==
              ImageLayoutType.LAYOUT_LEFT
            ) {
              this.iconTargetAccout = 0;
            }
            //形状
            if (
              newNodeMessage.imageContent.shapeType == ImageShapeType.Square
            ) {
              this.iconShapeAccout = 2;
            } else if (
              newNodeMessage.imageContent.shapeType == ImageShapeType.Circular
            ) {
              this.iconShapeAccout = 1;
            } else if (
              newNodeMessage.imageContent.shapeType == ImageShapeType.Diamond
            ) {
              this.iconShapeAccout = 0;
            }
          }
          // console.log(newNodeMessage);
          if (newNodeMessage.mindElementShape != null) {
            let shapeTypeList = [];
            if (newNodeMessage.type == MindElementType.EXPLAIN) {
              shapeTypeList = new ShapeTypeImg().explainLayoutStyleList;
            } else {
              shapeTypeList = new ShapeTypeImg().layoutStyleList;
            }
            let shapeTypeListCount = shapeTypeList.length;
            for (var i = 0; i < shapeTypeListCount; i++) {
              let shapeType = shapeTypeList[i];
              if (newNodeMessage.mindElementShape == shapeType.nodeType) {
                this.borderStyleLayout = this.mapEditDarkMode
                  ? shapeType.layoutIconWhite
                  : shapeType.layoutIcon;
                this.shapeBorderType = shapeType;
              }
            }
          }
          if (newNodeMessage.backgroundFullType != null) {
            let shapeTypeList = new NodeBackgroundFullShapeImg().shapeStyleList;
            let shapeTypeListCount = shapeTypeList.length;
            for (var i = 0; i < shapeTypeListCount; i++) {
              let shapeType = shapeTypeList[i];
              if (newNodeMessage.backgroundFullType == shapeType.type) {
                this.nodeFullShapeImg = this.mapEditDarkMode
                  ? shapeType.imgWhite
                  : shapeType.imgData;
              }
            }
          }
          // borderStyleLayout
          // 边框虚线
          this.borderIsDottedLine = newNodeMessage.borderDot;
          // 线条虚线
          this.lineIsDottedLine = EditMindmapVM.isLineDotted();
          if (newNodeMessage.isShadow != null) {
            this.nodeIsShadow = newNodeMessage.isShadow;
          }
        }
        if (newNodeMessage == null || (newNodeMessage.lineContent == null && newNodeMessage.generalizationContent == null)) {
          this.isNodeToBaoWei = true;
          this.isBaoWeiToNode = true;
          this.isNodeToLine = true;
        }
      
      },
      deep: true,
    },

    "$store.state.textColor"(newval, olval) {
      if (newval == null) {
        return;
      }
      this.textColor = colorChangeClass.nodeUpdataBeforeColor(newval);
    },
    "$store.state.borderColor"(newval, olval) {
      if (newval == null) {
        return;
      }
      this.borderColor = colorChangeClass.nodeUpdataBeforeColor(newval);
    },
    "$store.state.fillColor"(newval, olval) {
      if (newval == null) {
        return;
      }
      this.fillColor = colorChangeClass.nodeUpdataBeforeColor(newval);
    },
    "$store.state.borderMinorStyle"(newval, olval) {
      if (newval == null) {
        return;
      }
      this.borderMinorStyle = colorChangeClass.nodeUpdataBeforeColor(newval);
    },
    "$store.state.showShopping"(newval, olval) {
      this.textColorAccout = false;
      this.textShadowColorAccout = false;
      this.fillColorAccout = false;
      this.lineColorAccout = false;
      this.showCorrelationLineType = false;
      this.showCorrelationLineStartType = false;
      this.showCorrelationLineEndType = false;
      this.borderAccout = false;
      this.baoWeiFillColorAccout = false;
      this.baoWeiLineColorAccout = false;
    },

    "$store.state.borderStyleLayout"(icon) {
      this.borderStyleLayout = icon;
      // console.log(icon);
    },
    "$store.state.encircleLayoutImg"(icon) {
      this.encircleLayoutImg = icon;
    },
    //线条布局图标
    "$store.state.lineStyleLayout"(icon) {
      this.lineStyleLayoutImg = icon;
    },
    "$store.state.correlationLineStyleLayout"(icon) {
      // this.correlationLineStyleLayoutImg = icon;
    },
    "$store.state.correlationNodeFullShape"(icon) {
      this.nodeFullShapeImg = icon;
    },
    "$store.state.mapEditIsDardarkMode": {
      //导图编辑页面是否为深色模式
      handler(newVal, oldVal) {
        this.setDardarkMode();
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
.selected-box {
  .ant-select-selection {
    height: 20px !important;
  }
  .ant-select-selection--single {
    height: 20px !important;
  }
}
.no-drop-style {
  pointer-events: auto !important;
  cursor: not-allowed !important;
}
.menu-linecolor-box .menu-right-bg {
  position: absolute;
  z-index: 1;
  top: -150px;
  // right: 60px;
}
.menu-right-baoweifill-show {
  display: block;
}
.menu-right-baoweifill-hide {
  display: none;
}
.menu-right-baoweiline-show {
  display: block;
}
.menu-right-baoweiline-hide {
  display: none;
}
.menu-right-fill-show {
  display: block;
}
.menu-right-fill-hide {
  display: none;
}
.menu-right-bordercolor-show {
  display: block;
}
.menu-right-bordercolor-hide {
  display: none;
}
.menu-right-border-show {
  display: block;
}
.menu-right-border-hide {
  display: none;
}
.menu-right-border-box {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu-right-accout {
  cursor: pointer;
}
.menu-right-text-show {
  display: block;
}
.menu-right-text-hide {
  display: none;
}
.menu-right-minnorline-show {
  display: block;
}
.menu-right-minnorline-hide {
  display: none;
}

.font-stlye-before {
  width: 40px;
  height: 22px;
  border: 1px solid var(--exportMenuDashedColor);
  background: var(--rightMenuTextStyleBg);
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin: 0 0 2px 2px;
  cursor: pointer;
}
.menu-font-stlye {
  width: 40px;
  height: 22px;
  border: 1px solid var(--exportMenuDashedColor);
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin: 0 0 2px 2px;
  cursor: pointer;
}
.font-shadow-stlye-before {
  width: 40px;
  height: 22px;
  border: 1px solid var(--exportMenuDashedColor);
  background: var(--rightMenuTextStyleBg);
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin: 0 0 2px 2px;
  cursor: pointer;
}

.menu-div-font-shadow-stlye {
 position: absolute;
 margin-left:80px
}
.menu-img-font-shadow-stlye {
 height: 14px;
}
.menu-font-shadow-stlye {
  width: 40px;
  height: 22px;
  border: 1px solid var(--exportMenuDashedColor);
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin: 0 0 2px 2px;
  cursor: pointer;
}

.style-to-peer {
  width: 260px;
  height: 30px;
  background: #ffffff;
  margin-top: 10px;
  border: 1px solid var(--exportMenuDashedColor);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin: 0 0 2px 2px;
  cursor: pointer;
  &:hover {
        background: #f4f4f4;
      }
}

.style-to-node-only-peer {
  width: 260px;
  height: 30px;
  background: #ffffff;
  margin-top: 10px;
  border: 1px solid var(--exportMenuDashedColor);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin: 0 0 2px 2px;
  cursor: pointer;
  &:hover {
        background: #f4f4f4;
      }
}

.style-to-node-only-peer-darkmode {
  width: 260px;
  height: 30px;
  background: #2b2b2b;
  margin-top: 10px;
  border: 1px solid var(--exportMenuDashedColor);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin: 0 0 2px 2px;
  cursor: pointer;
  &:hover {
        background: #343434;
      }
}

.style-to-node-peer-and-all-son {
  width: 260px;
  height: 30px;
  background: #ffffff;
  margin-top: 10px;
  border: 1px solid var(--exportMenuDashedColor);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.style-to-node-peer-and-all-son-darkmode {
  width: 260px;
  height: 30px;
  background: #2b2b2b;
  margin-top: 10px;
  border: 1px solid var(--exportMenuDashedColor);
  border-radius: 6px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.style-to-node-cell {
  width: 100%;
  height: 28px;
  background: #ffffff;
  border-radius: 6px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin: 0 0 2px 2px;
  cursor: pointer;
  &:hover {
        background: #f4f4f4;
      }
}

.style-to-node-cell-darkmode {
  width: 100%;
  height: 28px;
  background: #2b2b2b;
  border-radius: 6px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin: 0 0 2px 2px;
  cursor: pointer;
  &:hover {
        background: #343434;
      }
}

.style-to-node-cell-divide {
  width: 50%;
  height: 28px;
  background: #ffffff;
  border-radius: 6px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin: 0 0 2px 2px;
  cursor: pointer;
  &:hover {
        background: #f4f4f4;
      }
}

.style-to-node-cell-divide-darkmode {
  width: 50%;
  height: 28px;
  background: #2b2b2b;
  border-radius: 6px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin: 0 0 2px 2px;
  cursor: pointer;
  &:hover {
        background: #343434;
      }
}

.right-menu-content-box .menu-content .menu-canvas .menu-linecolor {
  width: 285px;
  height: 100%;
  margin-bottom: 10px;
  // border-top: 1px solid var(--rightMenuBorderColor);
}
.right-menu-content-box
  .menu-content
  .menu-canvas
  .menu-linecolor-box
  .menu-right-accout {
  position: absolute;
  right: 16px;
  display: flex;
}
.right-menu-content-box
  .menu-content
  .menu-canvas
  .menu-linecolor-box
  .menu-right-accout {
  position: absolute;
  right: 16px;
  display: flex;
}

.right-menu-content-box
  .menu-content
  .menu-canvas
  .menu-linecolor-box
  .menu-left-accout {
  position: absolute;
  left: 16px;
  font-weight: 600;
}
.right-menu-content-box .menu-content .menu-canvas .menu-linecolor-box {
  width: 285px;
  height: 40px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}
.menu-change-number-box {
  width: 285px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  padding: 0 16px;
  .ant-slider {
    //为了兼容之前潘潘的slider代码
    margin: 0 !important;
    position: relative !important;
    right: 0 !important;
  }
}

.right-menu-content-box .menu-content .menu-canvas .menu-right-linecolor {
  width: 76px;
  height: 22px;
  background: #ffffff;
  border: 1px solid var(--exportMenuDashedColor);
  opacity: 1;
  border-radius: 2px;
}
.right-menu-content-box
  .menu-content
  .menu-canvas
  .menu-linecolor-box
  .menu-right-accout
  .menu-right-accout-slider {
  height: 6px;
}
.right-menu-content-box
  .menu-content
  .menu-canvas
  .menu-linecolor-box
  .menu-right-accout
  .menu-right-accout-text {
  position: absolute;
  right: 16px;
  top: -4px;
}
.ant-slider {
  width: 100px;
  text-align: center;
  margin: 0;
  position: absolute;
  right: 75px;
}
.menu-bgcolor .menu-left-accout {
  position: absolute;
  left: 16px;
  cursor: pointer;
  font-weight: 600;
}
.menu-bgcolor .menu-right-accout {
  position: absolute;
  right: 16px;
}
.menu-bgcolor .menu-right-accout .menu-right-bgcolor {
  width: 50px;
  height: 22px;
  background: #ffffff;
  border: 1px solid #cccccc;
  opacity: 1;
  border-radius: 2px;
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

/* 用于设置拖动条和文字位置，兼容英语 开始 */
.position-static {
  position: static !important;
}
.line-text {
  top: 11px !important ;
  right: 39px !important;
}
.imgthree {
  line-height: var(--select-height);
  transform: rotate(180deg);
  transition: all 0.3s;
  margin-left: 10px;
  line-height: 32px;
}
.imgthree:before {
  cursor: pointer;
  color: #d3dce6;
}
.imgthree-sel {
  transform: rotate(0deg);
}
.line-box {
  border-top: 1px solid var(--rightMenuBorderColor);
  width: 253px;
  margin-left: 16px;
  margin-top: 12px;
}
.checkbox-deep{
  color: #ffffff;
  margin-left: -4px;
  font-weight: 600;
}
.checkbox-shallow{
  color: #333333;
  margin-left: -4px;
  font-weight: 600;
}
/* 用于设置拖动条和文字位置，兼容英语 结束 */
.menu-left-title{
  font-weight: 600;
}
.fontFamilyAddLocal {
  background: #4949495d;
  width: 225px;
  margin: 5px 15px 5px 10px;
  border-radius: 4px;
}
.fontFamilyAddLocal:hover {
  background: rgba(255, 255, 255, 0.75);
}
// .userFontFamilyAddLocal {
//   background: #5858581e;
// }
// .userFontFamilyAddLocal:hover {
//   background: rgba(73, 73, 73, 0.061);
// }
.hiddenInputFontFamilys {
  display: none;
}
</style>