<template>
  <div id="edit-chart-wrapper">
    <div class="chart-content">
      <!-- 统计编辑页头 -->
      <div class="chart-header">
        <div class="chart-header-logo">
          <img
            src="../../../assets/img/create_chart_icon/chart_logo.svg"
            alt=""
          />
        </div>
        <div class="chart-header-title">
          {{ getString(strings.Echart_Edit_Statistical) }}
        </div>
        <div class="chart-header-ok" @click="colseEditChart">
          <img
            src="../../../assets/img/create_chart_icon/chart_eidt_close.svg"
            alt=""
          />
        </div>
      </div>
      <!-- 统计编辑主体 -->
      <div class="chart-main-father">
        <!-- 首屏编辑-->
        <div class="chart-main-defualt">
          <div class="chart-main">
            <div class="chart-edit-title">
              <div class="chart-edit" @click="openEdit">
                <img
                  src="../../../assets/img/create_chart_icon/chart_edit.svg"
                  alt=""
                />
                <span v-if="editAccout" style="color: #ff765e">{{
                  getString(strings.Mind_Mindmap_Deleted_Forever)
                }}</span>
                <span v-else>{{ getString(strings.Global_Edit) }}</span>
              </div>
              <div class="chart-add" @click="addEdit('addEdit')">
                <img
                  src="../../../assets/img/create_chart_icon/chart_add.svg"
                  alt=""
                />
                <span>{{ getString(strings.Mind_Edit_Add) }}</span>
              </div>
            </div>
            <div class="chart-edit-data">
              <!-- 编辑详情title -->
              <div class="chart-edit-title">
                <ul>
                  <li style="width: 15%">
                    <span>{{ getString(strings.Echart_Edit_Value) }}</span>
                  </li>
                  <li style="width: 15%">
                    <span>{{ getString(strings.Echart_Edit_Color) }}</span>
                  </li>
                  <li style="width: 25%">
                    <span>{{ getString(strings.Echart_Edit_Title) }}</span>
                  </li>
                  <li style="width: 30%">
                    <span>{{
                      getString(strings.Echart_Edit_Detailed_Description)
                    }}</span>
                  </li>
                  <li style="width: 15%">
                    <span>{{ getString(strings.Echart_Edit_Value_Sort) }}</span>
                  </li>
                </ul>
              </div>
              <!-- 编辑详情内容 -->
              <div class="chart-edit-detials-box">
                <div
                  class="chart-edit-detials"
                  v-for="(item, index) in chartEditDetials"
                  :key="index"
                >
                  <ul style="width: 100%">
                    <li style="width: 15%">
                      <span v-if="editAccout">
                        <a-checkbox
                          :value="index"
                          @change="onChangeCheckAccout"
                        ></a-checkbox>
                      </span>
                      <span v-else @click="addEdit('updateEdit', index)">
                        <div class="chart-edit-percent-box">
                          {{ item.percent }}
                        </div>
                      </span>
                    </li>
                    <li
                      style="width: 15%"
                      @click="addEdit('updateEdit', index)"
                    >
                      <div
                        :style="
                          'background:' +
                          item.color +
                          ';width:24px;height:14px;'
                        "
                      ></div>
                    </li>
                    <li
                      style="width: 25%"
                      @click="addEdit('updateEdit', index)"
                    >
                      <div class="chart-edit-title-box">
                        <span>{{ item.title }}</span>
                      </div>
                    </li>
                    <li
                      style="width: 30%"
                      @click="addEdit('updateEdit', index)"
                    >
                      <div class="chart-edit-detials-box">
                        <span>{{ item.detials }}</span>
                      </div>
                    </li>
                    <li style="width: 15%">
                      <div class="chart-edit-sort">
                        <img
                          src="../../../assets/img/create_chart_icon/chart_sort.svg"
                          alt=""
                          @click="editContentSort('down', index)"
                        />
                        <img
                          src="../../../assets/img/create_chart_icon/chart_sort_top.svg"
                          alt=""
                          @click="editContentSort('up', index)"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- 菜单 -->
          <div class="chart-foot">
            <div class="chart-foot-content" @click="setUpChart('TO_SHOW_TYPE')">
              <div class="chart-foot-content-left">
                {{ getString(strings.Echart_Edit_Value_Display_Type) }}
              </div>
              <div class="chart-foot-content-right">
                <img
                  :src="chartEditShowType[isShowChartTypeTitle - 1].url"
                  style="width: 50px"
                  alt=""
                />
                <img
                  src="../../../assets/img/create_chart_icon/chart_more.svg"
                  alt=""
                />
              </div>
            </div>
            <div
              class="chart-foot-content"
              @click="setUpChart('COLOR_PROJECT')"
            >
              <div class="chart-foot-content-left">
                {{ getString(strings.Echart_Edit_Value_Color_Scheme) }}
              </div>
              <div class="chart-foot-content-right">
                <img
                  src="../../../assets/img/create_chart_icon/chart_more.svg"
                  alt=""
                />
              </div>
            </div>
            <div class="chart-foot-content" @click="setUpChart('TO_SHOW_SIZE')">
              <div class="chart-foot-content-left">
                {{ getString(strings.Echart_Edit_Value_Display_Size) }}
              </div>
              <div class="chart-foot-content-right">
                <div class="chart-foot-size-box">
                  <img
                    src="../../../assets/img/create_chart_icon/chart_reduce.svg"
                    alt=""
                    @click="reduceSize('TO_SHOW_SIZE')"
                  />
                  <span>{{ chartWholeSize.toShowSize }}</span>
                  <img
                    src="../../../assets/img/create_chart_icon/chart_push.svg"
                    alt=""
                    @click="pushSize('TO_SHOW_SIZE')"
                  />
                </div>
              </div>
            </div>
            <div class="chart-foot-content" @click="setUpChart('TITLE_SIZE')">
              <div class="chart-foot-content-left">
                {{ getString(strings.Echart_Edit_Value_Title_Size) }}
              </div>
              <div class="chart-foot-content-right">
                <div class="chart-foot-size-box">
                  <img
                    src="../../../assets/img/create_chart_icon/chart_reduce.svg"
                    alt=""
                    @click="reduceSize('TITLE_SIZE')"
                  />
                  <span>{{ chartWholeSize.titleSize }}</span>
                  <img
                    src="../../../assets/img/create_chart_icon/chart_push.svg"
                    alt=""
                    @click="pushSize('TITLE_SIZE')"
                  />
                </div>
              </div>
            </div>
            <div class="chart-foot-content" @click="setUpChart('LINE_SIZE')">
              <div class="chart-foot-content-left">
                {{ getString(strings.Echart_Edit_Value_Explain_Size) }}
              </div>
              <div class="chart-foot-content-right">
                <div class="chart-foot-size-box">
                  <img
                    src="../../../assets/img/create_chart_icon/chart_reduce.svg"
                    alt=""
                    @click="reduceSize('LINE_SIZE')"
                  />
                  <span>{{ chartWholeSize.lineSize }}</span>
                  <img
                    src="../../../assets/img/create_chart_icon/chart_push.svg"
                    alt=""
                    @click="pushSize('LINE_SIZE')"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 模态窗 -->
        <a-modal
          :class="
            editModelList == 'EDIT_UPDATA' || editModelList == 'EDIT_ADD'
              ? 'chart-main-content-model'
              : 'chart-main-content-model-noButoon'
          "
          :centered="true"
          v-model="chartMainContentModel"
          :title="editModelTitle"
          :cancelText="getString(strings.Global_Cancel)"
          :okText="getString(strings.Global_Ok)"
          @ok="chartMainContentHandleOk"
        >
          <!-- 添加编辑 -->
          <div
            class="chart-main-content"
            v-if="editModelList == 'EDIT_ADD' || editModelList == 'EDIT_UPDATA'"
          >
            <div class="chart-main">
              <!-- 百分比颜色 -->
              <div class="chart-main-content">
                <div class="chart-main-left">
                  <div class="content-title">
                    <span>{{ getString(strings.Echart_Edit_Value) }}</span>
                  </div>
                  <div class="content-input">
                    <a-input
                      v-model="addCotnentList.editPercent"
                      ref="editPercent"
                      type="number"
                      :placeholder="getString(strings.Echart_Edit_Value)"
                      min="0"
                    />
                  </div>
                </div>
                <div class="chart-main-right">
                  <div class="content-title">
                    <span>{{ getString(strings.Echart_Edit_Color) }}</span>
                  </div>
                  <div class="content-input">
                    <div
                      class="content-color-btn"
                      :style="
                        'background:' +
                        addCotnentList.editColor +
                        ';width:117px;height:32px;borderRadius:7px;cursor: pointer;border:1px solid #999999;'
                      "
                      @click="isShowChartColorCpt"
                    ></div>
                  </div>
                  <div class="chart-main-color-cpt">
                    <transition name="slide-fade">
                      <CommonBgColor v-if="isShowChartColorCptAccout" />
                    </transition>
                  </div>
                </div>
              </div>

              <div class="chart-main-content title-and-detilas">
                <div class="content-title">
                  <span>{{ getString(strings.Echart_Edit_Title) }}</span>
                </div>
                <div class="content-input">
                  <a-input
                    v-model="addCotnentList.editTitle"
                    ref="editTitle"
                    :placeholder="getString(strings.Echart_Edit_Title)"
                  />
                </div>
              </div>

              <div class="chart-main-content title-and-detilas">
                <div class="content-title">
                  <span>{{
                    getString(strings.Echart_Edit_Detailed_Description)
                  }}</span>
                </div>
                <div class="content-input">
                  <a-input
                    v-model="addCotnentList.editDetial"
                    ref="editDetial"
                    :placeholder="
                      getString(strings.Echart_Edit_Detailed_Description)
                    "
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- 展示类型 -->
          <div class="chart-main-content" v-if="editModelList == 'EDIT_STYLE'">
            <div class="chart-main-style">
              <div
                class="chart-main-style-content"
                v-for="(item, index) in chartEditShowType"
                :key="index"
              >
                <img
                  :src="item.url"
                  alt=""
                  @click="getShowType(index, item.type)"
                />
              </div>
            </div>
          </div>

          <!-- 配色方案 -->
          <div class="chart-main-content" v-if="editModelList == 'EDIT_COLOR'">
            <div class="chart-main-color">
              <div
                class="chart-main-color-content"
                v-for="(item, index) in chartEditShowColor"
                :key="index"
              >
                <img
                  :src="item.url"
                  alt=""
                  @click="getShowColor(item.type, item.colorList, item.isMeber)"
                />
                <div
                  class="chart-main-color-ismeber"
                  v-if="!chartColorIsMember && index != 0"
                >
                  <span>{{
                    getString(strings.Echart_Edit_Value_Subscribe)
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </a-modal>
      </div>
      <!-- 统计编辑底部 -->
      <div class="chart-bottom">
        <a-form-item class="user-submit">
          <a-button @click="colseEditChart">{{
            getString(strings.Global_Cancel)
          }}</a-button>
          <a-button type="primary" @click="chartColoseOk">
            {{ getString(strings.Global_Ok) }}
          </a-button>
        </a-form-item>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import chartMainContentModel from "../../../assets/css/chartMainContentModel/chartMainContentModel.less";
import CommonBgColor from "../../common/CommonBgColor/CommonBgColor";
import StatisticsChapeType from "../../../viewmodel/datatype/StatisticsChapeType";
import Colors from "../../../utils/Colors";
import colorChangeClass from "../../../utils/ColorToSystem";
import StatisticsCellData from "../../../viewmodel/mindelementdata/mindcontent/StatisticsCellData";
import strings from "../../../common/lang/strings";
import getString from "../../../common/lang/language";

export default {
  data() {
    return {
      editAccout: false, //编辑状态
      selectCheckIndexs: [], //选中Index
      editModelList: "EDIT_ADD", //模态框类型
      editModelTitle: "添加", //模态框Title
      chartMainContentModel: false, //是否显示模态框
      editDetialsIndex: null, //编辑节点索引
      addCotnentList: {
        editPercent: "",
        editTitle: "",
        editDetial: "",
        editColor: "",
      }, //添加内容数组
      isShowChartColorCptAccout: false, //调色板
      chartEditDetials: [], //添加数组
      chartEditShowType: [
        {
          url: require("../../../assets/img/create_chart_icon/pie_chart.svg"),
          type: StatisticsChapeType.CakeShape_1,
        },
        {
          url: require("../../../assets/img/create_chart_icon/ring_chart.svg"),
          type: StatisticsChapeType.CakeShape_2,
        },
        {
          url: require("../../../assets/img/create_chart_icon/halving_chart.svg"),
          type: StatisticsChapeType.CakeShape_3,
        },
        {
          url: require("../../../assets/img/create_chart_icon/pie_s_chart.svg"),
          type: StatisticsChapeType.CakeShape_4,
        },
        {
          url: require("../../../assets/img/create_chart_icon/no_halving_chart.svg"),
          type: StatisticsChapeType.CakeShape_5,
        },
        {
          url: require("../../../assets/img/create_chart_icon/histogram_chart.svg"),
          type: StatisticsChapeType.Histogram_1,
        },
        {
          url: require("../../../assets/img/create_chart_icon/cylinder _chart.svg"),
          type: StatisticsChapeType.Histogram_2,
        },
        {
          url: require("../../../assets/img/create_chart_icon/dot_chart.svg"),
          type: StatisticsChapeType.Histogram_3,
        },
      ], //导图形状
      chartEditShowColor: [
        {
          url: require("../../../assets/img/create_chart_icon/color_block_2.svg"),
          type: "StatisticsStyleType_1",
          colorList: [
            0x32c5e9,
            0x66e0e3,
            0x9ee6b7,
            0xffdb5c,
            0xff9f7f,
            0xfb7293,
            0xe062ae,
          ],
          isMeber: true,
        },
        {
          url: require("../../../assets/img/create_chart_icon/color_block_5.svg"), // 1
          type: "StatisticsStyleType_2",
          colorList: [
            0xe60013,
            0xeb6101,
            0xf39800,
            0xfff100,
            0x8fc41f,
            0x23ac39,
            0x009944,
          ],
          isMeber: false,
        },
        {
          url: require("../../../assets/img/create_chart_icon/color_block_4.svg"),
          type: "StatisticsStyleType_3",
          colorList: [
            0x7532e9,
            0x7d66e3,
            0x9ec1e6,
            0x64ff5c,
            0xcaff7f,
            0xfb7272,
            0xe06262,
          ],
          isMeber: false,
        },
        {
          url: require("../../../assets/img/create_chart_icon/color_block_6.svg"),
          type: "StatisticsStyleType_4",
          colorList: [
            0x5073aa,
            0xdd4039,
            0x5495a0,
            0xca8623,
            0xf38711,
            0xe67a59,
            0xeac823,
          ],
          isMeber: false,
        },
        {
          url: require("../../../assets/img/create_chart_icon/color_block_1.svg"),
          type: "StatisticsStyleType_5",
          colorList: [
            0xde6b66,
            0x91ca8c,
            0x759aa0,
            0xf49f42,
            0xe69d87,
            0xead152,
            0x8dc1a8,
          ],
          isMeber: false,
        },
        {
          url: require("../../../assets/img/create_chart_icon/color_block_3.svg"),
          type: "StatisticsStyleType_6",
          colorList: [
            0xf29b76,
            0xf6b380,
            0xfacc89,
            0xfff899,
            0xacd598,
            0x8ac998,
            0x85ccc9,
          ],
          isMeber: false,
        },
        {
          url: require("../../../assets/img/create_chart_icon/color_block_7.svg"),
          type: "StatisticsStyleType_7",
          colorList: [
            0xcfbde5,
            0xf5e9d2,
            0xfcabc0,
            0xf364a4,
            0xffcd72,
            0xb399d2,
            0xff97c6,
          ],
          isMeber: false,
        },
        {
          url: require("../../../assets/img/create_chart_icon/color_block_8.svg"),
          type: "StatisticsStyleType_8",
          colorList: [
            0xf16681,
            0xdfe069,
            0xeb935a,
            0x6bbed4,
            0xdbe8b0,
            0xda513a,
            0x915991,
          ],
          isMeber: false,
        },
        {
          url: require("../../../assets/img/create_chart_icon/color_block_9.svg"),
          type: "StatisticsStyleType_9",
          colorList: [
            0xf4aebd,
            0x7b7fbb,
            0xa379ae,
            0xea8aa7,
            0xee8879,
            0x72458f,
            0xcb678f,
          ],
          isMeber: false,
        },
        {
          url: require("../../../assets/img/create_chart_icon/color_block_10.svg"),
          type: "StatisticsStyleType_10",
          colorList: [
            0x87c5ee,
            0xf1e0ce,
            0xf0b6c2,
            0x14b5a6,
            0xc4dfa8,
            0x5c71aa,
            0x4b97b9,
          ],
          isMeber: false,
        },
        {
          url: require("../../../assets/img/create_chart_icon/color_block_11.svg"),
          type: "StatisticsStyleType_11",
          colorList: [
            0xb4d296,
            0x065dac,
            0x33a5ca,
            0xe5d647,
            0xc4dfa8,
            0x5da34b,
            0xa8ae28,
          ],
          isMeber: false,
        },
        {
          url: require("../../../assets/img/create_chart_icon/color_block_12.svg"),
          type: "StatisticsStyleType_12",
          colorList: [
            0x012ba1,
            0x0141cb,
            0x3882ed,
            0x119af4,
            0x18c6fe,
            0x1fb4ec,
            0x1497fb,
          ],
          isMeber: false,
        },
      ], //颜色
      isShowChartTypeTitle: 0, //展示chart类型Index
      chartWholeSize: { toShowSize: "", titleSize: "", lineSize: "" },
      chartColorIsMember: false, //是否为会员
      strings: strings,
    };
  },
  components: {
    CommonBgColor,
  },
  created() {
    const chartData = this.$store.state.chartContent.data.statisticsContent;
    this.isShowChartTypeTitle = chartData.type;
    this.chartWholeSize.toShowSize = chartData.radius;
    this.chartWholeSize.titleSize = chartData.cellsData[0].explainTitleFontSize;
    this.chartWholeSize.lineSize = chartData.cellsData[0].explainFontSize;
    for (let i = 0; i < chartData.cellsData.length; i++) {
      const data = chartData.cellsData[i];
      this.chartEditDetials.push({
        percent: data.value,
        color: Colors.getUiColor(data.color),
        title: data.explainTitle,
        detials: data.explain,
      });
    }
    //获取会员信息
    let meMessage = localStorage.getItem("meMessage");
    if (meMessage == null) {
      this.chartColorIsMember = false;
    } else {
      this.chartColorIsMember = JSON.parse(meMessage).isMember;
    }
  },
  mounted() {
    //若焦点于调色板之外
    document.addEventListener("click", (e) => {
      var hideTag = e.target.tagName.toUpperCase();
      if (
        e.target.className != "color-content-max-titel" &&
        e.target.className != "vc-sketch-presets" &&
        e.target.className != "vc-saturation" &&
        e.target.className != "vc-hue-container" &&
        e.target.className != "vc-alpha-picker" &&
        e.target.className != "vc-input__input" &&
        e.target.className != "vc-alpha-container" &&
        e.target.className != "vc-saturation--black" &&
        e.target.className != "vc-sketch" &&
        e.target.className != "vc-sketch-sliders" &&
        e.target.className != "vc-hue-picker" &&
        e.target.className != "vc-sketch-field" &&
        e.target.className != "content-color-btn" &&
        e.target.className != "vc-sketch-field--single" &&
        e.target.className != "vc-input__label "
      ) {
        this.isShowChartColorCptAccout = false;
      }
    });
  },
  methods: {
    ...mapMutations([
      "markColorAccout",
      "chartContent",
      "chartContentToVm",
      "isShowShoppingModel",
      "useShortcut",
    ]),
    //语言包
    getString(i) {
      return getString(i);
    },
    //编辑 删除
    openEdit() {
      this.editAccout = !this.editAccout;
      if (!this.editAccout) {
        let list = [];
        for (let i = 0; i < this.chartEditDetials.length; i++) {
          const visible = this.chartEditDetials[i].visible;
          if (!visible) {
            list.push(this.chartEditDetials[i]);
          }
        }
        this.chartEditDetials = list;
      }
    },
    //编辑选中状态
    onChangeCheckAccout(e) {
      this.chartEditDetials[e.target.value].visible = true;
    },
    //添加 修改
    addEdit(type, index) {
      this.editModelTitle = getString(strings.Mind_Edit_Add);
      this.chartMainContentModel = true;
      this.editDetialsIndex = index;
      const updateContent = this.chartEditDetials[index];
      if (type == "addEdit") {
        this.editModelList = "EDIT_ADD";
        this.addCotnentList.editPercent = "";
        this.addCotnentList.editTitle = "";
        this.addCotnentList.editDetial = "";
        this.addCotnentList.editColor = "#ff765e";
        this.addCotnentList.visible = false;
      } else if (type == "updateEdit") {
        this.editModelList = "EDIT_UPDATA";
        this.addCotnentList.editPercent = updateContent.percent;
        this.addCotnentList.editTitle = updateContent.title;
        this.addCotnentList.editDetial = updateContent.detials;
        this.addCotnentList.editColor = updateContent.color;
      }
    },
    //显示调色板
    isShowChartColorCpt() {
      this.markColorAccout("CHART_COLOR"); // 发送颜色类型
      this.isShowChartColorCptAccout = !this.isShowChartColorCptAccout;
    },
    //上下排序
    editContentSort(type, index) {
      if (type == "up") {
        if (index === 0) {
          this.chartEditDetials.push(this.chartEditDetials[index]);
          this.chartEditDetials.splice(index, 1);
          return;
        }
        this.chartEditDetials.splice(
          index - 1,
          0,
          this.chartEditDetials[index]
        );
        this.chartEditDetials.splice(index + 1, 1);
      } else if (type == "down") {
        if (index === this.chartEditDetials.length - 1) {
          this.chartEditDetials.unshift(this.chartEditDetials[index]);
          this.chartEditDetials.splice(index + 1, 1);
          return;
        }
        this.chartEditDetials.splice(
          index + 2,
          0,
          this.chartEditDetials[index]
        );
        this.chartEditDetials.splice(index, 1);
      }
    },
    //菜单
    setUpChart(type) {
      if (type === "TO_SHOW_TYPE") {
        this.editModelList = "EDIT_STYLE";
        this.editModelTitle = getString(strings.Echart_Edit_Value_Display_Type);
        this.chartMainContentModel = true;
      } else if (type === "COLOR_PROJECT") {
        this.editModelList = "EDIT_COLOR";
        this.editModelTitle = getString(strings.Echart_Edit_Value_Color_Scheme);
        this.chartMainContentModel = true;
      } else if (type === "TO_SHOW_SIZE") {
      } else if (type === "TITLE_SIZE") {
      } else if (type === "LINE_SIZE") {
      }
    },
    //当前编辑是否为空
    chartEditContentIsNull() {
      if (
        this.addCotnentList.editPercent == "" ||
        this.addCotnentList.editPercent < 0
      ) {
        this.$refs.editPercent.focus();
        return false;
      } else if (this.addCotnentList.editTitle == "") {
        // this.$refs.editTitle.focus();
        // return false;
      } else if (this.addCotnentList.editDetial == "") {
        // this.addCotnentList.editDetial = getString(
        //   strings.Echart_Edit_Detailed_Description
        // );
      }
      this.chartMainContentModel = !this.chartMainContentModel;
      return true
    },
    //节点 展示 标题 解释 大小
    reduceSize(type) {
      if (type == "TO_SHOW_SIZE") {
        if (this.chartWholeSize.toShowSize < 1) {
          return;
        }
        this.chartWholeSize.toShowSize -= 1;
      } else if (type == "TITLE_SIZE") {
        if (this.chartWholeSize.titleSize < 1) {
          return;
        }
        this.chartWholeSize.titleSize -= 1;
      } else if (type == "LINE_SIZE") {
        if (this.chartWholeSize.lineSize < 1) {
          return;
        }
        this.chartWholeSize.lineSize -= 1;
      }
    },
    pushSize(type) {
      if (type == "TO_SHOW_SIZE") {
        if (this.chartWholeSize.toShowSize >= 800) {
          return;
        }
        this.chartWholeSize.toShowSize += 1;
      } else if (type == "TITLE_SIZE") {
        if (this.chartWholeSize.titleSize >= 100) {
          return;
        }
        this.chartWholeSize.titleSize += 1;
      } else if (type == "LINE_SIZE") {
        if (this.chartWholeSize.lineSize >= 100) {
          return;
        }
        this.chartWholeSize.lineSize += 1;
      }
    },
    //模态框
    chartMainContentHandleOk() {
      if (!this.chartEditContentIsNull()) {
        return;
      }
      //添加 修改 样式 颜色
      if (this.editModelList == "EDIT_ADD") {
        let data = this.$store.state.chartContent.data.statisticsContent
          .cellsData[0];
        //添加内容
        this.chartEditDetials.push({
          percent: this.addCotnentList.editPercent,
          color: this.addCotnentList.editColor,
          title: this.addCotnentList.editTitle,
          detials: this.addCotnentList.editDetial,
          titleSize: data.explainTitleFontSize,
          lineSize: data.explainFontSize,
        });
      } else if (this.editModelList == "EDIT_UPDATA") {
        //修改内容
        this.chartEditDetials[
          this.editDetialsIndex
        ].percent = this.addCotnentList.editPercent;
        this.chartEditDetials[
          this.editDetialsIndex
        ].color = this.addCotnentList.editColor;
        this.chartEditDetials[
          this.editDetialsIndex
        ].title = this.addCotnentList.editTitle;
        this.chartEditDetials[
          this.editDetialsIndex
        ].detials = this.addCotnentList.editDetial;
      } else if (this.editModelList == "EDIT_STYLE") {
      } else if (this.editModelList == "EDIT_COLOR") {
      }
    },
    //展示类型
    getShowType(index, type) {
      this.isShowChartTypeTitle = index + 1;
      this.chartMainContentModel = false;
    },
    //显示颜色
    getShowColor(type, colorList, isMeber) {
      this.chartMainContentModel = false;
      //判断是否为会员
      let meMessage = localStorage.getItem("meMessage");
      //未登录
      if (meMessage == null) {
        if (!isMeber) {
          this.isShowShoppingModel(true);
          return;
        }
      } else {
        //已登录
        if (JSON.parse(meMessage).isMember) {
        } else {
          if (!isMeber) {
            this.isShowShoppingModel(true);
            return;
          }
        }
      }

      //数组累加颜色
      if (this.chartEditDetials.length > colorList.length) {
        for (let j = 0; j < colorList.length; j++) {
          if (this.chartEditDetials.length != colorList.length) {
            colorList.push(colorList[j]);
          }
        }
      }
      for (let i = 0; i < this.chartEditDetials.length; i++) {
        const color = Colors.getUiColor(colorList[i]);
        this.chartEditDetials[i].color = color;
      }
    },
    //关闭窗口
    colseEditChart() {
      this.useShortcut(true);
      this.chartContent({ accout: false, data: null });
    },
    //关闭保存
    chartColoseOk() {
      //解除快捷键锁。
      this.useShortcut(true);
      //添加到数据结构
      let mindElementData = this.$store.state.chartContent.data;
      var cellsData = [];
      for (let i = 0; i < this.chartEditDetials.length; i++) {
        let cell = new StatisticsCellData();
        cell.value = this.chartEditDetials[i].percent - 0; //必须传数字，不能传字符串
        cell.color = colorChangeClass.ColorToSystem(
          this.chartEditDetials[i].color
        ) - 0;
        cell.lineColor = cell.color - 0;
        cell.explainTitle = this.chartEditDetials[i].title;
        cell.explain = this.chartEditDetials[i].detials;
        cell.explainTitleFontSize = this.chartWholeSize.titleSize;
        cell.explainFontSize = this.chartWholeSize.lineSize;
        cellsData.push(cell);
      }
      mindElementData.statisticsContent.cellsData = cellsData;
      mindElementData.statisticsContent.radius = this.chartWholeSize.toShowSize;
      mindElementData.statisticsContent.type = this.isShowChartTypeTitle; // chart类型
      this.chartContentToVm(null);
      this.chartContentToVm(mindElementData);

      this.chartContent({ accout: false, data: null });
    },
  },
  watch: {
    "$store.state.chartFillColor"(newColor) {
      if (newColor == undefined || newColor == null || newColor == "") {
        return;
      }
      if (newColor == "0x20202") {
        this.addCotnentList.editColor = "rgba(2,2,2)";
        return;
      }
      if (newColor == 1) {
        this.addCotnentList.editColor = "rgb(2, 2, 2,0)";
        return;
      }
      this.addCotnentList.editColor =
        "#" + newColor.substring(newColor.indexOf("0x") + 2);
    },
    "$store.state.chartContent.data"(newChartContentData) {
      // console.log(newChartContentData);
    },
  },
};
</script>

<style lang="less" scoped>
#edit-chart-wrapper {
  width: 100%;
  max-width: 524px;
  min-height: 600px;
  background-color: #ffffff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1100;
  .chart-content {
    padding: 30px;
    //统计编辑页头
    .chart-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      .chart-header-logo {
      }
      .chart-header-title {
        font-size: 16px;
        font-family: Noto Sans SC;
        color: #333333;
      }
      .chart-header-ok {
        cursor: pointer;
      }
    }
    //统计编辑主体
    .chart-main-father {
      //首屏编辑
      .chart-main-defualt {
        .chart-main {
          .chart-edit-title {
            width: 145px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            .chart-edit {
              width: 45px;
              height: 20px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;
              span {
                font-size: 14px;
                font-family: Noto Sans SC;
                line-height: 20px;
                color: #333333;
                opacity: 1;
              }
            }
            .chart-add {
              width: 70px;
              height: 32px;
              background-color: #ff765e;
              border-radius: 21px;
              transition: 0.3s;
              padding: 6px 12px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;
              span {
                font-family: Noto Sans SC;
                color: #ffffff;
              }
            }
            .chart-add:hover {
              background-color: #fd492b;
              transition: 0.3s;
            }
          }
          .chart-edit-data {
            width: 100%;
            height: 207px;
            //编辑详情title
            .chart-edit-title {
              width: 100%;
              padding: 10px 30px 10px 15px;
              margin-bottom: 0;
              background-color: #efefef;
              ul {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 0;
                li {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  span {
                    font-size: 12px;
                    font-family: Noto Sans SC;
                    color: #999999;
                  }
                }
              }
            }
            //编辑详情内容
            .chart-edit-detials-box {
              height: 176px;
              overflow: auto;
              overflow-y: scroll;
              .chart-edit-detials {
                width: 100%;
                ul {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding: 10px 30px 10px 15px;
                  margin-bottom: 0;
                  li {
                    display: flex;
                    justify-content: center;
                    cursor: pointer;
                    //百分比
                    .chart-edit-percent-box {
                      max-width: 62px;
                      height: 20px;
                      overflow: hidden;
                      text-align: center;
                    }
                    //标题
                    .chart-edit-title-box {
                      max-width: 70px;
                      height: 20px;
                      overflow: hidden;
                      text-align: center;
                    }
                    //详情
                    .chart-edit-detials-box {
                      max-width: 98px;
                      height: 20px;
                      overflow: hidden;
                      text-align: center;
                    }
                  }
                }
              }
              .chart-edit-detials:nth-of-type(odd) {
                background-color: #ffffff;
              }
              .chart-edit-detials:nth-of-type(even) {
                background-color: #efefef;
              }
            }
            //编辑详情内容滚动条
            .chart-edit-detials-box::-webkit-scrollbar {
              width: 0px;
              height: 0px;
            }
          }
        }
        //底部菜单
        .chart-foot {
          .chart-foot-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            height: 50px;
            border-bottom: 1px dashed #cccccc;
            .chart-foot-content-left {
              color: #333333;
              font-family: Noto Sans SC;
              font-size: 14px;
            }
            .chart-foot-content-right {
              .chart-foot-size-box {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 136px;
                height: 36px;
                border: 1px solid #999999;
                border-radius: 28px;
                padding: 8px 15px;
                span {
                  font-size: 14px;
                  font-family: Noto Sans SC;
                  font-weight: 400;
                  color: #999999;
                  opacity: 1;
                }
                img {
                  padding: 10px;
                }
              }
            }
          }
        }
      }
    }
    //统计编辑底部
    .chart-bottom {
      .user-submit {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 0;
        button {
          display: inline-block;
          margin: 0px 42px;
          // width: 90px;
          height: 32px;
          border-radius: 21px;
        }
      }
    }
  }
}

.chart-main-content {
  //添加
  .chart-main {
    .chart-main-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      .chart-main-left {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 42%;
        .content-title {
          width: 42px;
        }
        .content-input {
          width: 93px;
          input {
            border-radius: 4px;
            text-align: center;
          }
          // chrome
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
          // firefox
          input[type="number"] {
            -moz-appearance: textfield;
          }
        }
      }
      .chart-main-right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 40%;
        position: relative;
        .content-title {
          width: 42px;
        }
        .content-input {
          width: 118px;
          input {
            border-radius: 4px;
          }
        }
        .chart-main-color-cpt {
          position: absolute;
          top: 39px;
        }
      }
    }
    .title-and-detilas {
      .content-title {
        width: 20%;
      }
      .content-input {
        width: 80%;
        input {
          border-radius: 7px;
        }
      }
    }
  }
  //展示类型
  .chart-main-style {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .chart-main-style-content {
      display: flex;
      align-items: center;
      img {
        cursor: pointer;
        width: 89px;
        height: 89px;
      }
    }
  }
  //配色方案
  .chart-main-color {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .chart-main-color-content {
      display: flex;
      align-items: center;
      margin: 10px 0;
      position: relative;
      img {
        cursor: pointer;
        width: 89px;
        height: 89px;
      }
      .chart-main-color-ismeber {
        position: absolute;
        right: 5px;
        top: 5px;
        span {
          color: #ffffff;
        }
      }
    }
  }
}

//动画
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>