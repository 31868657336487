var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showModal),expression:"showModal"}],staticClass:"group-add-new-group-box",on:{"click":function($event){$event.stopPropagation();return _vm.hidden(false)}}},[_c('div',{staticClass:"center-writer",on:{"click":function($event){$event.stopPropagation();return _vm.hidden(true)}}},[_c('div',{staticClass:"group-setting-administrator-header"},[_c('div',{staticClass:"administrator-header-main"},[_vm._v(" "+_vm._s("共享组列表")+" ")]),_c('div',{staticClass:"administrator-header-right"},[_c('img',{staticClass:"hidden-icon close-button-img",staticStyle:{"margin-top":"5px"},attrs:{"src":require("../../../assets/img/popupsImg/colse_new.svg"),"alt":""},on:{"click":function($event){$event.stopPropagation();return _vm.hidden(false)}}})])]),_c('div',{staticClass:"group-name-list",attrs:{"id":"groupNameList"}},[_c('div',{staticClass:"menu-item-child-box",attrs:{"id":"groupNameListChidren"}},_vm._l((_vm.myGroupData),function(item,index){return _c('div',{key:index,staticClass:"group-message-box clearfix",class:_vm.checkGroupData!=null && _vm.checkGroupData.id == item.id ? 'group-message-box-check' : '',on:{"click":function($event){$event.stopPropagation();return _vm.checkGroupFile(item)}}},[_c('div',{staticClass:"group-new-img",style:({
                                width: 45 + 'px',
                                height: 45 + 'px',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center center',
                                overflow: 'hidden',
                                backgroundImage: 'url('+ _vm.httpImageToPrefix(item.cover) +')',
                            })},[(_vm.checkGroupData!=null && _vm.checkGroupData.id == item.id)?_c('div',{staticClass:"group-selected-img"},[_c('img',{attrs:{"src":require("../../../assets/img/groupImg/selectActive.svg"),"alt":""}})]):_vm._e()]),_c('div',{staticClass:"group-new-message"},[_c('div',{staticClass:"group-name"},[_c('div',{staticClass:"group-name-text"},[_vm._v(" "+_vm._s(item.name)+" ")]),(item.isTop == true )?_c('div',{staticClass:"group-top"},[_vm._v(" "+_vm._s(_vm.$getStringObj.getString(_vm.$Strings.Mind_Group_Stick))+" ")]):_vm._e()]),_c('div',{staticClass:"group-new-time"},[_vm._v(" "+_vm._s(_vm.timestampToTimeDHM(item.updateTime ))+" ")]),_c('div',{staticClass:"group-new-time"},[_vm._v(" "+_vm._s(item.subtitle)+" ")])]),(item.unread  == true )?_c('div',{staticClass:"message-hint"},[_c('img',{attrs:{"src":require("../../../assets/img/groupImg/redFlag.png"),"alt":""}}),_vm._v(" "+_vm._s(_vm.$getStringObj.getString(_vm.$Strings.Mind_Group_There_Is_A_New_Content))+" ")]):_vm._e(),(index != _vm.myGroupData.length - 1)?_c('div',{staticClass:"group-bottom-line"}):_vm._e()])}),0)]),_c('div',{staticClass:"move-footer"},[_c('div',{staticClass:"button-class button-class-colse",on:{"click":function($event){$event.stopPropagation();return _vm.hidden(false)}}},[_vm._v(" "+_vm._s(_vm.$getStringObj.getString(_vm.$Strings.Global_Cancel))+" ")]),_c('div',{staticClass:"button-class button-class-ok",on:{"click":function($event){$event.stopPropagation();return _vm.addMindGroupBefore()}}},[_vm._v(" "+_vm._s(_vm.$getStringObj.getString(_vm.$Strings.Global_Ok))+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }