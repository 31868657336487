<template>
  <!-- 一键配色 -->
  <div
    id="one-click-color-matching-box"
    ref="oneClickColorMatching"
    :class="
      showMe ? 'menu-content-border-box-showMe' : 'menu-content-border-box'
    "
  >
    <header v-if="isShowSkeleton" class="style-box-head" ref="styleHeaderBox">
      <section class="style-header-box">
        <div
          class="style-head-item"
          :class="{ 'style-head-active': item.type == headTableActive }"
          v-for="(item, index) in headTableMenu"
          :key="index"
          @click="switchHeadTableMenu(item.type)"
        >
          {{ item.title }}
        </div>
      </section>
    </header>
    <template v-if="headTableActive == headTableType.styleTable">
      <div class="style-type-name" ref="colorMatchingHeader">
        <div
          class="style-type-name-box"
          v-for="(typeItem, index) in TypeNameList"
          :key="index"
          @click.stop="cilckTypeName(typeItem, index)"
        >
          <span
            :class="
              selectedTypeName == typeItem.id ||
              (selectedTypeName == '' && index == 0)
                ? 'style-type-name-select'
                : ''
            "
          >
            {{ typeItem.name }}</span
          >
          <div
            v-if="
              selectedTypeName == typeItem.id ||
              (selectedTypeName == '' && index == 0)
            "
            class="select-type-name"
          ></div>
        </div>
      </div>
      <div class="menu-content-style-all">
        <div v-if="showMe" class="create_a_new_style">
          <label class="add-style-box" @click="uploadContent">
            <img
              ref="addStyleImg"
              class="style-img"
              style="width: 20px; height: 20px"
              src="../../../assets/img/groupImg/groupInvitation.svg"
              alt="创建新风格"
            />
          </label>
          <span
            @click="uploadContent"
            style="margin-left: 10px; cursor: pointer"
            >{{ getString(strings.Adds_Current_Guide_Style_Library) }}</span
          >
        </div>
        <div
          v-if="themeImgList.length > 0"
          class="menu-use-content-inside menu-silder"
          id="menu-content-border-box"
        >
          <ul>
            <li
              class="menu-theme-border"
              v-for="(item, index) in themeImgList"
              :key="index"
              @click="getThemeContent(index - 1)"
              @contextmenu.prevent="reightMenuStyleClick(item, index, $event)"
              @mouseover="mouseOverColl(index)"
              @mouseleave="mouseLeaveColl(index)"
            >
              <img
                class="style-img"
                :src="item.cover"
                @click="getImgMessage(item)"
                alt
              />
              <div
                v-if="
                  ((item.isCollected && !item.isMine) ||
                    (!item.isCollected && !item.isMine)) &&
                  showCollectionMouse &&
                  mouseIndex == index
                "
                class="collection_style_div"
              >
                <img
                  v-if="item.isCollected && !item.isMine"
                  class="collection_style"
                  @click.stop="clickCollection(item, false, index)"
                  src="../../../assets/img/styleImg/collection_icon.svg"
                  alt="已收藏"
                />
                <img
                  v-else-if="!item.isCollected && !item.isMine"
                  class="collection_style"
                  @click.stop="clickCollection(item, true, index)"
                  src="../../../assets/img/styleImg/collection_icon_no.svg"
                  alt="未收藏"
                />
              </div>
              <div
                v-if="item.needMember"
                :class="
                  showCollectionMouse && mouseIndex == index
                    ? 'member_style_div'
                    : 'member_style_div_mouse'
                "
              >
                <img
                  v-if="item.needMember && !isMember"
                  class="member_style"
                  src="../../../assets/img/styleImg/member_icon.svg"
                  alt="会员才能使用"
                />
              </div>
              <div
                v-if="item.isMine && (showIndex != index || !moveStyleMenu)"
                class="collection_style_div"
              >
                <img
                  v-if="item.isMine && (showIndex != index || !moveStyleMenu)"
                  class="collection_style"
                  @click.stop="clickEdit(item, index)"
                  src="../../../assets/img/styleImg/edit_icon.svg"
                  alt="编辑"
                />
              </div>
              <div
                class="style-box-menu"
                @click.stop="hiddenDeleteBtn"
                v-clickoutside="hiddenDeleteBtn"
                v-if="moveStyleMenu && showIndex == index"
              >
                <!---->
                <img
                  class=""
                  @click.stop="deleteStyle(item)"
                  src="../../../assets/img/styleImg/delete_icon.svg"
                />
              </div>
            </li>
          </ul>
        </div>
        <div
          v-else
          class="menu-use-content-inside menu-silder"
          id="menu-content-border-box"
        >
          <div class="style-null">
            <img
              class="style-null-img"
              src="../../../assets/img/styleImg/style_null.png"
              alt="空空如也"
              @click="uploadContent"
            /><br />
            <span style="color: #999999; font-size: 14px">{{
              getString(strings.Mind_Style_Empty)
            }}</span>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <!-- 骨架 -->
      <section
        class="menu-use-content-inside menu-silder skeleton-style"
        id="menu-content-border-box"
      >
        <ul>
          <li
            class="menu-theme-border"
            :class="{ 'menu-active-border': item.id == skeletonActive }"
            v-for="(item, index) in skeletonData"
            :key="index"
          >
            <img
              class="style-img"
              :src="
                require(`../../../assets/img/mindNewEditMenuIocn/oneKeyDiscoloration/skeleton2/${item.url}.png`)
              "
              @click="setFrameworkFun(item.id)"
              alt
            />
          </li>
        </ul>
      </section>
    </template>
  </div>
</template>
<script>
// import CompileDeleteStyleModel from "../ComplieHeader/ComplieAddModel/CompileDeleteStyleModel"

import { mapMutations } from "vuex";
import EditMindmapVM from "../../../viewmodel/mindelementdata/vm/EditMindmapVM";
import MindMapFramework from "../../../viewmodel/mindelementdata/MindMapFramework";
import {
  postMaterialAddMyStyle,
  postMaterialDeleteMyStyle,
  postMaterialCollect,
  postMaterialGetStyle,
} from "../../../common/netWork/material_api";
import { postMaterialQueryStyleByType } from "../../../common/netWork/networkcache";
import { postMindmapUploadImage } from "../../../common/netWork/mind_map_api";
import httpImageToPrefix from "../../../utils/httpImageToPrefix";
import ToImage from "../../../utils/ToImage";
import imageToStyle from "../../../utils/ImageToolkit";
import getString from "../../../common/lang/language";
import strings from "../../../common/lang/strings";
import Config from "../../../core/core/calcule/Config";
import Util from "../../../utils/Util";
import Colors from "../../../utils/Colors";
import HashMap from '../../../viewmodel/core/base/HashMap';
import StyleModelTyle from '../../../viewmodel/datatype/StyleModelTyle';
import MindType from '../../../viewmodel/datatype/MindType';
import TimeLineFramework from '../../../viewmodel/mindelementdata/TimeLineFramework';

const headTableType = {
  styleTable: "styleTypeTable",
  skeletonTable: "skeletonTypeTable",
};
export default {
  name: "ComplieChildMenuTheme",
  // components: {
  //   CompileDeleteStyleModel,
  // },
  data() {
    return {
      themeImgList: [],
      TypeNameList: [],
      isShowSkeleton: EditMindmapVM.isShowFrameworkSetting(),
      headTableMenu: [
        {
          title: getString(strings.Mind_Edit_Style),
          type: headTableType.styleTable,
        },
        {
          title: getString(strings.Mind_Skeleton), //骨架
          type: headTableType.skeletonTable,
        },
      ],
      headTableType,
      headTableActive: headTableType.styleTable,
      skeletonActive: null, //选中的骨架
      needMoneythemeImgList: [
        {
          thumbnail: "style_model_preview_669",
          file: "style_model_preview_669",
          lock: "false",
          mindType: 1,
          id: 10001,
          needMoney: false,
        },
        {
          thumbnail: "style_model_preview_670",
          file: "style_model_preview_670",
          lock: "false",
          mindType: 1,
          id: 10002,
          needMoney: false,
        },
        {
          thumbnail: "style_model_preview_673",
          file: "style_model_preview_673",
          lock: "false",
          mindType: 1,
          id: 10003,
          needMoney: false,
        },
        {
          thumbnail: "style_model_preview_674",
          file: "style_model_preview_674",
          lock: "false",
          mindType: 1,
          id: 10004,
          needMoney: false,
        },
        {
          thumbnail: "style_model_preview_682",
          file: "style_model_preview_682",
          lock: "true",
          mindType: 1,
          id: 10005,
          needMoney: false,
        },
        {
          thumbnail: "style_model_preview_671",
          file: "style_model_preview_671",
          lock: "true",
          mindType: 1,
          id: 10006,
          needMoney: false,
        },
        {
          thumbnail: "style_model_preview_672",
          file: "style_model_preview_672",
          lock: "true",
          mindType: 1,
          id: 10007,
          needMoney: false,
        },
        {
          thumbnail: "style_model_preview_675",
          file: "style_model_preview_675",
          lock: "true",
          mindType: 1,
          id: 10008,
          needMoney: false,
        },
        {
          thumbnail: "style_model_preview_676",
          file: "style_model_preview_676",
          lock: "true",
          mindType: 1,
          id: 10009,
          needMoney: false,
        },
        {
          thumbnail: "style_model_preview_677",
          file: "style_model_preview_677",
          lock: "true",
          mindType: 1,
          id: 10010,
          needMoney: false,
        },
        {
          thumbnail: "style_model_preview_683",
          file: "style_model_preview_683",
          lock: "true",
          mindType: 1,
          id: 10011,
          needMoney: false,
        },
        {
          thumbnail: "style_model_preview_678",
          file: "style_model_preview_678",
          lock: "true",
          mindType: 1,
          id: 10012,
          needMoney: false,
        },
        {
          thumbnail: "style_model_preview_679",
          file: "style_model_preview_679",
          lock: "true",
          mindType: 1,
          id: 10013,
          needMoney: false,
        },
        {
          thumbnail: "style_model_preview_680",
          file: "style_model_preview_680",
          lock: "true",
          mindType: 1,
          id: 10014,
          needMoney: false,
        },
        {
          thumbnail: "style_model_preview_681",
          file: "style_model_preview_681",
          lock: "true",
          mindType: 1,
          id: 10015,
          needMoney: false,
        },
      ],

      isMember: false,
      isScroll: true,
      pageIndex: 0,
      moveStyleMenu: false, //
      styleObj: null,
      reightMenuIndex: -1,
      reightMenuX: -1,
      reightMenuY: -1,
      strings: strings,
      showIndex: -1,
      isShowShoppingSetTimeout: null,
      selectedTypeName: "", //当前选中的风格类型
      showMe: false,
      showIsCollection: false, //
      showCollectionMouse: false, //移入显示出是否收藏
      mouseIndex: -1, //用于判断当前移入的是哪一个风格
      skeletonData: [], //骨架数据
      tabScrollTop: new HashMap()
    };
  },
  created() {
    setTimeout(()=>{
        this.setSkeletonDatas()
    },2000)
  },

  mounted() {
    let scrollDom = document.getElementById("menu-content-border-box");
    if (scrollDom != undefined) {
      scrollDom.removeEventListener("scroll", this.scrollMoreData); //解除绑定
      scrollDom.addEventListener("scroll", this.scrollMoreData, false); //添加绑定
    }
    this.tabScrollTop = new HashMap();
    postMaterialQueryStyleByType({ typeId: "",  page: this.pageIndex + 1, }, (res) => {
        this.TypeNameList = res.styleTypes;
        if (res.styles == null || res.styles.length == 0) {
          if (this.pageIndex == 1) {
            that.themeImgList = [];
          }
          return;
        }
        this.pageIndex = this.pageIndex + 1;
        for (let i = 0; i < res.styles.length; i++) {
          const cover = res.styles[i].cover;
          res.styles[i].cover = httpImageToPrefix(cover);
          res.styles[i].styleModelTyle = this.showMe ? StyleModelTyle.Custom : StyleModelTyle.Normal
        }
        this.themeImgList = res.styles;
      }, (e) => {
        // this.$message.error(e.desc);
      }
    );
    this.setDardarkMode();
    //是否会员
    let userData = localStorage.getItem("meMessage");
    if (userData == null || userData == "") {
      return;
    }
    if (userData != null) {
      let userDataObj = JSON.parse(userData);
      this.isMember = userDataObj.isMember;
    }
  },
  methods: {
    ...mapMutations([
      "imgStyleContent",
      "isShowShoppingModel",
      "showLoginModel",
    ]),
    getString(i) {
      return getString(i);
    },
    setSkeletonDatas() {
      let mindType = this.$store.state.initData.mindType;    
      this.skeletonData = [];
      if (mindType == MindType.TIME_MAP) {
        new TimeLineFramework().MindMapFrameworkList.forEach((item, index) => {
          this.skeletonData.push({
            id: item.id,
            url: item.imagePath,
            imgSrc: index + 1,
          });
        });
      } else {
        new MindMapFramework().MindMapFrameworkList.forEach((item, index) => {
          this.skeletonData.push({
            id: item.id,
            url: item.imagePath,
            imgSrc: index + 1,
          });
        });
      }
    },
    switchHeadTableMenu(type) {
      this.headTableActive = type;
      setTimeout(() => {
        //延迟获取dom
        let contentDom = document.getElementById("menu-content-border-box");
        if (type == headTableType.styleTable) {
          this.setDardarkMode();
          if (contentDom) {
            contentDom.style.height = "calc(100vh - 307px)";
          }
        } else {
          if (contentDom) {
            contentDom.style.height = "calc(100vh - 267px)";
          }
        }
      }, 10);
    },

    getTabScrollTopKey(name) {
      if (name == null || name.length == 0) {
        return "home_tab"
      }
      return name;
    },

    scrollMoreData() {
      //滚动事件
      let scrollDom = document.getElementById("menu-content-border-box");
      const scrollTopHeight = scrollDom.scrollTop || scrollDom.scrollTop; //滚动高度
      
      const clientHeight = scrollDom.clientHeight; //屏幕可用工作区高度
      const scrollHeight = scrollDom.scrollHeight || scrollDom.scrollHeight; //网页可见区域高(包括边线的宽),
      this.tabScrollTop.put(this.getTabScrollTopKey(this.selectedTypeName), scrollDom.scrollTop)
      if (scrollTopHeight + clientHeight + 200 > scrollHeight && this.isScroll) {
        this.isScroll = false;
        postMaterialQueryStyleByType({ typeId: this.selectedTypeName, page: this.pageIndex + 1,}, (res) => {
            this.pageIndex = this.pageIndex + 1;
            if (res.styles == null || res.styles.length == 0) {
              if (this.pageIndex == 1) {
                this.themeImgList = [];
              }
              this.pageIndex = this.pageIndex - 1;
              return;
            }
            // this.materialContentList = [];
            // this.materialTitleList.tags.unshift(getString(strings.Mind_Edit_Mine));
            for (let i = 0; i < res.styles.length; i++) {
              const cover = res.styles[i].cover;
              res.styles[i].cover = httpImageToPrefix(cover);
              res.styles[i].styleModelTyle = this.showMe ? StyleModelTyle.Custom : StyleModelTyle.Normal
            }
            this.themeImgList = this.themeImgList.concat(res.styles);
          }, (e) => {
            this.isScroll = true;
            this.$message.error(e.desc);
          }, () => {
            this.isScroll = true;
          }
        );
      }
    },
    getImgUrl(img) {
      return require("@/assets/img/mapContent/assets/style/" + img + ".jpg");
    },
    getThemeContent(index) {
      this.imgStyleContent(index);
    },
    mouseOverColl(index) {
      this.showCollectionMouse = true;
      this.mouseIndex = index;
    },
    mouseLeaveColl() {
      this.showCollectionMouse = false;
      this.mouseIndex = -1;
    },

    getStyleContent(id) {
      return new Promise(function (resolve, reject) {
        postMaterialGetStyle(
          { id: id },
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    getImgMessage(item) {
      if (!EditMindmapVM.isIncludeCustomStyle()) {
        this.setStyleToMap(item, true);
        return
      }
      let that = this;
      this.$confirm({
        title: getString(strings.Mind_Tips),
        content:  getString(strings.keep_Custom_Part_Style_Unchanged),//"是否保持自定义部分样式不改变？"
        centered: true,
        okText: getString(strings.Mind_Cover),//"覆盖"
        cancelText: getString(strings.Mind_Keep_Custom),//"保留自定义"
        onOk() {
          that.setStyleToMap(item, true);
        },
        onCancel() {
          that.setStyleToMap(item, false);
        },
      });

      
    },

    setStyleToMap(item, isCoverCustomStyle) {
      const meMessageStr = localStorage.getItem("meMessage");
      if (meMessageStr == null || meMessageStr == undefined || meMessageStr == "") {
        this.showLoginModel(true);
        return;
      }
      const meMessage = JSON.parse(meMessageStr);
      this.getStyleContent(item.id).then(
        (res) => {
          res.styleModelTyle = item.styleModelTyle;
          EditMindmapVM.setStyleToMap(res, isCoverCustomStyle);
        },
        (err) => {
          this.$message.error(err.desc);
        }
      );

      if (item.needMember && !meMessage.isMember) {
        let that = this;
        this.$confirm({
          title: getString(strings.Mind_Tips),
          content: getString(strings.Member_Exclusive_Color_Scheme), //该配色方案为会员专享，是否去升级会员？
          // centered: true,
          // maskClosable:true,
          okText: getString(strings.Mind_Edit_Confirm),
          cancelText: getString(strings.Global_Cancel),
          onOk() {
            EditMindmapVM.backwardRetreatForSetStyle();
            that.isShowShoppingModel(true);
          },
          onCancel() {
            EditMindmapVM.backwardRetreatForSetStyle();
            setTimeout(() => {
              that.$emit('setMindStyleComponent');
            }, 5);
          },
        });
      }
    },

    uploadContent() {
      if (!this.isMember) {
        if (this.themeImgList.length > 0 && this.themeImgList[0].isMine) {
          this.$message.warning(this.getString(this.strings.Customize_A_Style));
          setTimeout(() => {
            this.isShowShoppingModel(true);
          }, 1000);
          return;
        }
      }
      let obj = EditMindmapVM.creatCustomStyle();
      let that = this;
      this.getIamgeUrl(obj.rect).then((res) => {
        return new Promise(function (resolve, reject) {
          postMaterialAddMyStyle({ cover: res, content: obj.content }, (res) => {
              resolve(res);
              that.$message.success(
                that.getString(that.strings.Message_Tips_Create_Success)
              );
              postMaterialQueryStyleByType({ typeId: that.selectedTypeName, page: 1 }, (res) => {
                  if (res.styles == null || res.styles.length == 0) {
                    that.themeImgList = [];
                    return;
                  }
                  that.pageIndex = 1;
                  for (let i = 0; i < res.styles.length; i++) {
                    const cover = res.styles[i].cover;
                    res.styles[i].cover = httpImageToPrefix(cover);
                    res.styles[i].styleModelTyle = that.showMe ? StyleModelTyle.Custom : StyleModelTyle.Normal
                  }
                  that.themeImgList = res.styles;
                },
                (e) => {
                  that.$message.error(e.desc);
                }
              );
            },
            (error) => {
              that.$message.error(error.desc);
              reject(error);
            }
          );
        });
      });
    },
    getIamgeUrl(rect) {
      const that = this;
      let bgColor = EditMindmapVM.delegate.DrawingBoardBackgroundColor;
      let toImage = new ToImage();
      return new Promise(function (resolve) {
        toImage.toImage(rect, bgColor).then((res) => {
          let headers;
          headers = {
            "X-Type": "user-style",
          };
          postMindmapUploadImage(
            new imageToStyle().base64ToBlob(res),
            (imgData) => {
              resolve(imgData.imageUrl);
            },
            (error) => {
              resolve(error);
            },
            null,
            headers
          );
        });
      });
    },
    //点击编辑按钮
    clickEdit(item, index) {
      this.showIndex = index;
      this.moveStyleMenu = true;
    },
    deleteStyle(item) {
      // this.$message.config({
      //           top: `500px`,
      //         });
      let that = this;
      return new Promise(function (resolve, reject) {
        postMaterialDeleteMyStyle(
          { id: item.id },
          (res) => {
            resolve(res);
            that.$message.success(
              that.getString(that.strings.Message_Tips_Dlt_Success)
            );
            that.moveStyleMenu = false;
            postMaterialQueryStyleByType({typeId: that.selectedTypeName, page: 1}, (res) => {
                if (res.styles == null || res.styles.length == 0) {
                  that.themeImgList = [];
                  return;
                }
                that.pageIndex = 1;
                for (let i = 0; i < res.styles.length; i++) {
                  const cover = res.styles[i].cover;
                  res.styles[i].cover = httpImageToPrefix(cover);
                  res.styles[i].styleModelTyle = that.showMe ? StyleModelTyle.Custom : StyleModelTyle.Normal
                }
                that.themeImgList = res.styles;
              },
              (e) => {
                that.$message.error(e.desc);
              }
            );
          },
          (error) => {
            that.$message.error(error.desc);
            reject(error);
            this.moveStyleMenu = false;
          }
        );
      });
    },
    hiddenDeleteBtn() {
      this.moveStyleMenu = false;
    },
    cilckTypeName(typeItem, index) {
      this.moveStyleMenu = false;
      this.selectedTypeName = typeItem.id;
      this.showMe = typeItem.isMine;
      let that = this;
      let contentDom = document.getElementById("menu-content-border-box");
      if (contentDom != null) {
        let tabScrollTop = this.tabScrollTop.get(this.getTabScrollTopKey(this.selectedTypeName))
        contentDom.scrollTop = tabScrollTop != null ? tabScrollTop : 0; 
      }
      postMaterialQueryStyleByType({ typeId: typeItem.id, page: 1 },(res) => {
          if (res.styles == null || res.styles.length == 0) {
            this.themeImgList = [];
            return;
          }
          that.pageIndex = 1;
          for (let i = 0; i < res.styles.length; i++) {
            const cover = res.styles[i].cover;
            res.styles[i].cover = httpImageToPrefix(cover);
            res.styles[i].styleModelTyle = this.showMe ? StyleModelTyle.Custom : StyleModelTyle.Normal
          }
          this.themeImgList = res.styles;
        },
        (e) => {
          this.$message.error(e.desc);
        }
      );
    },
    clickCollection(item, isCollection, index) {
      let that = this;
      return new Promise(function (resolve, reject) {
        postMaterialCollect(
          { styleId: item.id },
          (res) => {
            resolve(res);
            // that.showIsCollection = isCollection;
            // that.showIndex = index;
            if (that.showMe) {
              postMaterialQueryStyleByType(
                {
                  typeId: that.selectedTypeName,
                  page: 1,
                },
                (res) => {
                  if (res.styles == null || res.styles.length == 0) {
                    that.themeImgList = [];
                    return;
                  }
                  that.pageIndex = 1;
                  for (let i = 0; i < res.styles.length; i++) {
                    const cover = res.styles[i].cover;
                    res.styles[i].cover = httpImageToPrefix(cover);
                    res.styles[i].styleModelTyle = that.showMe ? StyleModelTyle.Custom : StyleModelTyle.Normal
                  }
                  that.themeImgList = res.styles;
                },
                (e) => {
                  that.$message.error(e.desc);
                }
              );
            } else {
              that.themeImgList[index].isCollected = isCollection;
            }
          },
          (error) => {
            that.$message.error(error.desc);
            reject(error);
            this.moveStyleMenu = false;
          }
        );
      });
    },
    setFrameworkFun(id) {
      // 设置骨架
      EditMindmapVM.setFramework(id);
      this.skeletonActive = id;
    },
    setDardarkMode() {
      // 设置深色模式
      this.$nextTick(() => {
        let boxDom = this.$refs.oneClickColorMatching;
        let headDom = this.$refs.colorMatchingHeader;
        let headTabDom = this.$refs.styleHeaderBox;
        let imgDom = this.$refs.addStyleImg;
        let dardarkMode = this.$store.state.mapEditIsDardarkMode;
        if (dardarkMode.darkMode) {
          if (boxDom) {
            boxDom.style.color = dardarkMode.fontColor;
            boxDom.style.background = Colors.getUiColor(
              dardarkMode.mindBGColor
            );
          }
          if (headDom) {
            headDom.style.color = dardarkMode.fontColor;
            headDom.style.background = dardarkMode.bgColor;
            headDom.style.borderBottom = "1px solid rgba(255, 255, 255, 0.2)";
          }
          if (headTabDom) {
            headTabDom.style.color = dardarkMode.fontColor;
            headTabDom.style.background = dardarkMode.bgColor;
            headTabDom.style.borderBottom =
              "1px solid rgba(255, 255, 255, 0.2)";
          }
          if (imgDom) {
            imgDom.style.marginLeft = "20px";
            imgDom.style.filter = "drop-shadow(rgb(255, 255, 255) -20px 0px)";
          }
          document.documentElement.style.setProperty(
            "--styleHeadActiveBgColor",
            "rgba(255,255,255,.3)"
          );
        } else {
          if (boxDom) {
            boxDom.style.color = "#333333";
            boxDom.style.background = "#ffffff";
          }
          if (headDom) {
            headDom.style.color = "#303030";
            headDom.style.background = "#ffffff";
            headDom.style.borderBottom = "1px solid rgb(243, 235, 235)";
          }
          if (headTabDom) {
            headTabDom.style.color = "#333333";
            headTabDom.style.background = "#f3f3f3";
            headTabDom.style.borderBottom = 0;
          }
          if (imgDom) {
            imgDom.style.marginLeft = "0px";
            imgDom.style.filter = "none";
          }
          document.documentElement.style.setProperty(
            "--styleHeadActiveBgColor",
            "#ffffff"
          );
        }
      });
    },
  },
  watch: {
    "$store.state.initData"(newInitData) {
      this.isShowSkeleton = EditMindmapVM.isShowFrameworkSetting();
      // if (this.setSkeletonDatas != null && this.setSkeletonDatas != undefined) {
      //   this.setSkeletonDatas();
      // }
    },
    "$store.state.initData.mindType"(newInitData) {
      this.isShowSkeleton = EditMindmapVM.isShowFrameworkSetting();
      if (this.setSkeletonDatas != null && this.setSkeletonDatas != undefined) {
        this.setSkeletonDatas();
      }
    },
    "$store.state.mapEditIsDardarkMode": {
      //导图编辑页面是否为深色模式
      handler(newVal, oldVal) {
        this.setDardarkMode();
      },
      deep: true,
    },
    showMe(newVal) {
      if (newVal) {
        //上一级标设为深色模式
        this.setDardarkMode();
      }
    },
    isShowSkeleton(newVal) {
      let contentDom = document.getElementById("menu-content-border-box");
      if (contentDom) {
        if (newVal) {
          contentDom.style.height = "calc(100vh - 307px)";
        } else {
          contentDom.style.height = "calc(100vh - 267px)";
        }
      }
    },
    "$store.state.showSkeleton"(newVal) {
      //显示骨架
      if (newVal) {
        this.headTableActive = headTableType.skeletonTable;
      }
    },
  },
};
</script>
<style scoped lang="less">
.style-box-head {
  width: 100%;
  height: 40px;
  background: #f3f3f3;
  padding: 4px;
  .style-header-box {
    display: flex;
    .style-head-item {
      width: 50%;
      height: 32px;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover {
        background-color: var(--rightClickMenuBg);
        border-radius: 4px;
      }
    }
    .style-head-active {
      background: var(--styleHeadActiveBgColor);
      border-radius: 4px;
      font-weight: 525;
    }
  }
}

.style-box-menu {
  position: relative;
  width: 186px;
  height: 106px;
  background: #0000002e;
  opacity: 1;
  border-radius: 1px;
  margin-top: -106px;
  line-height: 106px;
  text-align: center;
}
.style-box-menu-son {
  margin-top: 2px;
  width: 95px;
  height: 40px;
  background: #ffffff;
  text-align: center;
  line-height: 40px;
  color: #7b8288;
  cursor: pointer;
}
.style-box-menu-son:hover {
  background: #f8f8f8;
}
.menu-content-border-box {
  position: absolute;
  width: 410px;
  height: calc(100vh - 220px);
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  z-index: 999;
  left: 50%;
  top: 56px;
  cursor: auto;
  transform: translate(-57%, 0);
  border: 1px solid var(--exportMenuDashedColor);
}
.menu-content-border-box-showMe {
  position: absolute;
  width: 410px;
  height: calc(100vh - 190px);
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border: 1px solid #ccc;
  z-index: 999;
  left: 50%;
  top: 56px;
  cursor: auto;
  transform: translate(-57%, 0);
}
.create_a_new_style {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  .add-style-box {
    width: 20px;
    overflow: hidden;
    margin-top: -3px;
    display: inline-block;
    cursor: pointer;
  }
}
.menu-use-content-inside {
  width: 410px;
  /* margin-top: 10px; */
  height: calc(100vh - 307px);
  overflow-y: auto;
  overflow-x: hidden;
}
.menu-silder::-webkit-scrollbar {
  width: 4px;
  /* height: 340px; */
  scrollbar-arrow-color: #8f8f8f;
}
.menu-silder::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background: #8f8f8f;
}
.menu-silder::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px #ffffff;
  border-radius: 16px;
  background: #e6e6e6;
}
/* .menu-silder::-webkit-scrollbar-button{
  background-color: black;
} */

.menu-use-content-inside ul {
  padding: 0;
  width: 405px;
  max-height: calc(100vh - 267px);
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
}
.menu-use-content-inside ul li {
  width: 186px;
  height: 106px;
  margin-left: 11px;
  margin-top: 10px;
  cursor: pointer;
  /* padding: 15px 15px; */
  border: 1px solid #ededed;
  border-radius: 4px;
  box-sizing: content-box;
}

.skeleton-style ul li {
  width: 120px;
  height: 72px;
  margin-left: 11px;
  margin-top: 10px;
  cursor: pointer;
  /* padding: 15px 15px; */
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  box-sizing: content-box;
}

.menu-use-content-inside ul li .style-img {
  width: 186px;
  height: 106px;
  
}
.skeleton-style ul li .style-img {
  width: 120px;
  height: 72px;
  border-radius: 4px;  
}
.style-type-name-box {
  position: relative;
  height: 38px;
  font-size: 14px;
  line-height: 38px;
  width: 101px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  font-weight: 550;
  &:hover {
    background-color: var(--rightClickMenuBg);
  }
}
.style-type-name {
  background: #e4e4e4;
  width: auto;
  height: 38px;
}
.select-type-name {
  height: 2px;
  width: 27px;
  background: #fd5436;
  position: absolute;
  margin-top: -3px;
  margin-left: 37px;
}
.style-type-name-select {
  color: #fd5436;
}
.collection_style_div {
  width: 24px;
  height: 24px;
  position: relative;
  margin-top: -99px;
  margin-left: 154px;
  z-index: 5;
  text-align: center;
  line-height: 24px;
  /* background: rgba(102, 102, 102, 0.2); */
}
.collection_style {
  width: 17px;
  height: 17px;
  margin-top: -4px;
  position: relative;
}
.member_style_div {
  width: 30px;
  height: 15px;
  position: relative;
  margin-top: -26px;
  // margin-left: 8px;
  z-index: 5;
  text-align: center;
  line-height: 15px;
  /* background: rgba(102, 102, 102, 0.2); */
}
.member_style_div_mouse {
  width: 30px;
  height: 15px;
  position: relative;
  margin-top: -101px;
  // margin-left: 8px;
  z-index: 5;
  text-align: center;
  line-height: 15px;
}
.member_style {
  position: relative;
}
.style-null {
  position: absolute;
  left: 50%;
  top: 50%;
  /* width: 126px; */
  transform: translate(-50%, -50%);
  text-align: center;
}
.menu-active-border {
  border: 2px solid #666 !important;
}
</style>