import { render, staticRenderFns } from "./ComplieSwitchMapMenu.vue?vue&type=template&id=3aa87066&scoped=true&"
import script from "./ComplieSwitchMapMenu.vue?vue&type=script&lang=js&"
export * from "./ComplieSwitchMapMenu.vue?vue&type=script&lang=js&"
import style0 from "./ComplieSwitchMapMenu.vue?vue&type=style&index=0&id=3aa87066&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3aa87066",
  null
  
)

export default component.exports