<template>
  <div class="complieleftmenu">
    <div
      class="complie-left-menu"
      :class="!leftMenuAccout ? 'show-left-menu' : 'hide-left-menu'"
    >
      <div class="complie-content-icon" @click="setLeftMenuAfter">
        <div
          class="complie-content-icon-box"
          :class="!leftMenuAccout ? 'icon-before' : ''"
        >
          <img :src="leftMenuIconAfter[0].leftMenuIcon" alt="" />
        </div>
      </div>
      <div class="complie-menu-icon">
        <div
          class="complie-content-icon"
          v-for="(item, index) in leftMenuIconList"
          :key="index"
          :class="menuAccout == index ? 'icon-after-bgcolor' : ''"
          @click="setMenuAccout(index, item.leftMenuType)"
        >
          <div class="complie-content-icon-box">
            <img :src="item.leftMenuIcon" alt="" />
          </div>
        </div>

        <div
          class="complie-content-icon bottom-help-icon"
          v-for="(item, index) in helpList"
          :key="item.leftMenuType"
          :class="checkHelp == true ? 'icon-after-bgcolor' : ''"
          @click="setHelp(index, item.leftMenuType)"
        >
          <div class="complie-content-icon-box">
            <img :src="item.leftMenuIcon" alt="" />
          </div>
        </div>
      </div>
      <div class="complie-audio-box" v-if="!leftMenuAccout">
        <div
          class="complie-left-audio"
          v-if="httpAudio != '' && httpAudio != undefined"
        >
          <ComplieAudioLeft :httpAudioData="httpAudio" />
        </div>
      </div>
    </div>

    <div
      class="complie-left-menu-after"
      :class="leftMenuAccout ? 'show-left-menu' : ''"
    >
      <div class="complie-menu-icon">
        <div class="complie-content">
          <div class="complie-content-icon-after" @click="setLeftMenuBefore">
            <div
              class="complie-content-icon-box-after"
              :class="leftMenuAccout ? 'icon-after' : ''"
            >
              <img :src="leftMenuIconAfter[0].leftMenuIcon" alt="" />
            </div>
          </div>
          <div
            class="complie-content-icon-after"
            v-for="(item, index) in leftMenuIconList"
            :key="index"
            :class="menuAccout == index ? 'icon-after-bgcolor' : ''"
            @click="setMenuAccout(index, item.leftMenuType)"
            :ref="item.leftMenuType"
          >
            <div class="complie-content-icon-box-after">
              <img :src="item.leftMenuIcon" alt="" />
            </div>

            <div
              v-if="item.leftMenuType == 'share_link'"
              class="complie-content-icon-text"
            >
              <span
                @click.stop="detailQuestion(item.leftMenuType)"
                class="question-margin"
                ><a-icon type="question-circle"
              /></span>
              {{ getString(item.leftMenuText) }}
            </div>
            <div v-else class="complie-content-icon-text">
              {{ getString(item.leftMenuText) }}
            </div>
          </div>

          <div
            class="complie-content-icon-after bottom-help-icon-after"
            v-for="(item, index) in helpList"
            :key="item.leftMenuType"
            :class="checkHelp == true ? 'icon-after-bgcolor' : ''"
            @click="setHelp(index, item.leftMenuType)"
            :ref="item.leftMenuType"
          >
            <div class="complie-content-icon-box-after">
              <img :src="item.leftMenuIcon" alt="" />
            </div>

            <div class="complie-content-icon-text">
              {{ getString(item.leftMenuText) }}
            </div>
          </div>
        </div>
      </div>
      <div class="complie-audio-box" v-if="leftMenuAccout">
        <div
          class="complie-left-audio"
          v-if="httpAudio != '' && httpAudio != undefined"
        >
          <ComplieAudioLeft :httpAudioData="httpAudio" />
        </div>
      </div>
    </div>

    <div
      id="printimgdom"
      style="
        width: 100%;
        height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        text-align: center;
      "
    >
      <img
        :style="{
          maxWidth: maxWidth,
          maxHeight: maxHeight,
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform:
            'translate(-50%,' + (isMac && !isEelctron ? '0' : '-50%') + ')',
        }"
        :src="printImageSrc"
        alt=""
      />
     
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { getCookie } from "../../common/netWork/base";
import EditMindmapVM from "../../viewmodel/mindelementdata/vm/EditMindmapVM";
import ComplieAudioLeft from "../../components/complieComponents/ComplieHeader/ComplieAudioLeft";
import ShoppingModel from "../shopping/ShoppingModel";
import { postMindmapUploadImage } from "../../common/netWork/mind_map_api";

import imageToStyle from "../../utils/ImageToolkit";
import ToImage from "../../utils/ToImage";
import print from "print-js";
import httpImageToPrefix from "../../utils/httpImageToPrefix";
import strings from "../../common/lang/strings";
import getString from "../../common/lang/language";
import MindDisplayType from "../../viewmodel/datatype/MindDisplayType";

export default {
  data() {
    return {
      leftMenuIconAfter: [
        {
          leftMenuIcon: require("../../assets/img/mapIcon/res/mipmap-leftmenu-icon/lmi-after1.png"),
        },
      ],
      leftMenuIconList: [
        {
          leftMenuIcon: require("../../assets/img/mapIcon/res/mipmap-leftmenu-icon/lmi-after2.png"),
          leftMenuText: strings.Mind_Edit_Left_Menu_Save_Picture,
          leftMenuType: "save_Type",
        },
        {
          leftMenuIcon: require("../../assets/img/mapIcon/res/mipmap-leftmenu-icon/lmi-after4.png"),
          leftMenuText: strings.Mind_Edit_Left_Menu_Export_Pdf,
          leftMenuType: "save_Pdf",
        },
        {
          leftMenuIcon: require("../../assets/img/mapIcon/res/mipmap-leftmenu-icon/lmi_link.png"),
          leftMenuText: strings.Mind_Edit_Left_Menu_Share_Links,
          leftMenuType: "share_link",
        },
        {
          leftMenuIcon: require("../../assets/img/mapIcon/res/mipmap-leftmenu-icon/lmi-after5.png"),
          leftMenuText: strings.Mind_Edit_Left_Menu_Share_The_Storm,
          leftMenuType: "share_PotatoChips",
        },
        {
          leftMenuIcon: require("../../assets/img/mapIcon/res/mipmap-leftmenu-icon/lmi-after9.png"),
          leftMenuText: strings.Mind_Edit_Left_Menu_Printing,
          leftMenuType: "save_Printf",
        },
        {
          leftMenuIcon: require("../../assets/img/mapIcon/res/mipmap-leftmenu-icon/get_version.png"),
          leftMenuText: strings.Mind_Set_Password,
          leftMenuType: "unlock_Full_Version",
        },
        {
          leftMenuIcon: require("../../assets/img/mapIcon/res/mipmap-leftmenu-icon/get_version.png"),
          leftMenuText: strings.Mind_Set_Password,
          leftMenuType: "unlock_Full_Version",
        },
        {
          leftMenuIcon: require("../../assets/img/mapIcon/res/mipmap-leftmenu-icon/get_version.png"),
          leftMenuText: strings.Mind_Edit_Left_Menu_Unlock_Full_Version,
          leftMenuType: "setting_mindpassword",
        },
      ],
      helpList: [
        {
          leftMenuIcon: require("../../assets/img/mapIcon/res/mipmap-leftmenu-icon/lmi-after10.png"),
          leftMenuText: strings.Mind_Edit_Left_Menu_Help,
          leftMenuType: "help",
        },
      ],
      menuAccout: 0,
      leftMenuAccout: false, //false,
      screenWidth: document.documentElement.clientWidth,
      editMindmapContent: EditMindmapVM,
      printImageSrc: "", //打印的图片
      maxWidth: "none", //最大宽度
      maxHeight: "100%", //最大高度
      isMac: false,
      isEelctron: false,
      checkHelp: false,
      httpAudio: "",
    };
  },
  props: {
    initMindData: {
      type: Object,
      defualt() {
        return {};
      },
    },
  },
  components: {
    ShoppingModel,
    ComplieAudioLeft,
  },
  created() {
    //是否是mac
    this.isMac = this.$tools.isMacSafari();
    this.isEelctron = this.$tools.isElectron();

    if (this.isNewGuide()) {
      // 如果没有看过新手教程
      this.leftMenuAccout = true;
    }
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.documentElement.clientWidth;
        that.screenWidth = window.screenWidth;
      })();
    };
  },
  methods: {
    ...mapMutations([
      "hideModelLink",
      "isShowShoppingModel",
      "showLoginModel",
      "sharePotatoChipsModel",
      "helpModal",
      "showCropperImg",
      "showCropperImgUrl",
      "imgTypeModelAccout",
      "setFunctionDetail",
    ]),
    getString(i) {
      return getString(i);
    },
    detailQuestion(dom) {
      let detail = {};
      detail.clientRect = this.$refs[dom][0].getBoundingClientRect();
      detail.index = dom;
      detail.menuboxType = "leftMenu";
      this.setFunctionDetail(detail);
    },
    isNewGuide() {
      let val = localStorage.getItem("newGuide");
      // let val = getCookie("newGuide");
      if (val == null) {
        return true;
      } else {
        return false;
      }
    },
    setMenuAccout(index, type) {
      this.menuAccout = index;
      switch (type) {
        case "save_Type":
          this.saveImgType();
          break;
        case "save_Pdf":
          this.savePdfType();
          break;
        case "share_link":
          this.saveLinkType();
          break;
        case "share_PotatoChips":
          this.sharePc();
          break;
        case "save_Sourse_Type":
          this.saveSourseType();
          break;
        case "unlock_Full_Version":
          this.saveFullType();
          break;
        case "save_Printf":
          this.savePrintf();
          break;
        case "help":
          this.helpModal(true);
          break;
        case "setting_mindpassword":
          EditMindmapVM.setMindPassword();
          break;
        default:
          break;
      }
    },
    setHelp() {
      this.checkHelp = true;
      this.helpModal(true);
    },
    setPrintPage(oldPhotoWidth, oldPhotoHeight) {
      let orientation;
      // 在分辨率72的情况下a4纸宽595高842
      let photoHeight; //新宽度
      let photoWidth; //新高度
      let a4Width = 595;
      let a4Height = 842;
      let a4Scale = a4Width / a4Height; //a4宽高比
      let photoScale = oldPhotoWidth / oldPhotoHeight;
      let photoScaleHeightWidth = oldPhotoHeight / oldPhotoWidth;
      if (oldPhotoWidth < a4Width && oldPhotoHeight < a4Height) {
        orientation = "portrait";
        photoHeight = oldPhotoWidth;
        photoWidth = oldPhotoHeight;
        //竖屏 不缩放
      } else if (Math.abs(a4Scale - photoScale) < 0.2) {
        orientation = "portrait";
        //竖屏
        //缩放
        if (a4Scale > photoScale) {
          photoWidth = a4Height;
          //宽自由缩放
        } else {
          photoHeight = a4Width;
          //高自由缩放
        }
      } else if (Math.abs(a4Scale - photoScaleHeightWidth) < 0.2) {
        orientation = "landscape";
        //横屏
        //缩放
        if (a4Scale > photoScaleHeightWidth) {
          photoHeight = a4Width;
          //高自由缩放
        } else {
          photoWidth = a4Height;
          //宽自由缩放
        }
      } else if (a4Scale - photoScale > 0.2) {
        orientation = "portrait";
        //横屏
        //缩放
        photoWidth = a4Height;
      } else if (a4Scale - photoScale < -0.2) {
        orientation = "landscape";
        //横屏
        //缩放
        photoHeight = a4Height;
      } else {
        orientation = "portrait";
        //竖屏 缩放
        if (a4Scale > photoScale) {
          photoWidth = a4Height;
          //高自由缩放
        } else {
          photoHeight = a4Width;
          //宽自由缩放
        }
      }
      return {
        orientation: orientation, // landscape是横放，portrait是竖放。
        width: photoHeight, //图片的宽度
        height: photoWidth, //图片的高度
      };
    },
    savePrintf() {
      let userData = localStorage.getItem("meMessage");
      if (userData == null || userData == "") {
        this.showLoginModel(true);
        return;
      }
      if(!userData.isMember){
          this.isShowShoppingModel(true);
          return;
      }
      let rect = this.editMindmapContent.getMindContentRect();
      let name = this.editMindmapContent.getTitle();
      let bgColor = this.$store.state.saveImageBgcolor;
      let toImage = new ToImage();
      let type = "printf";
      let outType = "png"; // png
      if (EditMindmapVM.mindDisplayType == MindDisplayType.MindMap) {
        let image = toImage.toImage(rect, bgColor, type, outType, name, EditMindmapVM.isSettingFontFamily());
        image.then(() => {
          this.printImageSrc = toImage.imgUrl;
          let obj = this.setPrintPage(rect.size.width, rect.size.height);
          if (obj.width == undefined) {
            this.maxWidth = "none"; //最大宽度
            this.maxHeight = "100%"; //最大高度
          } else if (obj.height == undefined) {
            this.maxWidth = "100%"; //最大宽度
            this.maxHeight = "none"; //最大高度
          } else {
            this.maxWidth = "none"; //最大宽度
            this.maxHeight = "none"; //最大高度
          }

          setTimeout(() => {
            const style =
              "@page {size:" + obj.orientation + ";margin:10mm 10mm };";
            printJS({
              printable: "printimgdom",
              type: "html",
              scanStyles: false,
              style,
            });
          }, 500);
        });
      } else if (EditMindmapVM.mindDisplayType == MindDisplayType.Outline) {
        let image = toImage.outlineToImage(0x000000, type, outType, name);
        image.then(() => {
          this.printImageSrc = toImage.imgUrl;
          let image = new Image();image.setAttribute('crossOrigin','Anonymouse');
          image.src = toImage.imgUrl;
          image.onload = () => {
            let obj = this.setPrintPage(image.width, image.height);
            if (obj.width == undefined) {
              this.maxWidth = "none"; //最大宽度
              this.maxHeight = "100%"; //最大高度
            } else if (obj.height == undefined) {
              this.maxWidth = "100%"; //最大宽度
              this.maxHeight = "none"; //最大高度
            } else {
              this.maxWidth = "none"; // //最大宽度
              this.maxHeight = "none"; // //最大高度
            }

            setTimeout(() => {
              const style =
                "@page {size:" + obj.orientation + ";margin:0 10mm };";
              printJS({
                printable: "printimgdom",
                type: "html",
                scanStyles: false,
                style,
              });
            }, 500);
          };
        });
      }
    },
    //保存图片
    getSaveImgType(getType) {
      
      let rect = this.editMindmapContent.getMindContentRect();
      let name = this.editMindmapContent.getTitle();
      let bgColor = this.$store.state.saveImageBgcolor;
      let toImage = new ToImage();
      let type = "download";
      let outType = getType; // png webp jpeg
      if (EditMindmapVM.mindDisplayType == MindDisplayType.MindMap) {
        toImage.toImage(rect, bgColor, type, outType, name, EditMindmapVM.isSettingFontFamily());
      } else if (EditMindmapVM.mindDisplayType == MindDisplayType.Outline) {
        toImage.outlineToImage(16777215, type, outType, name);
      }
    },
    //打开选择img type面板
    saveImgType() {
      let userData = localStorage.getItem("meMessage");
      if (userData == null || userData == "") {
        this.showLoginModel(true);
        return;
      }
      this.imgTypeModelAccout({ accout: true, type: "" });
    },
    //保存pdf
    savePdfType() {
      let userData = localStorage.getItem("meMessage");
      if (userData == null || userData == "") {
        this.showLoginModel(true);
        return;
      }
      let rect = this.editMindmapContent.getMindContentRect();
      let name = this.editMindmapContent.getTitle();
      let bgColor = this.$store.state.saveImageBgcolor;
      let toImage = new ToImage();
      let type = "savePdf";
      let outType = "png"; // png
      if (EditMindmapVM.mindDisplayType == MindDisplayType.MindMap) {
        toImage.toImage(rect, bgColor, type, outType, name, EditMindmapVM.isSettingFontFamily());
      } else if (EditMindmapVM.mindDisplayType == MindDisplayType.Outline) {
        toImage.outlineToImage(16777215, type, outType, name);
      }
    },
    saveLinkType() {
      let userData = localStorage.getItem("meMessage");
      if (userData == null || userData == "") {
        this.showLoginModel(true);
        return;
      }
      this.hideModelLink({
        accout: true,
        mindMapId: this.$router.history.current.query.id,
      });
    },
    //分享风暴截图
    sharePc() {
      let userData = localStorage.getItem("meMessage");
      if (userData == null || userData == "") {
        this.showLoginModel(true);
        return;
      }

      let rect = this.editMindmapContent.getMindContentRect();

      let wh_s = 5 / 4;
      let orgWHS = rect.width() / rect.height();
      if (wh_s > orgWHS) {
        let width = rect.height() * wh_s;
        let changeWidth = width - rect.width();
        rect.width(width);
        rect.x = rect.x - changeWidth / 2;
      } else {
        let height = rect.width() / wh_s;
        let changeHeight = height - rect.height();
        rect.height(height);
        rect.y = rect.y - changeHeight / 2;
      }
      rect.y = Math.max(rect.y, 0);
      rect.x = Math.max(rect.x, 0);

      let name = this.editMindmapContent.getTitle();
      let bgColor = this.$store.state.saveImageBgcolor;
      let toImage = new ToImage();
      let type = "storm";
      let outType = "png"; // png
      let image = toImage.toImage(rect, bgColor, type, outType, name, EditMindmapVM.isSettingFontFamily());
      image.then((image) => {
        this.showCropperImg(true);
        this.showCropperImgUrl({ img: image, type: "storm" });
      });
    },
    //截取风暴封面图片
    setSsharePc(image) {
      const cover = image;
      const name = this.editMindmapContent.getTitle().replace(/[\r\n]/g, "");
      const subtitle = this.editMindmapContent.getSubtitle();
      this.sharePotatoChipsModel({
        accout: true,
        shareData: { cover, name, subtitle },
      });
    },
    saveSourseType() {},
    saveFullType() {
      this.isShowShoppingModel(true);
    },
    setLeftMenuAfter() {
      this.leftMenuAccout = true;
    },
    setLeftMenuBefore() {
      this.leftMenuAccout = false;
    },
    setLeftMenuAccout() {
      this.leftMenuAccout = false;
    },
  },
  computed: {
    ...mapState({
      bgColor: (state) => state.saveImageBgcolor,
    }),
  },
  watch: {
    initMindData(newInitMindData) {
      this.httpAudio = newInitMindData.audio;
    },
    screenWidth(val) {
      if (!this.timer) {
        this.screenWidth = val;
        this.timer = true;
        let that = this;
        setTimeout(() => {
          this.timer = false;
        }, 400);
        if (this.screenWidth < 1200) {
          this.setLeftMenuAccout();
        }
      }
    },
    "$store.state.mp3Blob"(newval, olval) {
      //保存图片
      if (newval == "del") {
        this.httpAudio = "";
      } else {
        this.httpAudio = newval;
      }
    },
    "$store.state.isSaveMindPhote"(newval, olval) {
      //保存图片
      if (newval == null) {
        return;
      } else {
        this.saveImgType();
      }
    },
    "$store.state.urlDomImg.imageBase64"(newval, olval) {
      if (newval == null || this.$store.state.urlDomImg.type == "uploadImg") {
        return;
      }
      this.setSsharePc(newval);
    },
    "$store.state.imgTypeModelAccout.type"(newImgType, olImgType) {
      if (newImgType == "") {
        return;
      }
      this.getSaveImgType(newImgType);
    },
    "$store.state.checkHelp"(newval, olval) {
      this.checkHelp = newval;
    },
  },
};
</script>

<style >
/**father Node */
.complieleftmenu {
  position: relative;
}

/**before */
.complie-left-menu {
  width: 52px;
  height: calc(100vh);
  background: #ffffff;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.16);
  opacity: 1;
  z-index: 100;
  position: absolute;
}

.complie-content-icon-box {
  display: flex;
  align-items: center;
  height: 60px;
}
.complie-content-icon {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  cursor: pointer;
}

.bottom-help-icon {
  position: absolute;
  left: 0;
  bottom: 78px;
  width: 52px;
}

/**after */
.complie-left-menu-after {
  width: 170px;
  height: calc(100vh);
  background: #ffffff;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.16);
  opacity: 1;
  z-index: 100;
  position: absolute;
}
.complie-content-icon-box-after {
  display: flex;
  align-items: center;
  height: 60px;
  margin-left: 15px;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.complie-content-icon-text {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  font-size: 15px;
  color: #333333;
  margin-left: 7px;
  letter-spacing: 0px;
}
.question-margin {
  margin-left: 10px;
}
.complie-content-icon-after {
  display: flex;
  cursor: pointer;
}

.bottom-help-icon-after {
  position: absolute;
  left: 0;
  bottom: 78px;
  width: 170px;
}
/**show and hide */
.icon-after-bgcolor {
  background: #f0f2f8;
  transition: 0.5s;
}
.complie-left-menu-after {
  position: absolute;
  left: -170px;
  top: 88px;
  transition: 0.5s;
}
.show-left-menu {
  position: absolute;
  left: 0px;
  top: 88px;
  transition: 0.5s;
}
.hide-left-menu {
  position: absolute;
  left: -52px;
  top: 88px;
  transition: 0.5s;
}
.icon-after {
  transition: 0.6s;
  transform: rotate(540deg);
}
.icon-before {
  transition: 0.6s;
  transform: rotate(360deg);
}
</style>
<style lang="less" scoped>
.complieleftmenu {
  .complie-left-menu {
    .complie-audio-box {
      position: absolute;
      z-index: 80;
      top: 0;
      left: 52px;
      .complie-left-audio {
        z-index: 10;
        position: absolute;
        left: 0px;
        height: 46px;
        opacity: 1;
        border-radius: 61px;
        transition: 0.5s;
        font-size: 16px;
        color: #666666;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 140px;
      }
      .complie-left-audio input {
        border: none;
      }
    }
  }
  .complie-left-menu-after {
    .complie-audio-box {
      position: absolute;
      z-index: 80;
      top: 0;
      left: 170px;
      .complie-left-audio {
        z-index: 10;
        position: absolute;
        left: 0px;
        height: 46px;
        opacity: 1;
        border-radius: 61px;
        transition: 0.5s;
        font-size: 16px;
        color: #666666;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 140px;
      }
      .complie-left-audio input {
        border: none;
      }
    }
  }
}
@media screen and(min-width:768px) and(max-width:1400px) {
  .complieleftmenu {
    .shoping-models {
      width: 100%;
      position: absolute;
      z-index: 10000;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
}
</style>